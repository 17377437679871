import React, { Fragment, useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SupportText from "components/common/SupportText";
import SupportFeedback from "components/common/SupportFeedback";
import styled, { css } from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { DROPZONE_PROPS, ImageCrop } from "utils/constants";
import Icon from "components/Icon/Icon";
import Scrollbars from "react-custom-scrollbars";
import { TrackVertical } from "components/common/style";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import Button from "components/Button/Button";
import { croppedImg, getImage, imageConverter, keyDownConfirm } from "utils/helpers";
import mediaQueries, { mq } from "utils/mediaQueries";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import IconFontAwesome from "components/Icon/IconFontAwesome";
import { CROP_CONFIG as CROP_CONFIG_BASE } from "utils/constants";
import { ImageData } from "core/api/definitions";

interface Erros {
  errorType: "CROP_ERROR" | "IMAGE_INVALID";
  message?: string;
}

export interface ImageCropper extends ImageData {
  cropped?: string;
  id?: number;
}

interface ImageGallery extends ImageCrop<ImageCropper> {}

const CROP_CONFIG = {
  image: undefined,
  ...CROP_CONFIG_BASE,
};

interface ImageGalleryCropperProps {
  disabled?: boolean;
  images?: ImageCropper[];
  error?: string;
  cropConfig?: Omit<typeof CROP_CONFIG, "image">;
  onChange?(gallery: ImageCropper[]): void;
}

let imageID = 0;

const ImageGalleryCropper = ({
  images,
  onChange,
  cropConfig,
  error,
  disabled,
}: ImageGalleryCropperProps): JSX.Element => {
  const { t } = useTranslation();
  const _dropzoneConfig = { ...DROPZONE_PROPS, disabled: disabled };
  const _cropConfig = cropConfig ? { image: undefined, ...cropConfig } : CROP_CONFIG;

  const [imageCrop, setImageCrop] = useState<ImageGallery>(_cropConfig);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ height: 0, width: 0, x: 0, y: 0 });
  const [hasError, setHasError] = useState<Erros | undefined>();
  const [loading, setLoading] = useState(false);

  const imagesMemorized = useMemo(() => images?.map((img) => ({ ...img, id: img?.id ? img?.id : ++imageID })), [
    images,
  ]);

  const hasImage = !!imagesMemorized && imagesMemorized!.length !== 0;

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (!disabled) {
        const files = [];
        for (const file of acceptedFiles) {
          try {
            const image = await getImage(URL.createObjectURL(file));
            const original = await imageConverter(image, undefined, "BASE64");
            files.push({
              original,
              id: ++imageID,
            });
          } catch (e) {
            console.log(e);
            // TODO(Jota): To report error to sentry.
            // TODO(Jota): To inform error to the customer.
          }
        }

        if (onChange) onChange([...(imagesMemorized || []), ...files]);

        if (rejectedFiles.length !== 0)
          setHasError({
            errorType: "IMAGE_INVALID",
            message: t("wizard.pages.pictures.errors.invalidImages", {
              images: rejectedFiles.map((img) => img.name).join(", "),
            }),
          });
      }
    },
    [imagesMemorized, disabled, onChange, t, _cropConfig]
  );

  const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!imageCrop.image?.original) return;
    setLoading(true);
    try {
      const croppedImage = await croppedImg(imageCrop.image?.original, croppedAreaPixels, "BASE64");
      const filtered = imagesMemorized?.filter((img) => img?.id !== imageCrop.image?.id);
      if (croppedImage && onChange && imagesMemorized)
        onChange([
          ...(filtered || []),
          {
            ...imageCrop.image,
            cropped: croppedImage,
          },
        ]);
      setImageCrop(_cropConfig);
      setHasError(undefined);
    } catch (e) {
      console.log(e);
      setHasError({ errorType: "CROP_ERROR", message: t("wizard.pages.pictures.errors.imageCrop") });
    } finally {
      setLoading(false);
    }
  }, [croppedAreaPixels, imageCrop, imagesMemorized]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, ..._dropzoneConfig });
  const dropzoneRootProps = imageCrop.image ? {} : getRootProps();

  const handleEsc = (e: KeyboardEvent) => {
    if ((e?.keyCode === 27 || e.code === "Escape") && imageCrop.image) setImageCrop(_cropConfig);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [imageCrop]);

  const selectImageCrop = (img: ImageCropper) =>
    setImageCrop((prev) => ({
      ...prev,
      image: img,
    }));

  return (
    <ContainerGallery>
      <PictureContainer marginBottom={!imageCrop.image?.original}>
        <SimpleRow className={`col-md-12 col-lg-${hasImage ? 9 : 12}`}>
          <DropContainer
            {...dropzoneRootProps}
            title={t("wizard.pages.pictures.titles.addImages")}
            disabled={disabled}
            data-cy="gallery-dropzone"
          >
            {imageCrop.image ? (
              <Cropper
                image={imageCrop.image.original}
                crop={imageCrop.crop}
                zoom={imageCrop.zoom}
                aspect={imageCrop.aspect}
                onCropChange={(crop) => setImageCrop((prev) => ({ ...prev, crop }))}
                onZoomChange={(zoom) => setImageCrop((prev) => ({ ...prev, zoom }))}
                onCropComplete={onCropComplete}
              />
            ) : (
              <Fragment>
                <input {...getInputProps()} disabled={disabled} />
                <IconContainer>
                  <Icon type="photoCamera" />
                  <SupportText size="md" withWeight={500}>
                    {t("common.add")}
                  </SupportText>
                </IconContainer>
              </Fragment>
            )}
          </DropContainer>
        </SimpleRow>
        <ScrollbarContainer className="col-lg-3 col-md-12">
          <Scrollbars renderThumbVertical={() => <TrackVertical top={-25} right={-3} />}>
            <PreviewList hasImage={hasImage} data-cy="gallery-list">
              {imagesMemorized &&
                imagesMemorized.map((img, key) => {
                  const removeImage = () => {
                    const editing = imageCrop.image?.id === img?.id && img.id !== undefined;
                    if (editing) setImageCrop(CROP_CONFIG);
                    setHasError(undefined);
                    if (onChange) onChange(imagesMemorized.filter((_, imgKey) => imgKey !== key));
                  };

                  return (
                    <PreviewContainer key={key}>
                      <Preview
                        tabIndex={0}
                        role="button"
                        aria-pressed={
                          imageCrop.image !== undefined && imageCrop.image.id === img?.id && img.id !== undefined
                        }
                        title={t("wizard.pages.pictures.titles.editImage")}
                        disabled={disabled}
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          if (!disabled) {
                            selectImageCrop(img);
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (!disabled) {
                            keyDownConfirm(e, () => {
                              e.preventDefault();
                              selectImageCrop(img);
                            });
                          }
                        }}
                        src={img?.cropped || img?.original}
                        alt=""
                      />
                      {!disabled && (
                        <Remove
                          tabIndex={0}
                          title={t("wizard.pages.pictures.titles.removeImage")}
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            removeImage();
                          }}
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (!disabled) {
                              keyDownConfirm(e, () => {
                                e.preventDefault();
                                removeImage();
                              });
                            }
                          }}
                        >
                          <IconFontAwesome icon={["far", "times"]} color="#fff" />
                        </Remove>
                      )}
                    </PreviewContainer>
                  );
                })}
            </PreviewList>
          </Scrollbars>
        </ScrollbarContainer>
      </PictureContainer>
      {imageCrop.image && (
        <Flex className="col-md-12 col-lg-9" data-container="actions">
          <ActionButton
            size="sm"
            color="secondary"
            outline
            disabled={loading || disabled}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              showCroppedImage();
            }}
          >
            {t("common.apply")}
          </ActionButton>
          <ActionButton
            size="sm"
            color="quaternary"
            outline
            disabled={loading || disabled}
            title="Cancelar"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setImageCrop(CROP_CONFIG);
            }}
          >
            {t("common.cancel")}
          </ActionButton>
        </Flex>
      )}
      {!imageCrop.image && hasError && <ErrorText>{hasError.message || ""}</ErrorText>}
      {error && <WizardTrans>{error}</WizardTrans>}
    </ContainerGallery>
  );
};

export default ImageGalleryCropper;

const ContainerGallery = styled.div`
  overflow-x: hidden;
`;

const PictureContainer = styled.div<{ marginBottom: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 30px;
    `}

  ${mediaQueries.md(css`
    flex-direction: column;
  `)}
`;

const SimpleRow = styled.div`
  width: 100%;
  display: flex;
  transition: 0.3s;
  padding: 0;
`;

const DropContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 378px;
  border: 1px solid #9facbd;
  border-radius: 6px;
  background-color: #f9fafc;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  :focus {
    outline-width: 1px;
    outline-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
    `}
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  svg {
    fill: #9facbd;
  }

  span {
    margin-top: 7px;
  }
`;

const PreviewList = styled.ul<{ hasImage: boolean }>`
  width: 100%;
  max-height: 378px;
  transition: opacity 3s;
  padding: 0;
  padding-right: 15px;

  ${({ hasImage }) =>
    !hasImage &&
    css`
      opacity: 0;
    `}
`;

const ScrollbarContainer = styled.div`
  ${mediaQueries.md(css`
    height: 130px;
    margin-top: 15px;
    padding-left: 0;
    padding-right: 0;

    & > div > div ul {
      display: flex;
    }
  `)}
`;

const Remove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  bottom: 7px;
  right: 7px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.3s;
  transform: translateY(40px);
  cursor: pointer;

  :hover,
  :focus {
    transform: translateY(0);
    svg {
      color: ${({ theme }) => theme.colors.quaternary};
    }
  }

  svg {
    height: 75%;
  }
`;

const Preview = styled.img<{ disabled?: boolean }>`
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #9facbd;
  border-radius: 6px;
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  :hover,
  :focus {
    & + ${Remove} {
      transform: translateY(0);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
    `}
`;

const ActionButton = styled(Button)`
  ${({ color }) =>
    color === "secondary" &&
    css`
      align-self: flex-start;
    `};
  margin-top: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  padding: 0;
  margin-bottom: 30px;
`;

const PreviewContainer = styled.li`
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;

  ${mediaQueries.md(css`
    width: 130px;
    min-width: 130px;
  `)}

  ${mq.mdDown} {
    margin-right: 10px;
  }
`;

const ErrorText = styled(SupportFeedback)`
  margin-top: 10px;
`;
