import React from "react";
import { PageEvents, PhotoFile } from "../definitions/commonTypes";
import SimpleProfileImage from "wizard/pages/common/SimpleProfileImage";

interface InputProfileImageProps extends PageEvents<PhotoFile | undefined> {
  file?: PhotoFile;
}

const InputProfileImagePage: React.FC<InputProfileImageProps> = ({ file, ...props }) => {
  return (
    <SimpleProfileImage
      file={file}
      continueDisabled={!file?.cropped}
      title="Imagem do perfil do seu projeto"
      {...props}
    />
  );
};

export default InputProfileImagePage;
