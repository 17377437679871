import React from "react";
import styled from "styled-components/macro";
import Overlay from "components/Overlay/Overlay";
import Ellipsis from "components/Loading/Ellipsis";

interface DoingProps {
  isOpen?: boolean;
}

const DoingWork: React.FC<DoingProps> = ({ children, ...props }) => {
  return (
    <Overlay isOpen={props.isOpen}>
      <Ellipsis size={"md"} />
      <TransitionContainer>{children}</TransitionContainer>
    </Overlay>
  );
};

export default DoingWork;

const TransitionContainer = styled.div`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors?.support_text};
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
