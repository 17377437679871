import ButtonPrimary from "v3/components/Button/ButtonPrimary";
import { ellipsis } from "components/common/style";
import { rem } from "polished";
import React, { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import { getCurrency } from "utils/helpers";
import Text from "v3/components/common/Text";
import Badge from "../Badge/Badge";
import useTranslationV3 from "hooks/useTranslationV3";
import mqV3 from "utils/mediaQueriesV3";
import IconOpportunity from "../Icons/IconOpportunity";
import { DeliveryWay } from "core/api/definitions";

export interface OpportunityCardInterface {
  title: string;
  project: {
    name: string;
    link?: string;
    target?: string;
  };
  location: string;
  type?: "volunteer" | "material" | "collection";
  available: number | string;
  total: number | string;
  isOver?: boolean;
  isRemote: boolean;
  actionSlug: string;
  projectSlug: string;
  vacancySlug?: string;
  donationID?: string;
  deliveryWay?: DeliveryWay;
  onContainerClick?(): void;
}
interface OpportunityCardProps
  extends Omit<OpportunityCardInterface, "projectSlug" | "actionSlug" | "vacancySlug" | "donationID"> {
  className?: string;
  to: string | Object;
  tagContainer?: React.ElementType;
}

const OpportunityCardComp = ({
  type,
  title,
  project,
  location,
  total,
  available,
  to,
  isOver,
  onContainerClick,
  tagContainer,
  className,
  isRemote,
}: OpportunityCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslationV3();
  const history = useHistory();

  const formatCountCallback = useCallback(
    (total: number | string, available: number | string) => {
      const countSTR = typeof total === "number" ? total.toString() : total;

      if (type === "volunteer" || type === undefined) return Math.floor(parseInt(countSTR || "0"));
      if (type === "collection")
        return total.toLocaleString(language, { style: "currency", currency: getCurrency(language) });
      if (type === "material") return available;
    },
    [type, language]
  );

  const calcPercentageMemorized = useMemo(() => {
    if (!available || !total) return undefined;
    const availableNumber = typeof available !== "number" ? parseInt(available) : available;
    const totalNumber = typeof total !== "number" ? parseInt(total) : total;
    const result = Math.floor(((availableNumber || 1) * 100) / (totalNumber || 1));
    return result > 100 ? 100 : Math.abs(100 - result);
  }, [available, total]);

  const containerClickCallback = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const isTagA = (e.target as HTMLInputElement).tagName === "A";
      if (!isTagA && !isOver) {
        if (to) history.push(to);
        if (!to && onContainerClick) onContainerClick();
      }
    },
    [to, onContainerClick, isOver, history]
  );

  const buttonPropsMemorized = useMemo(() => {
    const result = to ? { to, tag: Link } : { onClick: onContainerClick || (() => {}) };
    return isOver ? {} : result;
  }, [onContainerClick, isOver]);

  const projectLinkMemorized = useMemo(
    () =>
      project?.target ? { tag: "a", href: project?.link, target: project?.target } : { tag: Link, to: project?.link },
    [project]
  );

  return (
    <Container onClick={containerClickCallback} tag={tagContainer} className={className}>
      <Header>
        <Title size="xs" color="neutralLight">
          {t([`cardOpportunity.type.${type}.title`, "cardOpportunity.type.volunteer.title"], { ns: "components" })}
        </Title>
      </Header>
      <Main>
        <Presentation>
          <Describe>
            <Text title={title} color="neutralBase">
              {title}
            </Text>
            <IconOpportunity icon={type} />
          </Describe>
          <div>
            <Text {...projectLinkMemorized} size="sm" color="secondary">
              {project.name}
            </Text>
          </div>
        </Presentation>
        <Info>
          <Count>
            <Text tag="span" color="neutralBase">
              {/* {formatCountCallback(total, available)} */}
              {available}
            </Text>

            <Label>
              {t([`cardOpportunity.type.${type}.label`, "cardOpportunity.type.volunteer.label"], { ns: "components" })}
            </Label>
            <Progress percentage={calcPercentageMemorized} />
          </Count>
          {isOver && <Badge color="neutralLight">{t("plain:Encerrada")}</Badge>}
          {!isOver && (
            <ButtonPrimary size="sm" {...buttonPropsMemorized}>
              {t([`cardOpportunity.type.${type}.action`, "cardOpportunity.type.volunteer.action"], {
                ns: "components",
              })}
            </ButtonPrimary>
          )}
        </Info>
      </Main>
      {
        <Footer>
          <Text color="neutralLight" size="sm">
            {isRemote ? t("plain:Remota") : location || t("plain:Sem endereço")}
          </Text>
        </Footer>
      }
    </Container>
  );
};

const OpportunityCard = styled(OpportunityCardComp)``;

export default OpportunityCard;

const Container = styled(({ tag: Tag, ...props }) => (Tag ? <Tag {...props} /> : <div {...props} />))`
  position: relative;
  flex: 1;
  min-width: 270px;
  max-width: 314px;
  min-height: 333px;
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  border-radius: 6px;
  margin: 0 15px 30px;
  transition: ${transition.fast("box-shadow", "border-color")};
  background-color: ${({ theme }) => theme.v3.colors.neutralWhite};
  cursor: pointer;

  :hover {
    box-shadow: 0px 10px 30px rgba(13, 51, 32, 0.1);
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  ${mqV3.smDown} {
    max-width: 276px;
  }

  ${mqV3.xsDown} {
    max-width: unset;
    min-width: 250px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.v3.spacing.sm} ${({ theme }) => theme.v3.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
`;

const Main = styled.div`
  padding: ${({ theme }) => theme.v3.spacing.md};
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.v3.spacing.sm} ${({ theme }) => theme.v3.spacing.md};
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
`;

const Title = styled(Text)`
  text-transform: uppercase;
  font-weight: 500;
`;

const Describe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};

  & > p {
    ${ellipsis};
    max-width: 100%;
    -webkit-line-clamp: 3;
  }
`;

const Presentation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
  min-height: 103px;

  ${Text} {
    padding: 0;
  }

  svg {
    min-width: 48px;
    height: 48px;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.p`
  font-size: ${rem(9)};
  line-height: ${rem(10)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;
  max-width: 68px;
`;

const Count = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${Text} {
    margin-right: ${({ theme }) => theme.v3.spacing.xs};
  }
`;

const Progress = styled.div<{ percentage?: number }>`
  position: absolute;
  bottom: -10px;
  width: 80px;
  height: 2px;
  background-color: ${({ theme }) => theme.v3.colors.neutralLightest};
  border-radius: 5px;

  :before {
    content: "";
    position: absolute;
    height: 2px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    background-color: ${({ theme }) => theme.v3.colors.primary};
    ${({ percentage }) =>
      percentage !== undefined &&
      css`
        width: ${percentage}%;
      `}
  }
`;
