import { showAlert, showFeedback } from "core/alerts/actions";
import api from "core/api";
import { LoadingStatus } from "core/api/definitions";
import { SubscribeAssignRequest } from "core/api/subscribe/assign";
import { StoreNewMatchmakerUserRequest, StoreNewUserRequest } from "core/api/users/storeNewUser";
import { login } from "core/auth/actions";
import { modalClose } from "core/pages/actions";
import { subscribeActions } from "core/subscribe/subscribe_actions";
import { createStoreNewUser } from "core/wizard/create/user";
import { LocationDescriptor } from "history";
import useCountry from "hooks/useCountry";
import useTranslationV3 from "hooks/useTranslationV3";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { APIParamsToString } from "utils/helpers";
import ModalBaseContainer, { ModalClose } from "../ModalBase";
import ConfirmationStep from "./Confirmation";
import FinishedStep from "./Finished";
import SignInStep from "./SignIn";
import SignUpStep, { SubmitErrorSignUp } from "./SignUp";
import { parsePhoneNumber, PhoneNumber } from "react-phone-number-input";

interface DoCompleted {
  success: boolean;
  response?: any;
}
export interface OnSubmitConfirmationSignUp {
  submitConfirmationFormData?: Partial<StoreNewUserRequest> | Partial<StoreNewMatchmakerUserRequest>;
  onConfirmation?({}: DoCompleted): void;
}
interface ModalSignInUpProps {
  isOpen?: boolean;
  step?: StepType;
  toggle(): void;
  onSubmitConfirmation?: OnSubmitConfirmationSignUp;
  redirectTo?: {
    signIn?: string | Object;
    signUp?: string | Object;
  };
}

export interface Base {
  onSubmit(e: React.FormEvent): void;
  toggleStep(): void;
  loading: LoadingStatus;
}

export interface SignUpFormData {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
  password_confirmation?: string;
  accept_terms?: boolean;
  acceptToReceiveEmails?: boolean;
}

type StepType = "signIn" | "signUp" | "confirmation" | "finished" | undefined;

const ModalSignInUp = ({ isOpen, toggle, step, redirectTo, onSubmitConfirmation }: ModalSignInUpProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslationV3();
  const [timer, setTimer] = useState<number | undefined>();

  const { country, isInternationalPhone } = useCountry();

  const { state } = useLocation<{
    from?: LocationDescriptor;
    signInUpSubscribeData?: SubscribeAssignRequest;
  }>();

  const [modalStep, setModalStep] = useState<StepType>();
  const [signUpData, setSignUpData] = useState<SignUpFormData>({
    phone: isInternationalPhone ? country : "",
  });
  const [signUpError, setSignUpError] = useState<SubmitErrorSignUp[]>();
  const [loading, setLoading] = useState<LoadingStatus>("idle");

  const redirectToLocal = state?.from || routes.opportunities.toString();

  const { onConfirmation } = onSubmitConfirmation || { onConfirmation: undefined };
  const doAction = useCallback(
    async (to?: string | Object) => {
      if (to) {
        toggle();
        history.push(to);
      } else if (state?.signInUpSubscribeData) {
        const r = (await dispatch(subscribeActions.assign(state?.signInUpSubscribeData))) as any;
        if (subscribeActions.assign.fulfilled.match(r)) {
          setModalStep("finished");
        } else {
          dispatch(showAlert("danger", t([`errors.${r?.payload?.response?.message}`, "errors.default"])));
        }
      } else if (state?.from) {
        toggle();
        if (typeof state.from === "string") {
          history.push(state.from);
        } else {
          history.push({
            ...state.from,
            state: {
              ...((state.from.state as Object) || {}),
              justLogged: true,
            },
          });
        }
      } else if (!onConfirmation) {
        toggle();
        history.push(routes.opportunities.toString());
      }
    },
    [history, toggle, onConfirmation, state]
  );

  const onCompletedSubmitConfirmation = useCallback(
    (data: DoCompleted) => {
      if (!onConfirmation) return;
      onConfirmation(data);
    },
    [onConfirmation]
  );

  const onSubmitLoginCallback = useCallback(
    async (data: { email: string; password: string; memorizePassword: boolean }) => {
      setLoading("loading");
      const response: any = await dispatch(
        login({
          email: data.email,
          password: data.password,
          redirect: redirectTo?.signIn ? undefined : redirectToLocal,
        })
      );

      if (login.rejected.match(response)) {
        dispatch(showAlert("danger", t(`${response?.payload?.response?.message_translated}`)));
        setLoading("error");
      } else {
        if (login.fulfilled.match(response)) {
          dispatch(modalClose());
          await doAction(redirectTo?.signIn);
        }
      }
    },
    [redirectTo, redirectToLocal, t, dispatch, setLoading, doAction]
  );

  const onSubmitRegistrationCallback = useCallback(
    async (data: SignUpFormData) => {
      if (!isEqual(data, signUpData)) setSignUpData(data);
      setLoading("loading");

      let phoneNumber = "";
      let phoneCountry = country;

      const phoneParsed = parsePhoneNumber(data.phone as any);
      phoneNumber = phoneParsed?.nationalNumber ?? "";
      if (phoneParsed?.countryCallingCode) phoneCountry = `+${phoneParsed.countryCallingCode}`;

      const newData = {
        name: data?.name || "",
        email: data?.email || "",
        contacts: [
          {
            country: phoneCountry,
            type: "phone",
            value: `${phoneNumber}`.replace(/[.|-]/g, ""),
          },
        ],
        password: data?.password || "",
        password_confirmation: data?.password_confirmation || "",
        accept_terms: data.accept_terms,
        gt_18_years: data.accept_terms,
        receive_emails: data.acceptToReceiveEmails || false,
      };

      try {
        const res = await api.users.sendEmailValidationCode(newData);
        const expiratedCode = res.data["X-Expirates-Code"];
        if (expiratedCode) {
          setTimer(expiratedCode);
        }
        setLoading("ok");
        setModalStep("confirmation");
      } catch (e) {
        const params = e.response?.data?.params || {
          response: { data: { params: {} } },
        };
        dispatch(showAlert("danger", e.response?.data?.response?.message_translated));
        setLoading("error");
        setModalStep("signUp");
      }
    },
    [setModalStep, signUpData, setLoading, dispatch]
  );

  const { submitConfirmationFormData } = onSubmitConfirmation || { submitConfirmationFormData: undefined };

  const onSubmitConfirmationCallback = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const code = (e.target as any)?.code?.value || undefined;
      if (!code || code?.length !== 4 || !signUpData) return;
      setLoading("loading");

      let phoneNumber = "";
      let phoneCountry = country;

      const phoneParsed = parsePhoneNumber(signUpData.phone as any);
      phoneNumber = phoneParsed?.nationalNumber ?? "";
      if (phoneParsed?.countryCallingCode) phoneCountry = `+${phoneParsed.countryCallingCode}`;

      const response: any = await dispatch(
        createStoreNewUser({
          ...(submitConfirmationFormData || {}),
          name: signUpData?.name || "",
          email: signUpData?.email || "",
          contacts: [
            {
              country: phoneCountry,
              type: "phone",
              value: `${phoneNumber}`.replace(/[.|-]/g, ""),
            },
          ],
          password: signUpData?.password || "",
          password_confirmation: signUpData?.password_confirmation || "",
          accept_terms: signUpData.accept_terms,
          gt_18_years: signUpData.accept_terms,
          receive_emails: signUpData.acceptToReceiveEmails || false,
          code,
        })
      );

      setLoading("ok");
      if (createStoreNewUser.fulfilled.match(response)) {
        setSignUpData({
          phone: `${country}`,
        });
        await doAction(redirectTo?.signUp);
        if (onCompletedSubmitConfirmation) {
          toggle();
          onCompletedSubmitConfirmation({ success: true, response });
        }
      } else {
        const matchErrors = [/name$/, /email$/, /password$/, /(contacts(\.[\d]\.)?)/, /accept_terms$/];
        const params = response?.payload?.params || {};
        const keysOfErrors = Object.keys(params);

        const hasError = keysOfErrors.filter((keyName) => !!matchErrors.find((err) => err.test(keyName)));
        if (hasError.length) {
          const result = hasError.map((err: any, key) => {
            const type = Array.isArray(params[err]) && params[err]?.includes("is-required") ? "required" : undefined;
            const message = Array.isArray(params[err]) && params[err]?.length > 0 ? params[err][0] : undefined;
            const r = {
              name: err,
              errorOption: {
                shouldFocus: key === 0,
                message: `errors.${message}`,
                type,
              },
            };

            if (matchErrors[3].test(err)) r.name = "phone"; //This is necessary because the input name is different from the backend parameter

            return r;
          });
          setSignUpError(result);
          setModalStep("signUp");
          return;
        } else if (response.payload?.response?.message !== "users.code-invalid") {
          /* setModalStep("signUp"); */
        }

        dispatch(showAlert("danger", t([`errors.users.${response.payload?.response?.message}`, "errors.default"])));
        if (onCompletedSubmitConfirmation) onCompletedSubmitConfirmation({ success: false, response });
      }
    },
    [
      setModalStep,
      setSignUpData,
      dispatch,
      signUpData,
      country,
      doAction,
      submitConfirmationFormData,
      onCompletedSubmitConfirmation,
      toggle,
      redirectTo,
    ]
  );

  const toggleStepCallback = useCallback(() => setModalStep((prev) => (prev === "signIn" ? "signUp" : "signIn")), [
    setModalStep,
  ]);

  const toStepSignUpCallback = useCallback(() => setModalStep("signUp"), [setModalStep]);

  const onClose = useCallback(() => {
    toggle();
  }, [toggle]);

  useEffect(() => {
    const currentStep = ["signIn", "signUp"].includes(step || "") ? step : undefined;
    if (currentStep) setModalStep((prev) => (prev !== currentStep ? currentStep : prev));
  }, [step, setModalStep]);

  return (
    <ModalBaseContainer isOpen={!!isOpen} modalSize={modalStep === "confirmation" ? "md" : "sm"} /* toggle={onClose} */>
      <ModalClose onClick={onClose} />
      {modalStep === "signIn" && (
        <SignInStep onSubmit={onSubmitLoginCallback as any} toggleStep={toggleStepCallback} loading={loading} />
      )}
      {modalStep === "signUp" && (
        <SignUpStep
          onSubmit={onSubmitRegistrationCallback as any}
          toggleStep={toggleStepCallback}
          loading={loading}
          data={{
            ...(signUpData || {}),
            name: signUpData?.name || onSubmitConfirmation?.submitConfirmationFormData?.name,
          }}
          submitErrors={signUpError}
        />
      )}
      {modalStep === "confirmation" && (
        <ConfirmationStep
          onSubmit={onSubmitConfirmationCallback}
          toggleStep={toStepSignUpCallback}
          loading={loading}
          data={{ email: signUpData?.email }}
          newData={signUpData}
          setModalStep={() => setModalStep("signUp")}
          timer={timer ? timer : 5}
        />
      )}
      {modalStep === "finished" && <FinishedStep />}
    </ModalBaseContainer>
  );
};

export default ModalSignInUp;
