import create from "./create";
import edit from "./edit";
import storeNewSubscribe from "./storeNewSubscribe";
import update from "./update";
import listByAction from "./listByAction";
import assign from "./assign";
import show from "./show";
import volunteerLoggedPopupV2 from "./volunteerLoggedPopupV2";
import getCertificates from "./listCertificates";
import generateCertificates from "./generateCertificate";
import checkoutValidateMultipleVolunteers from "./checkoutValidateMultiple";
export default {
  create,
  edit,
  show,
  storeNewSubscribe,
  update,
  listByAction,
  assign,
  volunteerLoggedPopupV2,
  getCertificates,
  generateCertificates,
  checkoutValidateMultipleVolunteers,
};
