import React, { Fragment } from "react";
import { SubscribeHour } from "components/SubscribeHours/SubscribeHours";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import InputVacancyHourBase from "../common/InputVacancyHourBase";
import { PageEvents } from "../definitions/commonTypes";
import { useTranslation } from "react-i18next";

interface VacancyHourPageProps extends PageEvents<SubscribeHour[]> {
  hours: SubscribeHour[];
  isLoading?: boolean;
  continueLabel?: string;
}

// TODO(Jota): Focus next input when typing.
const InputVacancyHourPage: React.FC<VacancyHourPageProps> = ({
  hours,
  isLoading,
  onBack,
  onChange,
  continueLabel,
  onContinue,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <WizardContainer>
        <InputVacancyHourBase
          hours={hours}
          autoFocus
          helmet={t("wizard.pages.vacancyHour.title")}
          title={t("wizard.pages.vacancyHour.title")}
          formTitle={t("wizard.pages.vacancyHour.form.title")}
          description={t("wizard.pages.vacancyHour.description")}
          classNameSubscribeHours="col-md-7 col-sm-12"
          onChange={(data) => {
            if (onChange) onChange(data);
          }}
          error={error}
        />
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueLabel={continueLabel || t("plain:Concluir Cadastro")}
        continueDisabled={hours.length === 0 || isLoading}
        onContinue={() => {
          if (onContinue) onContinue(hours);
        }}
      />
    </Fragment>
  );
};

export default InputVacancyHourPage;
