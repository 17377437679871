import { Theme } from "core/api/definitions";
import { rem } from "polished";

import { Platform, DashProfile } from "core/api/definitions";

export const DEFAULT_THEME_V3 = {
  colors: {
    neutralBase: "#274264",
    neutralLight: "#8798AD",
    neutralLightness: "#BCC4DE",
    neutralLightest: "#E6EAF6",
    neutralBackground: "#F9FAFC",
    neutralWhite: "#fff",
  },
  spacing: {
    xxs: "4px",
    xs: "8px",
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "40px",
    xxl: "80px",
    huge: "160px",
  },
  fontSize: {
    xxs: rem(9),
    xs: rem(12),
    sm: rem(14),
    md: rem(18),
    lg: rem(24),
    xl: rem(32),
    xxl: rem(40),
  },
  boxShadow: {
    l1: "0px 1px 2px #E2E6F4",
    l2: "0px 2px 4px #E2E6F4",
    l3: "0px 10px 30px rgba(13, 51, 32, 0.1)",
  },
};

export const PLATFORM: Platform = process.env.REACT_APP_PLATFORM as any;

export const COPYRIGHT_YEAR = new Date().getFullYear();

export const SECOND = 1000;

export const MINUTE = 60 * SECOND;

export const HOUR = 60 * MINUTE;

export const DAY = 24 * HOUR;

export const DEFAULT_THEME: Theme = {
  // color: "white",
  colors: {
    // white: "#fff",
    primary: "#21D170",
    // primary_text: "#FFFFFF",
    secondary: "#495EEC",
    // secondary_text: "#FFFFFF",
    tertiary: "#F0F2FA",
    quaternary: "#F57062",
    // link_text: "#8798AD",
    support_text: "#8798AD",
    warning: "#fff3cd",
    // headings: {
    //   h3: "#18235E",
    // },
    secondary_700: "#18235E",
    info: "#495EEC",
    success: "#21D170", // TODO(Jota): Not into origin.
    danger: "#F67467",
    // disabled: "#BFC5D2",
    panel: {
      waitingApproval: "#8798AD", // TODO(Jota): Not into origin.
      approved: "#495EEC",
      disapproved: "#F57062",
    },
  },
  // paragraph_color: "black",
} as any;

export const Z_INDEX = {
  HEADER: 5,
  POPUP: 10,
  ALERT: 1060,
  MENU_MOBILE: {
    WRAPPER: 1000,
  },
};

export const STORE_KEYS = {
  DASHPROFILE: "panel.dashProfile",
  BLC: "panel.blcInformation",
  INFO: {
    DASH_PROFILE_CHANGE: "infos.dashProfileChange",
  },
};

export const MODALS = {
  DASH_PROFILE_CHANGE: "dashProfileChange",
  CHANGE_PASSWORD: "changePassword",
  CHANGE_EMAIL: "changeEmail",
  SIGN_IN: "signIn",
  SIGN_UP: "signUp",
  VACANCY: "vacancy",
  DONATIONS: "donations",
  FEEDBACK: "feedback",
  FORGOT_PASSWORD: "forgotPassword",
  NEW_PASSWORD: "password",
  CREATE_TAG: "createTag",
  REPORT_TAG: "reportTag",
  FANS: "futebol-onboarding",
};

export const CONTAINER_BASE = "850px";

export const ACCEPT_IMAGE = "image/jpg,image/jpeg,image/png,image/gif";

export const SIZE_IMAGE = {
  SIZE_2MB: 2000000,
};

export const ERRORS_IMAGE = {
  size: "imageSize",
  type: "imageType",
  weight: "imageWeight",
};

export const DROPZONE_PROPS = {
  maxSize: SIZE_IMAGE.SIZE_2MB,
  accept: "image/jpg,image/jpeg,image/png,image/gif",
  multiple: true,
};

export const CROP_SIZE = 175;

export const CROP_SIZE_COVER = {
  width: 848 / 2,
  height: 218,
  ratio: 848 / 2 / 218,
  // width: 818,
  // height: 218,
  // ratio: 818/218
};

export const CROP_CONFIG = {
  crop: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  zoom: 1,
  aspect: 1,
};

export const ENV = process.env.REACT_APP_STAGE;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_BLOG_URL = process.env.REACT_APP_API_BLOG_URL;
export const API_METADATA = "https://cgmjl1pzde.execute-api.sa-east-1.amazonaws.com/dev/api/metadata";

export const PREVENT_DEPLOY_CACHE = process.env.REACT_APP_PREVENT_DEPLOY_CACHE || "";

export const DEFAULT_NAME = process.env.REACT_APP_DEFAULT_NAME;
export const DEFAULT_USER = process.env.REACT_APP_DEFAULT_USER;
export const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;
export const DEFAULT_PHONE = process.env.REACT_APP_DEFAULT_PHONE;

export const SENTRY = process.env.REACT_APP_SENTRY;
export const USER_WAY = process.env.REACT_APP_USER_WAY;
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HAND_TALK_TOKEN = process.env.REACT_APP_HAND_TALK_TOKEN;
export const HAND_TALK_AVATAR = process.env.REACT_APP_HAND_TALK_AVATAR;
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyDWSRXXinf2XRLtUiG64zBa8NMVEXRFonY";

export const deliveryWays = [
  { label: "No local", value: "in_loco" },
  { label: "A combinar", value: "to_combine" },
];

export const deliveryWaysChile = [
  { label: "En el lugar", value: "in_loco" },
  { label: "Hacer coincidir", value: "to_combine" },
];

export const PROFILE_TYPES: { name: string; value: DashProfile }[] = [
  // TODO(Jota): To translate it.
  { name: "Perfil Voluntário", value: "volunteer" },
  // TODO(Jota): To translate it.
  { name: "Perfil Projeto", value: "project" },
];

export type ImageCrop<T = any> = {
  image: T | undefined;
  crop: {
    x: number;
    y: number;
  };
  zoom: number;
  aspect: number;
};

export const GENERIC_STATUS_FILTERS = ["active", "pending", "deactivated", "reject"];

export const DEFAULT_COORDINATES = [-46.641581099999996, -23.541128399999998]; //SP

export const CLASSNAME_OPEN_NAV = "tb-nav-panel-open";

export const CLASSNAME_CLOSED_NAV = "tb-nav-panel-closed";

export const CLASSNAME_FLOAT_NAV = "tb-nav-panel-float";

export const NAVBAR_PANEL_OPENED_WIDTH = 256;

export const NAVBAR_PANEL_CLOSED_WIDTH = 72;

export const NAVBAR_STATUS_CHANGED = "navbar-status-changed";
