import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { BaseTitle } from "components/common/BaseTitle";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "components/common/Dropdown";
import Icon from "components/Icon/Icon";
import SupportText from "components/common/SupportText";
import { PageEvents } from "wizard/pages/definitions/commonTypes";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { ProjectType } from "core/api/definitions";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import Card, { CardContainer, CardCol } from "wizard/components/Card";
import CardItem, { CardItemProps } from "wizard/components/Card/CardItem";
import CardAnimate from "wizard/components/Card/CardAnimate";
import { TitleContainer } from "wizard/components/Layout/Content";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import { Helmet } from "react-helmet";
import { useUser } from "hooks/useUser";
import useProjectType, { ProjectTypeInfo } from "hooks/useProjectType";
import Text from "v3/components/common/Text";

type ProjectTypeWithNone = ProjectType | "none";

interface TypeProjectProps extends Exclude<PageEvents<ProjectType>, "onContinue"> {
  projectType?: ProjectTypeWithNone;
  onContinue: (projectType: ProjectType, replace?: boolean) => void;
}

function findProject(projectsObj: string, projectSelected?: ProjectTypeWithNone) {
  return !!projectSelected && projectsObj === projectSelected;
}

const keydownCallback = (fnc: () => void) => (e?: React.KeyboardEvent<HTMLElement>) => {
  if (!e) {
    fnc();
    return;
  }

  switch (e.keyCode) {
    case 13:
      fnc();
      break;
    case 9:
      return;
  }
  e.preventDefault();
};

const handleIcon = (type: ProjectType) => {
  if (type === "collective") return "people-carry";
  if (type === "movement") return "ball-pile";
  if (type === "personal") return "person-sign";
};

const SelectProjectTypePage: React.FC<TypeProjectProps> = ({ projectType, onContinue, onChange, onBack, error }) => {
  const { t } = useTranslation();

  // projectTypes from the origin
  const { projectTypeJoined } = useProjectType();

  const hasOneProjectType = projectTypeJoined.length === 1;

  const [projectLabelDropdown, setprojectLabelDropdown] = useState<ProjectTypeInfo<ProjectTypeWithNone> | undefined>(
    undefined
  );

  const { hasPersonalProject } = useUser();
  const { organizationTypes, othersTypes } = useProjectType();

  const projectTypeNone: ProjectTypeInfo<ProjectTypeWithNone> = useMemo(
    () => ({ value: "none", label: t("common.select") }),
    [t]
  );

  useEffect(() => {
    if (hasOneProjectType && onContinue && projectTypeJoined && projectTypeJoined[0]) {
      onContinue(projectTypeJoined[0].value, true);
    }
  }, [onContinue, hasOneProjectType, projectTypeJoined]);

  useEffect(() => {
    const _projectLabel = organizationTypes.find((project) => findProject(project.value, projectType));

    if (_projectLabel) {
      setprojectLabelDropdown(_projectLabel);
    } else {
      setprojectLabelDropdown(projectTypeNone);
    }
  }, [projectType, projectTypeNone, organizationTypes, t]);

  const onContinueCallback = useCallback(() => {
    if (onContinue && projectType) {
      onContinue(projectType);
    } else {
      //TODO(Sabrina): See what error style will be shown
    }
  }, [onContinue, projectType]);

  const isOrganization = useMemo(() => {
    return !!organizationTypes.find((v) => v.value === projectType) || projectType === "none";
  }, [projectType, organizationTypes]);

  const personalCardProps = useMemo(() => {
    return hasPersonalProject
      ? { id: "personalCard", hint: t("wizard.pages.projectType.body.cards.personal.hint") }
      : {};
  }, [hasPersonalProject, t]);

  return (
    <>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.projectType.body.title")} />
          <Title tag="h1" size="lg">
            {t("wizard.pages.projectType.body.title")}
          </Title>
          <Text color="neutralLight" size="sm">
            {t("wizard.pages.projectType.body.description")}
          </Text>
        </TitleContainer>
        <CardContainer id="projectTypeCards" className="justify-content-center">
          {organizationTypes.length !== 0 && (
            <CardAnimate selected={isOrganization}>
              <CustomCardItemFront
                selected={isOrganization}
                className="front"
                icon="home-heart"
                title={t("wizard.pages.projectType.body.cards.organization.title")}
                content={t("wizard.pages.projectType.body.cards.organization.description")}
                onClick={() => {
                  if (onChange) onChange(projectLabelDropdown?.value || "none");
                }}
                onKeyDown={keydownCallback(() => {
                  if (onChange) onChange(projectLabelDropdown?.value || "none");
                })}
              />
              <CustomCardBack selected={isOrganization} className="back">
                <BaseTitle strong={true} textAlign="center">
                  {t("wizard.pages.projectType.body.cards.organization.title")}
                </BaseTitle>
                <SupportText textAlign="center">
                  {t("wizard.pages.projectType.body.cards.organization.description")}
                </SupportText>
                <Container>
                  <CustomDropdown selected={isOrganization}>
                    <DropdownToggleStyled>
                      {projectType && projectLabelDropdown?.label
                        ? projectLabelDropdown?.label
                        : t("wizard.pages.projectType.body.cards.organization.dropdown.text")}
                      <Icon type="down-chevron" />
                    </DropdownToggleStyled>
                    <DropdownMenuStyled>
                      {[projectTypeNone, ...organizationTypes].map((type) => (
                        <DropdownItemStyled
                          key={type.value}
                          onClick={() => {
                            if (onChange && type.value !== "none") onChange(type.value);
                          }}
                          selected={projectLabelDropdown && projectLabelDropdown?.value === type.value}
                        >
                          {type.label}
                        </DropdownItemStyled>
                      ))}
                    </DropdownMenuStyled>
                  </CustomDropdown>
                </Container>
              </CustomCardBack>
            </CardAnimate>
          )}
          {othersTypes.map((other, index) => {
            const cardProps = other.value === "personal" ? personalCardProps : {};
            const ignoreAction = hasPersonalProject && other.value === "personal";

            return (
              <CustomCardSelectable
                {...cardProps}
                selected={projectType === other.value}
                icon={handleIcon(other.value)}
                title={t(`wizard.pages.projectType.body.cards.${other.value}.title`)}
                content={t(`wizard.pages.projectType.body.cards.${other.value}.description`)}
                disabled={ignoreAction}
                onClick={() => {
                  if (ignoreAction) return;
                  if (onChange) onChange(other.value);
                }}
                onKeyDown={keydownCallback(() => {
                  if (ignoreAction) return;
                  if (onChange && !hasPersonalProject) onChange(other.value);
                })}
                key={index}
              />
            );
          })}
        </CardContainer>
        {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
        <ActionsFooter
          continueLabel="Continuar"
          continueDisabled={!projectType || projectType === "none"}
          onContinue={onContinueCallback}
          onBack={onBack}
        />
      </WizardContainer>
    </>
  );
};

export default SelectProjectTypePage;

const CustomDropdown = styled(UncontrolledDropdown)<{ selected: boolean }>`
  margin: 20px 0;
  opacity: 0;
  transition-delay: 0.16s;
  transition-duration: 0.2s;
  transition-property: opacity;

  ${({ selected }) =>
    selected &&
    css`
      opacity: 1;
      transition-delay: 0.2s;
    `}
`;

const DropdownToggleStyled = styled(DropdownToggle)`
  z-index: 9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 11.15px 25px 11.45px 10px;

  svg {
    right: 10px;
  }
`;

const DropdownMenuStyled = styled(DropdownMenu)`
  z-index: 19;
  width: auto !important;
`;

const DropdownItemStyled = styled(DropdownItem)<{ selected?: boolean }>`
  ${({ selected }) =>
    selected &&
    css`
      background: #f4f4f4;
    `}
`;

const Container = styled.div`
  width: 100%;
`;

const CustomCardSelectable = (props: CardItemProps) => {
  return (
    <CardCol md={3} sm={6} xs={12}>
      <CustomCardItem {...props} />
    </CardCol>
  );
};

const CustomCardItem = styled(CardItem)`
  cursor: pointer;
  &.disabled {
    cursor: default;
  }
`;

const CustomCardItemFront = styled(CustomCardItem)`
  margin: 0;
`;

const CustomCardBack = styled(Card)`
  flex-direction: column;
  margin: 0;
`;

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
`;
