import React from "react";
import { useTranslation } from "react-i18next";
import { PageEvents } from "../definitions/commonTypes";
import MultiLineInput from "../common/MultilineInputPage";

interface InputProjectDescriptionPage extends PageEvents<string> {
  description: string;
  minlength?: number;
  error: any;
}

const InputProjectDescriptionPage: React.FC<InputProjectDescriptionPage> = ({
  minlength,
  description,
  error,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <MultiLineInput
      title={t("wizard.pages.project.description.title")}
      placeholder={t("wizard.pages.project.description.placeholder")}
      description={t("wizard.pages.project.description.subtitle")}
      clue={t("wizard.pages.project.description.clue")}
      required
      value={description}
      error={
        error !== undefined
          ? error.indexOf("does-not-minimum-character-required") != -1
            ? (error[
                error.indexOf("does-not-minimum-character-required")
              ] = t("plain:O numero minimo de caracteres nao foi atingido", { minLenght: minlength ?? 180 }))
            : error
          : undefined
      }
      {...props}
      maxLength={1000}
      totalLength={description.trim().length}
      continueDisabled={!!error || !description}
    />
  );
};

export default InputProjectDescriptionPage;
