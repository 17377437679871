import React from "react";
import SimpleInputCepPage from "../common/SimpleInputCepPage";
import { PageEvents } from "../definitions/commonTypes";
import { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import { useTranslation } from "react-i18next";

interface MatchmakerAddressPageProps extends PageEvents<LocationWithAddress> {
  value?: LocationWithAddress;
}

const MatchmakerAddressPage = ({ ...props }: MatchmakerAddressPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SimpleInputCepPage
      title={t("wizard.stepsWizard.wizardWhichTown.title").toString()}
      placeholder={t("wizard.stepsWizard.wizardWhichTown.input.title").toString()}
      icon={["fal", "map-marker-alt"]}
      continueDisabled={!!props.error || !props.value}
      {...props}
    />
  );
};

export default MatchmakerAddressPage;
