import { rem } from "polished";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import ModalSubscriptionBase, {
  SubjectInfo,
  SubscriptionContentWrapper,
  TitleSection,
  ScrollbarsStyled,
  ThumbVertical,
  TrackVertical,
  SubscriptionContentScrollable,
  ModalHeaderOpportunity,
} from "./ModalSubscriptionBase";
import Text from "../common/Text";
import useTranslationV3 from "hooks/useTranslationV3";
import mqV3 from "utils/mediaQueriesV3";
import { useDispatch } from "react-redux";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { subscribeActions } from "core/subscribe/subscribe_actions";
import { useReduxSelector } from "hooks/useReduxSelector";
import {
  Contact,
  Login,
  SubscribeTimeConfigurationsTime,
  SubscribeWeekday,
  SubscribeWeekdayAvailability,
} from "core/api/definitions";
import { dateFromAPI, format, formatFullMonthYear, formatTimeShort } from "utils/date";
import { capitalize, debounce } from "lodash";
import { compareAsc, isPast, isToday } from "date-fns";
import routes from "routes";
import { reverse } from "named-urls";
import useMountedRef from "hooks/useMountedRef";
import { Slot, SubscribeAssignRequest } from "core/api/subscribe/assign";
import { modalClose } from "core/pages/actions";
import { useUser } from "hooks/useUser";
import FinishedStep from "./ModalSignInUp/Finished";
import ModalBaseContainer, { ModalBody, ModalClose, ModalFooter } from "./ModalBase";
import { showAlert, showFeedback } from "core/alerts/actions";
import Button from "../Button/Button";
import SignInSignUpSteps from "./ModalSignInUp/Steps";
import Loading from "components/Loading/Loading";
import useCountry from "hooks/useCountry";
import RequestPhone from "./ModalRequestPhone";
import { PhoneNumber } from "react-phone-number-input";
import { isDateEqual } from "utils/date";
import { openInRoutes } from "utils/helpers";
import { Scrollbars } from "react-custom-scrollbars";
import { TrackHorizontal } from "components/common/style";
import ModalRequestUniqueDocument, {
  ModalRequestDocumentError,
} from "panel/components/Modal/ModalRequestUniqueDocument";
import { UniqueDocument } from "core/api/users/getGeneralData";
import { updateUniqueDocument } from "core/panel/user/settings";
import { validateCPF, validateNIF } from "utils/yup";
import cookies from "core/cookies";

interface MonthDay {
  day: SubscribeWeekday;
  dateDay: number;
  dateWeekday: string;
  times: {
    info: SubscribeTimeConfigurationsTime;
    startDt: Date;
    endDt: Date;
    start: string;
    end: string;
    availability: SubscribeWeekdayAvailability;
  }[];
}

interface Month {
  title: string;
  days: MonthDay[];
}

interface MonthMap {
  [proName: string]: Month;
}

interface SelectedTime {
  dayId: string;
  timeId: string;
}

/**
 * Handles error form actions.
 *
 * @param r
 */
export const handleAssignError = (dispatch: any, r: any) => {
  dispatch(showAlert("danger", "Não foi possível efetuar a inscrição."));
};

interface ModalVolunteerVacanciesProps {
  isOpen: boolean;
  onClose(): void;
  subscribeSlug?: string;
}

const today = new Date();

const ModalVolunteerVacancies = ({
  isOpen,
  onClose,
  subscribeSlug: subscribeSlugProps,
}: ModalVolunteerVacanciesProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslationV3();
  const history = useHistory();
  const location = useLocation<{ justLogged?: string }>();
  const params = useParams();
  const { country: countryCode } = useCountry();
  const { personalDocumentType } = useCountry();
  const [uniqueDocument, setUniqueDocument] = useState<UniqueDocument>({
    type: personalDocumentType,
    value: "",
  });
  const [hasErrorModalRequest, setHasErrorModalRequest] = useState<{
    type?: ModalRequestDocumentError;
    message?: string;
  }>();
  const [uniqueDocumentLoading, setUniqueDocumentLoading] = useState<boolean>(false);
  const [hasPersonalDocument, setHasPersonalDocument] = useState({
    has_phone: false,
    has_document: false,
  });
  const { userCookie } = useUser();

  const handleDocumentValidation = useCallback(
    debounce((value: string) => {
      if (value === "") {
        setHasErrorModalRequest(undefined);
        return;
      }
      if (personalDocumentType === "cpf" && !validateCPF(value)) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
        setUniqueDocumentLoading(true);
      } else if (personalDocumentType === "nif" && !validateNIF(value)) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
        setUniqueDocumentLoading(true);
      } else if (personalDocumentType === "rut" && value.length < 7) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
        setUniqueDocumentLoading(true);
      } else {
        setHasErrorModalRequest(undefined);
        setUniqueDocumentLoading(false);
      }
    }, 1000),
    []
  );

  const mountedRef = useMountedRef();

  const DocumentSubmit = async () => {
    setUniqueDocumentLoading(true);
    const response: any = await dispatch(updateUniqueDocument({ document_number: uniqueDocument?.value ?? "" }));
    if (response.error) {
      dispatch(
        showFeedback({
          type: "danger",
          message: response.payload?.response?.message_translated,
        })
      );
      setUniqueDocumentLoading(false);
    } else {
      dispatch(
        showFeedback({
          type: "info",
          message: t("plain:Documento atualizado com sucesso"),
        })
      );
      const usr = cookies.user.get() as Login;
      cookies.user.set({ ...usr, has_document: true });
      await handleAction();
      setUniqueDocumentLoading(false);
    }
  };

  const openIn = openInRoutes(
    [routes.action.vacancy, routes.opportunities.vacancy, routes.project.vacancy, routes.blog.post.vacancy],
    [routes.action.toString(), routes.opportunities.toString(), routes.project.toString(), routes.blog.post.toString()]
  );

  const [step, setStep] = useState<"" | "login" | "success" | "phone" | "document">("");

  const { vacancySlug: subscribeSlug } = useParams<{ vacancySlug?: string }>();

  const vacancySlug = subscribeSlug || subscribeSlugProps || "";

  const subscribeInfo = useReduxSelector((state) => state.subscribeNew.subscribes[vacancySlug]);

  const matchRoute = matchPath(location.pathname, openIn?.routes || "");
  const isOpenProxy = isOpen || !!matchRoute?.isExact;

  useEffect(() => {
    if (!vacancySlug) return;
    dispatch(
      subscribeActions.get({
        slug: vacancySlug,
      })
    );
  }, [dispatch, vacancySlug]);

  /**
   * timesMap is a map that relates the time _id to its object containing the `start` and `end`.
   */
  const timesMap = useMemo(() => {
    // Creates a map for all times for easy referencing further down the method.
    const times: { [propName: string]: SubscribeTimeConfigurationsTime } = {};
    if (subscribeInfo?.data?.subscribe_data.time_configurations.times) {
      for (const t of subscribeInfo.data?.subscribe_data.time_configurations.times) {
        times[t._id!] = t;
      }
    }
    return times;
  }, [subscribeInfo]);

  // Build a bunch of information grouped by Month > Days > Hours available.
  // This structure is memorized and helps to ease the processing at the render
  // phase.
  const dates = useMemo(() => {
    if (!subscribeInfo?.data) return [];

    // Creates an array of months (first layer of the grouping) and a map of
    // months for helping to group days
    const months: Month[] = [],
      monthsMap: MonthMap = {};
    const daysSorted = [...subscribeInfo.data?.subscribe_data.time_configurations.weekdays];
    daysSorted.sort((a, b) => (a.day < b.day ? -1 : 1));
    for (const day of daysSorted) {
      const dayDate = dateFromAPI(day.day);
      const dDate = new Date(dayDate ?? today);

      if (!dayDate || (isPast(dDate) && !isToday(dDate))) {
        // TODO(Jota): Date not compliant.
        continue;
      }

      // Group by month
      const monthKey = capitalize(formatFullMonthYear(dayDate));
      if (!monthsMap[monthKey]) {
        months.push(
          (monthsMap[monthKey] = {
            title: t("plain:" + monthKey.split(" ")[0]) + " " + monthKey.split(" ")[1],
            days: [],
          })
        );
      }

      const times = subscribeInfo.data.subscribe_data.time_configurations.times
        .filter((time) => {
          const dd = new Date(dayDate);
          const start = dateFromAPI(time.start)!;
          dd.setHours(start.getHours());
          dd.setMinutes(start.getMinutes());
          return !!day.available?.find((t) => t.time_id === time._id && !isPast(dd));
        })
        .map((time) => {
          const start = dateFromAPI(time.start)!;
          const end = dateFromAPI(time.end)!;
          const startDt = new Date(dayDate);
          startDt.setHours(start.getHours());
          startDt.setMinutes(start.getMinutes());
          const endDt = new Date(dayDate);
          endDt.setHours(end.getHours());
          endDt.setMinutes(end.getMinutes());

          // Build structure to easy at the render phase.
          return {
            info: time,
            startDt,
            endDt,
            start: formatTimeShort(startDt),
            end: formatTimeShort(dateFromAPI(time.end)!),
            availability: day.available?.find((t) => t.time_id === time._id)!,
          };
        })
        .sort((a, b) => compareAsc(a.startDt, b.startDt));

      if (times.length > 0) {
        // Group by days
        monthsMap[monthKey].days.push({
          day,
          dateDay: dayDate.getDate(),
          dateWeekday: t("plain:" + capitalize(format(dayDate, "EEEE"))),
          // Finally all "times" inside of the day.
          times: times,
        });
      }
    }
    return months.filter((month) => {
      return (
        month.days.filter((day) => {
          return day.times.length > 0;
        }).length > 0
      );
    });
  }, [subscribeInfo]);

  const [selectedTimes, setSelectedTimes] = useState<SelectedTime[]>([]);

  /**
   * selectedDatesMemoized list all selected dates/times grouping by day. Also,
   * its keeps a list and a map of "times" selected per day.
   */
  const selectedDatesMemoized = useMemo(() => {
    const o: {
      [propName: string]: {
        list: string[];
        mapped: { [propName: string]: string };
      };
    } = {};
    selectedTimes.forEach((t) => {
      if (!o[t.dayId]) {
        o[t.dayId] = {
          list: [],
          mapped: {},
        };
      }
      o[t.dayId].list.push(t.timeId);
      o[t.dayId].mapped[t.timeId] = t.timeId;
    });
    return o;
  }, [selectedTimes]);

  // toggleTime un/selects "times" for the assignment.
  const toggleTime = useCallback(
    (dayId: string, timeId: string) => {
      setSelectedTimes((times) => {
        if (selectedDatesMemoized[dayId]?.mapped[timeId]) {
          return times.filter((item) => item.dayId !== dayId || item.timeId !== timeId);
        } else {
          return [
            ...times,
            {
              dayId,
              timeId,
            },
          ];
        }
      });
    },
    [selectedDatesMemoized]
  );

  const [subscribing, setSubscribing] = useState(false);

  const handleAction = useCallback(
    async (_?: React.MouseEvent, phoneData?: PhoneNumber) => {
      const user = cookies.user.get() as Login;

      if (!user) {
        setStep("login");
        return;
      }

      if (!user.has_phone && !phoneData) {
        setStep("phone");
        return;
      }

      if (!user.has_document) {
        setStep("document");
        return;
      }

      setSubscribing(true);
      try {
        // Creates a map for all times for easy referencing further down the method.
        const times: { [propName: string]: SubscribeTimeConfigurationsTime } = {};
        if (subscribeInfo?.data?.subscribe_data?.time_configurations?.times) {
          for (const t of subscribeInfo?.data?.subscribe_data?.time_configurations?.times) {
            times[t._id!] = t;
          }
        }

        const slots: Slot[] = [];
        for (const dayId in selectedDatesMemoized) {
          slots.push({
            _id: dayId,
            times: [
              {
                _id: selectedDatesMemoized[dayId].list.map((time) => timesMap[time]._id)[0],
                start: selectedDatesMemoized[dayId].list.map((time) => timesMap[time].start.slice(11, 19))[0],
                end: selectedDatesMemoized[dayId].list.map((time) => timesMap[time].end.slice(11, 19))[0],
              },
            ],
          });
        }

        const contacts: Contact[] = [
          {
            type: "phone",
            country: phoneData?.countryCallingCode ? `+${phoneData?.countryCallingCode}` : "",
            value: phoneData?.nationalNumber ?? "",
          },
        ];

        const req: SubscribeAssignRequest = {
          slots: slots,
          subscribeSlug: subscribeInfo!.data!.subscribe_data.slug!,
          accept_terms: "true",
          contacts: user.has_phone ? undefined : contacts,
        };
        const r = (await dispatch(subscribeActions.assign(req))) as any;
        if (!mountedRef.current) return;

        if (subscribeActions.assign.fulfilled.match(r)) {
          setStep("success");
        } else {
          dispatch(showAlert("danger", `${r?.payload?.response?.message_translated}`));
          setStep("");
        }
      } finally {
        if (!mountedRef.current) return;
        setSubscribing(false);
      }
    },
    [dispatch, userCookie, location, mountedRef, history, subscribeInfo, selectedDatesMemoized, timesMap]
  );

  const handleSubmitFormPhone = useCallback(
    (data?: { phone: PhoneNumber }) => {
      handleAction(undefined, data?.phone);
    },
    [handleAction, countryCode]
  );

  const handleActionRef = useRef<() => void>(handleAction);

  if (handleActionRef) {
    handleActionRef.current = handleAction;
  }

  const handleLogin = useCallback(() => {
    setSubscribing(true);

    setTimeout(() => {
      handleActionRef.current!();
    }, 400);
  }, [handleActionRef]);

  useEffect(() => {
    if (!isOpenProxy && step !== "") setStep("");
  }, [isOpenProxy, step]);

  const onCloseProxy = useCallback(() => {
    if (subscribeSlug) {
      const r = openIn?.getParent(matchRoute?.path || "");
      if (r) {
        history.push({ pathname: reverse(r, params) });
      } else {
        history.goBack();
      }
    } else {
      onClose();
    }
  }, [onClose, history, subscribeSlug, params, matchRoute]);

  return (
    <>
      <ModalBaseContainer modalSize={step !== "" ? "sm" : undefined} isOpen={isOpenProxy} toggle={onCloseProxy}>
        {subscribeInfo?.loading === "loading" && <CustomLoading />}
        {subscribeInfo?.loading === "ok" && (
          <>
            {step === "success" && (
              <>
                <ModalClose onClick={onCloseProxy} />
                <FinishedStep
                  title={t("plain:Inscrição submetida com sucesso")}
                  url={reverse(routes.action.vacancy, {
                    slug: subscribeInfo!.data!.action_data.slug,
                    vacancySlug: subscribeInfo!.data!.subscribe_data.slug,
                  })}
                >
                  {t("plain:Em breve você receberá um retorno sobre sua inscrição Fique atento ao seu e-mail")}
                </FinishedStep>
              </>
            )}
            {step === "login" && (
              <>
                <ModalClose onClick={onCloseProxy} />
                <SignInSignUpSteps
                  onLogin={handleLogin}
                  onSignUp={handleLogin}
                  setHasPersonalDocument={setHasPersonalDocument}
                  setStep={setStep}
                />
              </>
            )}
            {step === "phone" && <RequestPhone onSubmit={handleSubmitFormPhone} disabled={subscribing} />}

            {step === "document" && (
              <ModalRequestUniqueDocument
                document={uniqueDocument}
                isOpen={step === "document"}
                disabled={uniqueDocumentLoading}
                error={hasErrorModalRequest?.message}
                onSubmit={DocumentSubmit}
                onClose={() => setStep("")}
                onChange={(doc) => {
                  handleDocumentValidation(doc.value);
                  setUniqueDocument(doc);
                  if (hasErrorModalRequest) setHasErrorModalRequest({});
                }}
              />
            )}

            {step === "" && (
              <>
                <ModalHeaderOpportunity
                  title={t("plain:Vaga de Voluntariado")}
                  shortTitle={t("plain:Vaga de Voluntariado_short")}
                  subTitle={
                    subscribeInfo.data?.subscribe_data.time_accept_remote ? t("plain:Remota") : t("plain:Presencial")
                  }
                  subscriptionType="volunteer"
                  toggle={onCloseProxy}
                />
                <ModalBody>
                  <SubjectInfo
                    subscriptionType="volunteer"
                    subjectTitle={subscribeInfo?.data?.subscribe_data.title ?? ""}
                    subjectDescription={subscribeInfo?.data?.subscribe_data.description ?? ""}
                    subjectAddress={subscribeInfo?.data?.subscribe_data.address}
                    subjectIsRemote={subscribeInfo?.data?.subscribe_data?.time_accept_remote ?? false}
                    subjectFunction={subscribeInfo?.data?.function_data.title}
                    subjectMapLink={reverse(routes.listVacancyMap, {
                      slugVacancy: subscribeInfo?.data?.subscribe_data.slug ?? "",
                    })}
                    shareableUrl={reverse(routes.action.vacancy, {
                      slug: subscribeInfo?.data?.action_data.slug ?? "",
                      vacancySlug: subscribeInfo?.data?.subscribe_data.slug ?? "",
                    })}
                    ods={subscribeInfo?.data?.ods_data}
                    actionTitle={subscribeInfo?.data?.action_data.title}
                    actionLink={reverse(routes.action.toString(), {
                      slug: subscribeInfo?.data?.action_data.slug ?? "",
                    })}
                  />
                  <SubscriptionContentWrapper>
                    {/*             
              <ScrollbarsStyled
                onScroll={handleOnScroll}
                renderThumbVertical={(props) =>
                  !(props.style.display && props.style.display === "none") ? <ThumbVertical {...props} /> : <></>
                }
                renderTrackVertical={(props) =>
                  !(props.style.display && props.style.display === "none") ? (
                    <TrackVertical {...props} className="track-vertical" />
                  ) : (
                    <></>
                  )
                }
                renderThumbHorizontal={(props) =>
                  !(props.style.display && props.style.display === "none") ? <div {...props} /> : <></>
                }
              >
                <SubscriptionContentScrollable> */}
                    <TitleSection tag="h3" size="xs" color="neutralLight">
                      {dates.length !== 0 ? t("plain:Selecione seus horários") : t("plain:Nenhum horário disponível")}
                    </TitleSection>
                    <ListMonths>
                      <Scrollbars
                        style={{ width: "100%", height: "240px", paddingRight: "10px" }}
                        renderTrackHorizontal={() => <CustomTrackHorizontal />}
                        renderThumbVertical={() => <CustomThumbVertical />}
                      >
                        {dates.map((month) => (
                          <ListMonthsItem key={month.title}>
                            <MonthTitle>{month.title}</MonthTitle>
                            <ListDays>
                              {month.days.map((day) => (
                                <ListDaysItem key={day.day._id}>
                                  <DayWrapper>
                                    <Day>{day.dateDay}</Day>
                                    <Text size="sm" color="neutralLight">
                                      {capitalize(day.dateWeekday)}
                                    </Text>
                                  </DayWrapper>
                                  <ListHours>
                                    {day.times.map((time, key) => (
                                      <ListHoursItem key={key}>
                                        <HourButton
                                          disabled={time.availability.quantity === 0}
                                          active={!!selectedDatesMemoized[day.day._id]?.mapped[time.info._id!]}
                                          onClick={() => {
                                            toggleTime(day.day._id, time.info._id!);
                                          }}
                                        >
                                          {t("plain:X às X", {
                                            start: time.start,
                                            end: time.end,
                                          })}
                                        </HourButton>
                                      </ListHoursItem>
                                    ))}
                                  </ListHours>
                                </ListDaysItem>
                              ))}
                            </ListDays>
                          </ListMonthsItem>
                        ))}
                      </Scrollbars>
                    </ListMonths>
                    {/* </SubscriptionContentScrollable>
              </ScrollbarsStyled> */}
                  </SubscriptionContentWrapper>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    block
                    disabled={subscribing || selectedTimes.length == 0}
                    onClick={handleAction}
                  >
                    {t("actions.confirmSubscription.label")}
                  </Button>
                </ModalFooter>
              </>
            )}
          </>
        )}
      </ModalBaseContainer>
    </>
  );
};

export default ModalVolunteerVacancies;

const ListMonths = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const CustomTrackHorizontal = styled(TrackHorizontal)`
  display: none;
`;
const CustomThumbVertical = styled(ThumbVertical)`
  width: ${({ theme }) => theme.v3.spacing.xs};
  background: ${({ theme }) => theme.v3.colors.neutralLightest};
  &:hover {
    background: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;

const ListMonthsItem = styled.li`
  display: flex;
  flex-direction: column;
`;

const MonthTitle = styled.h4`
  font-size: ${({ theme }) => theme.v3.fontSize.md};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  line-height: ${rem(22)};
  margin: 0 0 ${({ theme }) => theme.v3.spacing.sm} 0;
  font-weight: 400;

  ${mqV3.xsDown} {
    font-size: ${({ theme }) => theme.v3.fontSize.sm};
    line-height: ${rem(20)};
  }
`;

const ListDays = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListDaysItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  padding: ${({ theme }) => theme.v3.spacing.sm} 0;

  ${mqV3.xsDown} {
    flex-wrap: wrap;
  }
`;

const DayWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Day = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  box-sizing: border-box;
  border-radius: 6px;
  font-size: ${({ theme }) => theme.v3.fontSize.md};
  line-height: 1;
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin-right: ${({ theme }) => theme.v3.spacing.sm};
`;

const ListHours = styled.ul`
  list-style: none;
  padding: 0;
  margin: -${({ theme }) => theme.v3.spacing.xxs};
  display: flex;
  max-width: 430px;
  flex-wrap: wrap;
`;

const ListHoursItem = styled.li`
  margin: ${({ theme }) => theme.v3.spacing.xxs};
`;

const HourButton = styled.button<{ active?: boolean }>`
  border: 1px solid transparent;
  background: none;
  padding: ${({ theme }) => theme.v3.spacing.xs} ${({ theme }) => theme.v3.spacing.sm};
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  line-height: ${rem(22)};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  box-shadow: ${({ theme }) => theme.v3.boxShadow.l1};
  border-radius: 6px;
  white-space: nowrap;

  &:hover {
    box-shadow: ${({ theme }) => theme.v3.boxShadow.l2};
  }

  :disabled {
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
    background: ${({ theme }) => theme.v3.colors.neutralBackground};
    box-shadow: none;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background: ${({ theme }) => theme.v3.colors.primary};
    `}

  ${mqV3.xsDown} {
    font-size: ${({ theme }) => theme.v3.fontSize.xs};
    padding: ${({ theme }) => theme.v3.spacing.xxs} ${({ theme }) => theme.v3.spacing.xs};
  }
`;

const CustomLoading = styled(Loading)`
  margin: ${({ theme }) => theme.v3.spacing.huge} 0;
`;
