import React, { useEffect, useMemo, useCallback, useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components/macro";

export interface Point {
  x: number;
  y: number;
}

export interface CropInfo extends Point {
  width: number;
  height: number;
}

interface Size {
  width: number;
  height: number;
}

export interface CropperProps {
  src?: string;
  zoom: number;
  minZoom?: number;
  maxZoom?: number;
  crop: Point;
  showCropArea?: boolean;
  cropSize: Size;
  unrestricted?: boolean;
  onMediaLoaded?: (image: Size, container: Size) => void;
  onZoomChange?: (value: number) => void;
  onCropChange?: (value: Point) => void;
  onCropComplete?: (value: CropInfo) => void;
}

function world2Zoom(zoom: number, n: number): number {
  return n * zoom;
}

function zoom2World(zoom: number, n: number): number {
  return n / zoom;
}

function minimumZoom(containerWidth: number, containerHeight: number, originalImageSize: Size) {
  if (containerWidth / containerHeight > originalImageSize.width / originalImageSize.height) {
    return containerWidth / originalImageSize.width;
  } else {
    return containerHeight / originalImageSize.height;
  }
}

const Cropper = ({
  src,
  crop,
  showCropArea,
  unrestricted,
  zoom,
  minZoom,
  maxZoom,
  onMediaLoaded,
  onZoomChange,
  onCropChange,
  onCropComplete,
}: CropperProps): JSX.Element => {
  const mounted = useRef(false);

  const container = useRef<HTMLDivElement>();
  const image = useRef<HTMLImageElement>();

  const [originalImageSize, setOriginalImageSize] = useState<Size>();
  const [internalSrc, setInternalSrc] = useState<string>();
  const [internalCrop, setInternalCrop] = useState<Point>(crop);
  const [mouseDown, setMouseDown] = useState(false);

  const containerWidth = container.current?.offsetWidth || 1,
    containerHeight = container.current?.offsetHeight || 1;

  const w2z = useCallback((n: number) => world2Zoom(zoom, n), [zoom]);
  const z2w = useCallback((n: number) => zoom2World(zoom, n), [zoom]);

  const internalMinZoom = unrestricted
    ? minZoom
    : originalImageSize
    ? minimumZoom(containerWidth || 1, containerHeight || 1, originalImageSize)
    : undefined;

  const internalMaxZoom = maxZoom;

  const imageLoadCallback = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      setOriginalImageSize({
        width: (e.target as HTMLImageElement)?.naturalWidth || 0,
        height: (e.target as HTMLImageElement)?.naturalHeight || 0,
      });
      if (onMediaLoaded) {
        onMediaLoaded(
          {
            width: (e.target as HTMLImageElement)?.naturalWidth || 0,
            height: (e.target as HTMLImageElement)?.naturalHeight || 0,
          },
          { width: containerWidth, height: containerHeight }
        );
      }
    },
    [onMediaLoaded, containerWidth, containerHeight]
  );

  useEffect(() => {
    setInternalCrop(crop);
  }, [crop]);

  const imageStyle = useMemo(() => {
    if (!originalImageSize) return;
    return {
      transform: `translate(${-w2z(internalCrop.x)}px, ${-w2z(internalCrop.y)}px)`,
      width: `${w2z(originalImageSize?.width || 0)}px`,
      height: `${w2z(originalImageSize?.height || 0)}px`,
    };
  }, [internalCrop, originalImageSize, w2z]);

  const windowMouseMoveCallback = useCallback(
    (e: React.MouseEvent) => {
      if (!originalImageSize) return;
      if (unrestricted) {
        setInternalCrop((crop) => ({ ...crop, x: crop.x - e.movementX, y: crop.y - e.movementY }));
        return;
      }

      setInternalCrop((crop) => {
        return {
          ...crop,
          x: Math.min(Math.max(0, (crop.x || 0) - z2w(e.movementX)), originalImageSize.width - z2w(containerWidth)),
          y: Math.min(Math.max(0, (crop.y || 0) - z2w(e.movementY)), originalImageSize.height - z2w(containerHeight)),
        };
      });
    },
    [unrestricted, z2w, containerWidth, containerHeight, originalImageSize]
  );

  const windowMouseUpCallback = useCallback(() => {
    setMouseDown(false);
    if (onCropChange) onCropChange(internalCrop);
  }, [onCropChange, internalCrop]);

  useEffect(() => {
    if (!mouseDown) return;
    window.addEventListener("mousemove", windowMouseMoveCallback as any);
    window.addEventListener("mouseup", windowMouseUpCallback);

    return () => {
      if (!mouseDown) return;
      window.removeEventListener("mousemove", windowMouseMoveCallback as any);
      window.removeEventListener("mouseup", windowMouseUpCallback);
    };
  }, [mouseDown, windowMouseMoveCallback, windowMouseUpCallback]);

  const containerMouseDownCallback = useCallback((e: React.MouseEvent) => {
    if (e.button !== 0) return;
    setMouseDown(true);
  }, []);

  const containerWheelCallback = useCallback(
    (e: React.WheelEvent) => {
      let layerX = typeof (e.nativeEvent as any) === "undefined" ? 0 : (e.nativeEvent as any).layerX,
        layerY = typeof (e.nativeEvent as any) === "undefined" ? 0 : (e.nativeEvent as any).layerY;
      if (typeof (e.nativeEvent as any) === "undefined") {
        let offsetParent = (e.currentTarget as HTMLElement).offsetParent as HTMLElement;
        while (offsetParent !== null) {
          layerX += offsetParent.offsetLeft;
          layerY += offsetParent.offsetTop;
          offsetParent = offsetParent.offsetParent as HTMLElement;
        }
      }

      // e.stopPropagation();
      const step = zoom < 1 ? 0.05 : 0.1;
      let newZoom = Math.round(zoom / step) * step - step * Math.sign(e.deltaY);
      if (internalMinZoom) newZoom = Math.max(newZoom, internalMinZoom);
      if (internalMaxZoom) newZoom = Math.min(newZoom, internalMaxZoom);
      if (onZoomChange) onZoomChange(newZoom);
      if (onCropChange) {
        const relativeX = zoom2World(zoom, layerX),
          relativeY = zoom2World(zoom, layerY),
          pX = (internalCrop.x || 0) + relativeX,
          pY = (internalCrop.y || 0) + relativeY;

        let x = pX - zoom2World(newZoom, layerX),
          y = pY - zoom2World(newZoom, layerY);

        if (!unrestricted) {
          x = Math.min(Math.max(0, x), (originalImageSize?.width || 0) - zoom2World(newZoom, containerWidth));
          y = Math.min(Math.max(0, y), (originalImageSize?.height || 0) - zoom2World(newZoom, containerHeight));
        }

        setInternalCrop({
          x,
          y,
        });
        onCropChange({
          x,
          y,
        });
      }
    },
    [
      internalCrop,
      zoom,
      internalMinZoom,
      internalMaxZoom,
      onZoomChange,
      onCropChange,
      unrestricted,
      containerWidth,
      containerHeight,
    ]
  );

  useEffect(() => {
    if (!container.current) return;
    const cancelWheel = (e: WheelEvent) => {
      e.preventDefault();
    };
    container.current.addEventListener("wheel", cancelWheel);
    return () => {
      container.current!.removeEventListener("wheel", cancelWheel);
    };
  }, [container.current]);

  useEffect(() => {
    if (!container.current) return;
    setInternalSrc(src);
  }, [src, container.current]);

  useEffect(() => {
    if (!mounted.current) return;

    if (onCropComplete) {
      onCropComplete({
        x: internalCrop.x,
        y: internalCrop.y,
        width: zoom2World(zoom, containerWidth),
        height: zoom2World(zoom, containerHeight),
      });
    }
  }, [onCropComplete, zoom, originalImageSize, internalCrop, containerWidth, containerHeight]);

  useLayoutEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <CrooperStyled
      ref={container as any}
      onMouseDown={containerMouseDownCallback}
      onWheel={containerWheelCallback as any}
    >
      {src && <ImgStyled ref={image as any} src={internalSrc} style={imageStyle} onLoad={imageLoadCallback} />}
      {showCropArea && <CropperArea />}
    </CrooperStyled>
  );
};

export default Cropper;

const CrooperStyled = styled.div`
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: crosshair;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImgStyled = styled.img`
  user-select: none;
  touch-action: none;
  user-drag: none;
`;

const CropperArea = styled.div`
  border: 1px solid red;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;
