import React from "react";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { TitleContainer } from "wizard/components/Layout/Content";
import { Helmet } from "react-helmet";
import SupportText from "components/common/SupportText";
import Footer, { ToBack } from "wizard/components/Layout/Footer";
import { BaseTitle } from "components/common/BaseTitle";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";

export interface RetryOrSkipErrorPage {
  title?: string;
  message: string;
  onRetry?: () => void;
  skipTo?: string | (() => void);
}

const RetryOrSkipErrorPage: React.FC<RetryOrSkipErrorPage> = ({ children, title, message, onRetry, skipTo }) => {
  const { t } = useTranslation();
  return (
    <>
      <WizardContainer>
        <TitleContainer>
          {title && <Helmet title={title} />}
          <BaseTitle size="md">Oooops!</BaseTitle>
          <SupportText size="md">{message}</SupportText>
        </TitleContainer>
        {children}
      </WizardContainer>
      <Footer centered>
        {skipTo && (
          <ToBack
            icon={false}
            text={t("wizard.common.skipStep")}
            to={typeof skipTo === "string" ? skipTo : undefined}
            onClick={typeof skipTo === "function" ? skipTo : undefined}
          />
        )}
        {onRetry && <Button onClick={onRetry}>Tentar novamente</Button>}
      </Footer>
    </>
  );
};

export default RetryOrSkipErrorPage;
