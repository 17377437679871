import React from "react";
import { useTranslation } from "react-i18next";
import SimpleInputPage from "../common/SimpleInputPage";
import { PageEvents } from "../definitions/commonTypes";

interface MatchmakerNamePageProps extends PageEvents {
  value: string;
}

const MatchmakerNamePage = ({ ...props }: MatchmakerNamePageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SimpleInputPage
      title={t("wizard.pages.matchmakerName.name")}
      placeholder={t("common.writeHere")}
      maxLength={50}
      {...props}
    />
  );
};

export default MatchmakerNamePage;
