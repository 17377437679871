import React, { ElementType } from "react";
import styled, { css } from "styled-components/macro";
import { rem, darken } from "polished";
import { Size, Colors } from "utils/types";
import { Link } from "react-router-dom";
import { FocusStyle } from "./style";

type Color = Exclude<Colors, "link">;

export interface SupportTextWrapperProps {
  tag?: "span" | "p" | "button" | ElementType;
  to?: string | Object;
  size?: Size | "xmd";
  disabled?: boolean;
  onClick?(): void;
  textAlign?: "center" | "left" | "right";
  withWeight?: number;
  color?: Color;
  [k: string]: any;
}

const SupportTextWrapper: React.FunctionComponent<SupportTextWrapperProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  to,
  disabled,
  tag: Wrapper = "span",
  ...props
}) => {
  const cProps = Wrapper === Link ? { to: to || "" } : Wrapper === "button" ? { disabled, onClick: props.onClick } : {};
  return <Wrapper {...props} {...cProps} />;
};

const SupportText = styled(SupportTextWrapper)`
  border: 0;
  background-color: transparent;
  word-wrap: break-word;
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};

  ${({ color, theme }) =>
    (color &&
      css`
        transition: color 0.3s;
        color: ${theme.colors[color]};
        :hover {
          color: ${darken(0.075, theme.colors[color])};
        }
      `) ||
    css`
      color: ${theme.colors.support_text};
    `};

  ${({ withWeight }) =>
    withWeight &&
    css`
      font-weight: ${withWeight};
    `}

  ${({ size }) =>
    size === "md" &&
    css`
      font-size: ${rem(15)};
      line-height: ${rem(22)};
      letter-spacing: 0;
    `};

  ${({ size }) =>
    size === "lg" &&
    css`
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      letter-spacing: 0;
    `};

  :focus {
    ${FocusStyle()};
  }

  ${({ tag }) =>
    tag === Link &&
    css`
      padding: 0 5px;
    `}

  ${({ theme, tag, color, disabled }) =>
    tag === "button" &&
    css`
      ${disabled &&
      css`
        color: ${darken(0.1, theme.colors.tertiary)};
        &:hover {
          color: ${darken(0.1, theme.colors.tertiary)};
        }
      `}
      ${!disabled &&
      css`
        color: ${theme.colors[color || "secondary"]};
      `}
    `}
`;

export default SupportText;
