import history from "./history";
import reports from "./reports";
import overview from "./overview";
import validateActionByAdmin from "./validateActionByAdmin";
import projectResume from "./reports/projectResume";

export default {
  history,
  reports,
  overview,
  validateActionByAdmin,
  projectResume,
};
