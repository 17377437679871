import FuncRoute from "components/Routes/FuncRoute";
import { showAlert } from "core/alerts/actions";
import api from "core/api";
import { LoadingStatus, ValidationErrorSet } from "core/api/definitions";
import routeToError from "core/validators/routeToError";
import userMatchMakerValidation from "core/validators/userMatchmaker";
import { updateStoreUser } from "core/wizard/update/user";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import { useUser } from "hooks/useUser";
import { isEmpty } from "lodash";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { matchPath, Route, Switch, useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { isAllPropsEmpty, storageGet } from "utils/helpers";
import { goto } from "utils/router";
import ModalSignInUp, { OnSubmitConfirmationSignUp } from "v3/components/Modals/ModalSignInUp";
import { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import Wizard from "wizard/components/Layout/Wizard";
import useValidation from "wizard/hooks/useValidation";
import ContinueRegistrationPage, { continuePageMessage } from "../common/ContinueRegistrationPage";
import CausesPage, { CauseSet } from "../common/SelectCausesPage";
import { PhotoFile } from "../definitions/commonTypes";
import { Terms } from "../profile/AcceptTermsPage";
import { AccessInfo } from "../profile/InputAccessInfoBase";
import MatchmakerAbilitiesPage, { AbilitySet } from "./MatchmakerAbilitiesPage";
import MatchmakerAddressPage from "./MatchmakerAddressPage";
import MatchmakerIntroductionPage from "./MatchmakerIntroductionPage";
import MatchmakerNamePage from "./MatchmakerNamePage";
import MatchmakerPhotoPage from "./MatchmakerPhotoPage";
import MatchmakerPreferencesPage from "./MatchmakerPreferencesPage";
import MatchmakerProfilePreparationPage from "./MatchmakerProfilePreparationPage";
import MatchmakerWorkPage from "./MatchmakerWorkPage";

export const WIZARD_CREATE_MATCHMAKER = "creating.matchmaker";

interface MatchmakerData {
  name?: string;
  myWork?: Skill;
  abilities?: AbilitySet;
  causes?: CauseSet;
  location?: LocationWithAddress;
  accessInfo?: AccessInfo;
  profileImage?: PhotoFile;
  gt18year?: boolean;
  terms?: Terms;
}

const defaultCreatingMatchmaker = (): MatchmakerData => ({});

const MatchmakerPage = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const dataMatchmaker = useLocalStorageRead<MatchmakerData>(WIZARD_CREATE_MATCHMAKER, defaultCreatingMatchmaker)!;
  const { t } = useTranslation();
  const { user } = useUser();

  const [name, setName] = useState(dataMatchmaker.name);
  const [myWork, setMyWork] = useState<Skill | undefined>(dataMatchmaker?.myWork);
  const [abilities, setAbilities] = useState<AbilitySet | undefined>(dataMatchmaker.abilities);
  const [causes, setCauses] = useState<CauseSet | undefined>(dataMatchmaker.causes);
  const [location, setLocation] = useState<LocationWithAddress | undefined>(dataMatchmaker.location);
  const [profileImage, setProfileImage] = useState<PhotoFile | undefined>(dataMatchmaker.profileImage);

  const [loading, setLoading] = useState<LoadingStatus>("idle");
  const [isOpen, setIsOpen] = useState<boolean>();

  useLocalStorageWrite<MatchmakerData>(
    {
      name,
      myWork,
      abilities,
      causes,
      location,
    },
    WIZARD_CREATE_MATCHMAKER
  );

  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    name: false,
    "conditionals.skills": false,
    "conditionals.interests": false,
    "conditionals.causes": false,
    "locations.city": false,
    "locations.country": false,
    "locations.state": false,
  });

  const conditionalsMemorized = useMemo(
    () => ({
      causes: Object.keys(causes || {}).filter((ck) => causes && causes[ck]) as Cause[],
      interests: Object.keys(abilities || {}).filter((ak) => abilities && abilities[ak]) as Interest[],
      skills: myWork ? [myWork] : [],
    }),
    [causes, abilities, myWork]
  );

  const resetState = useCallback(() => {
    setName(undefined);
    setMyWork(undefined);
    setAbilities(undefined);
    setCauses(undefined);
    setLocation(undefined);
    setProfileImage(undefined);
  }, []);

  const { errors, setErrors, failed: hasErrors, assignErrorsToOriginal, errorsOriginal } = useValidation(
    {
      name,
      locations: location!,
      conditionals: conditionalsMemorized,
    },
    userMatchMakerValidation.rules,
    touched,
    [name, location, conditionalsMemorized]
  );

  const stepCurrent = useMemo(() => {
    const isLogged = user ? 1 : 0;
    if (matchPath(pathname, routes.wizard.matchmaker.name)) return 1;
    if (matchPath(pathname, routes.wizard.matchmaker.work)) return 2 - isLogged;
    if (matchPath(pathname, routes.wizard.matchmaker.abillities)) return 3 - isLogged;
    if (matchPath(pathname, routes.wizard.matchmaker.causes)) return 4 - isLogged;
    if (matchPath(pathname, routes.wizard.matchmaker.address)) return 5 - isLogged;
    if (matchPath(pathname, routes.wizard.matchmaker.image)) return 6 - isLogged;
    return 0;
  }, [pathname, user]);

  //////////////////////////////////////////////////////////////////////////////
  // Pages event section

  const handleErrorAPI = useCallback(
    (params) => {
      if (params && !isEmpty(params)) {
        setErrors(params);
        const r = routeToError(params as ValidationErrorSet, userMatchMakerValidation.routes.create);
        if (r) {
          history.push(r);
          return;
        }
      } else {
        //TODO(Jeconias): Report to Sentry.
      }
    },
    [history, setErrors]
  );

  const handleLoggedUser = useCallback(async () => {
    setLoading("loading");

    const response: any = await dispatch(
      updateStoreUser({
        locations: location ? { ...location } : undefined,
        conditionals: conditionalsMemorized,
        profile_image: profileImage?.cropped,
      })
    );

    if (updateStoreUser.fulfilled.match(response)) {
      setLoading("ok");
      resetState();
      history.push(routes.wizard.matchmaker.preparation);
    } else {
      setLoading("error");
      handleErrorAPI(response?.payload?.params || {});
      dispatch(showAlert("danger", t([`errors.${response?.payload?.response?.message}`, "errors.default"])));
    }
  }, [dispatch, history, profileImage, location, t, handleErrorAPI, conditionalsMemorized, resetState, setLoading]);

  const selectNameOnContinue = useCallback(() => {
    goto(history, routes.wizard.matchmaker.work)();
  }, [history]);

  const selectMyWorkOnContinue = useCallback(() => {
    goto(history, routes.wizard.matchmaker.abillities)();
  }, [history]);

  const selectAbilitiesOnContinue = useCallback(
    (data: AbilitySet) => {
      setAbilities(data);
      goto(history, routes.wizard.matchmaker.causes)();
    },
    [history]
  );

  const selectCausesOnContinue = useCallback(
    (data: CauseSet) => {
      setCauses(data);
      goto(history, routes.wizard.matchmaker.address)();
    },
    [history]
  );

  const selectLocationOnContinue = useCallback(
    (location: LocationWithAddress) => {
      setLocation(location);
      goto(history, routes.wizard.matchmaker.image)();
    },
    [history]
  );

  const selectProfileImageOnContinue = useCallback(
    (data: PhotoFile | undefined): void => {
      setProfileImage(data);

      if (!user) {
        if (hasErrors) {
          assignErrorsToOriginal();
          const r = routeToError(errorsOriginal!, userMatchMakerValidation.routes.create);
          if (r) {
            return history.push(r);
          } else {
            // TODO(Jeconias): Report to Sentry.
            return;
          }
        }
        return setIsOpen(true);
      } else if (user) {
        handleLoggedUser();
      }
    },
    [user, hasErrors, errorsOriginal, history, setIsOpen, handleLoggedUser]
  );

  /** MATCHMAKER */
  const matchmakerRuleMemorized = useMemo(() => {
    const matchmakerData = storageGet(WIZARD_CREATE_MATCHMAKER);
    return !!matchmakerData && !isAllPropsEmpty(matchmakerData);
  }, [user]);

  const matchmakerOnContinue = useCallback(() => {
    history.replace(routes.wizard.matchmaker.name);
  }, [history]);

  const matchmakerOnUnregister = useCallback(() => {
    localStorage.removeItem(WIZARD_CREATE_MATCHMAKER);
    resetState();
    history.replace(routes.wizard.matchmaker.name);
  }, [history, resetState]);

  const onSubmitConfirmationMemorized = useMemo(
    (): OnSubmitConfirmationSignUp => ({
      submitConfirmationFormData: {
        name,
        conditionals: conditionalsMemorized,
        locations: location,
        profile_image: profileImage?.cropped,
      },
      onConfirmation: ({ success, response }) => {
        if (success) {
          resetState();
          history.push(routes.wizard.matchmaker.preparation);
        }
        if (!success) {
          handleErrorAPI(response?.payload?.params || {});
        }
      },
    }),
    [name, conditionalsMemorized, location, profileImage, history, resetState, handleErrorAPI]
  );

  return (
    <>
      <ModalSignInUp
        isOpen={isOpen}
        onSubmitConfirmation={onSubmitConfirmationMemorized}
        toggle={() => setIsOpen((prev) => !prev)}
        step="signUp"
      />
      <Switch>
        <Route path={routes.wizard.matchmaker.continue}>
          <ContinueRegistrationPage
            message={continuePageMessage("matchmaker", t)}
            rule={matchmakerRuleMemorized}
            onContinue={matchmakerOnContinue}
            onUnregister={matchmakerOnUnregister}
          />
        </Route>
        <Route path={routes.wizard.matchmaker.introduction}>
          <MatchmakerIntroductionPage to={user ? routes.wizard.matchmaker.work : undefined} />
        </Route>
        <Route path={routes.wizard.matchmaker.preparation}>
          <MatchmakerProfilePreparationPage />
        </Route>
        <Route path={routes.wizard.matchmaker.preferences}>
          <MatchmakerPreferencesPage />
        </Route>
        <Wizard
          header={{ title: t("wizard.stepsWizard.createProfile.header.title"), stepCurrent, stepMax: user ? 5 : 6 }}
        >
          <FuncRoute
            path={routes.wizard.matchmaker.name}
            rule={!user}
            unauthorized={() => routes.wizard.matchmaker.work}
          >
            <MatchmakerNamePage
              value={name || ""}
              onBack={routes.wizard.matchmaker.introduction}
              onContinue={selectNameOnContinue}
              onChange={(name) => {
                setTouched((prev) => ({ ...prev, name: true }));
                setName(name);
              }}
              error={errors?.name}
            />
          </FuncRoute>
          <Route path={routes.wizard.matchmaker.work}>
            <MatchmakerWorkPage
              value={myWork}
              onBack={!user ? routes.wizard.matchmaker.name : undefined}
              onChange={(data) => {
                setTouched((prev) => ({ ...prev, ["conditionals.skills"]: true }));
                setMyWork(data);
              }}
              onContinue={selectMyWorkOnContinue}
              error={errors && errors["conditionals.skills"]}
            />
          </Route>
          <Route path={routes.wizard.matchmaker.abillities}>
            <MatchmakerAbilitiesPage
              abilities={abilities || {}}
              onBack={routes.wizard.matchmaker.work}
              onChange={(data) => {
                setTouched((prev) => ({ ...prev, ["conditionals.interests"]: true }));
                setAbilities(data);
              }}
              onContinue={selectAbilitiesOnContinue}
              error={errors && errors["conditionals.interests"]}
            />
          </Route>
          <Route path={routes.wizard.matchmaker.causes}>
            <CausesPage
              causes={causes || {}}
              title={t("wizard.pages.causes.titleVolunteer")}
              onBack={routes.wizard.matchmaker.abillities}
              onChange={(data) => {
                setTouched((prev) => ({ ...prev, ["conditionals.causes"]: true }));
                setCauses(data);
              }}
              onContinue={selectCausesOnContinue}
              error={errors && errors["conditionals.causes"]}
            />
          </Route>
          <Route path={routes.wizard.matchmaker.address}>
            <MatchmakerAddressPage
              value={location}
              onBack={routes.wizard.matchmaker.causes}
              onContinue={selectLocationOnContinue}
              onChange={(data) => {
                if (!touched["locations.city"] || !touched["locations.country"] || !touched["locations.state"])
                  setTouched((prev) => ({
                    ...prev,
                    ["locations.city"]: true,
                    ["locations.country"]: true,
                    ["locations.state"]: true,
                  }));
                setLocation(data);
              }}
              error={errors && (errors["locations.city"] || errors["locations.country"] || errors["locations.state"])}
            />
          </Route>
          <Route path={routes.wizard.matchmaker.image}>
            <MatchmakerPhotoPage
              file={profileImage}
              onBack={routes.wizard.matchmaker.address}
              onContinue={selectProfileImageOnContinue}
              onChange={setProfileImage}
              loading={loading === "loading"}
            />
          </Route>
        </Wizard>
      </Switch>
    </>
  );
};

export default MatchmakerPage;
