import React from "react";
import Icon from "./Icon";

interface IconOpportunityProps {
  icon?: "volunteer" | "material" | "collection";
}

const IconOpportunity = ({ icon }: IconOpportunityProps) => {
  return (
    <>
      <Icon icon={icon ?? "volunteer"} color={icon === "volunteer" ? "feedbackImportant" : "feedbackSupport"} />
    </>
  );
};

export default IconOpportunity;
