import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageEvents } from "../definitions/commonTypes";
import { Centered } from "wizard/components/common/styles";
import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { HourError } from "components/SubscribeHours/SubscribeHours";
import { ValidationErrorSet } from "core/api/definitions";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import { validHour } from "utils/helpers";
import InputLabeled from "wizard/components/Input/InputLabeled";
import PlacesAutoCompleteResolved, { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { TitleContainer } from "wizard/components/Layout/Content";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import FormWizard from "../common/FormWizard";
import { addMonths, startOfTomorrow } from "date-fns";
import { Col, Row } from "reactstrap";
import InputMask from "react-input-mask";

export interface AboutActionData {
  address?: LocationWithAddress;
  date?: {
    start?: Date;
    end?: Date;
  };
  time_start?: string;
  time_end?: string;
}

interface AboutActionPageProps extends PageEvents<AboutActionData> {
  data?: AboutActionData;
}

// TODO(Jota): How to choose a period? How to change the start period?
const AboutActionPage = ({ data, error, onChange, onContinue, onBack }: AboutActionPageProps): JSX.Element => {
  const { t } = useTranslation();
  const tomorrow = startOfTomorrow();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onCalendarChangeStart = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, date?: Date | null) => {
      // Nothing defined, don't do anything.
      if (!onChange || !date) return;
      onChange({ ...(data || {}), date: { start: date } });
    },
    [onChange]
  );

  const onCalendarChangeEnd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, date?: Date | null) => {
      // Nothing defined, don't do anything.
      if (!onChange || !date) return;
      onChange({ ...(data || {}), date: { ...(data?.date! || {}), end: date } });
    },
    [onChange, data]
  );

  const onContinueCallback = useCallback(() => {
    if (onContinue) onContinue(data || {});
  }, [data, onContinue]);

  const onPlacesAutoCompleteChangeCallback = useCallback(
    (value) => {
      if (onChange) onChange({ ...(data || {}), address: value });
    },
    [data, onChange]
  );

  const getDate = (date?: string | Date) => (date instanceof Date ? date : date ? new Date(date) : null);

  const { time_start, time_end } = data || { time_start: "", time_end: "" };
  const hasErrorHour = useMemo((): HourError => {
    const start = validHour(time_start || "");
    const end = validHour(time_end || "");
    if ((start === undefined && time_start?.length === 5) || (start === undefined && end !== undefined)) return "start";
    if (end === undefined && time_end?.length === 5) return "end";
  }, [time_start, time_end]);

  const errors = error as ValidationErrorSet | undefined;

  const hasError = !(data?.address?.city && data?.date?.start) || hasErrorHour;

  useEffect(() => {
    if (data?.time_start && !data?.time_end) {
      setIsDisabled(true);
    } else if (!data?.time_start && data?.time_end) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [data]);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <Container>
          <TitleContainer>
            <Helmet title={t("wizard.pages.aboutAction.title")} />
            <BaseTitle size="md">{t("wizard.pages.aboutAction.title")}</BaseTitle>
            <SupportText size="md">{t("wizard.pages.aboutAction.description")}</SupportText>
          </TitleContainer>
          <Div>
            <PlacesAutoCompleteResolved
              inputTag={InputLabeled}
              debounce={500}
              inputProps={{
                id: "actionAddress",
                type: "text",
                autoFocus: true,
                label: t("wizard.pages.aboutAction.form.address.label"),
                placeholder: t("wizard.pages.aboutAction.form.address.placeholder"),
                error:
                  errors?.["address.city"] ||
                  errors?.["address.coordinates"] ||
                  errors?.["address.country"] ||
                  errors?.["address.state"],
                required: true,
              }}
              value={data?.address}
              onChange={onPlacesAutoCompleteChangeCallback}
              onSelect={onPlacesAutoCompleteChangeCallback}
            />
            <Row>
              <Col md={6}>
                <InputLabeled
                  type="datepicker"
                  name="dateStart"
                  label={t("wizard.pages.aboutAction.form.date.label")}
                  placeholder={t("wizard.pages.aboutAction.form.date.placeholder")}
                  required
                  shouldCloseOnSelect
                  selected={getDate(data?.date?.start) as any}
                  minDate={tomorrow}
                  startDate={data?.date?.start ? new Date(data!.date!.start!) : tomorrow}
                  endDate={data?.date?.end ? new Date(data!.date!.end!) : null}
                  calendarSize="sm"
                  selectsStart
                  error={
                    errors?.["time_configurations.date_start"] ||
                    errors?.["action.not-allowed-date-start-be-smaller-than-today"]
                  }
                  onChange={onCalendarChangeStart}
                  customInput={<InputMask mask="99/99/9999" maskChar={null} />}
                />
              </Col>
              <Col md={6}>
                <InputLabeled
                  type="datepicker"
                  name="dateEnd"
                  placeholder={t("wizard.pages.aboutAction.form.date.placeholderEnd")}
                  shouldCloseOnSelect
                  required
                  selected={getDate(data?.date?.end) as any}
                  minDate={data?.date?.start ? new Date(data!.date!.start!) : tomorrow}
                  startDate={data?.date?.start ? new Date(data!.date!.start!) : tomorrow}
                  endDate={data?.date?.end ? new Date(data!.date!.end!) : null}
                  calendarSize="sm"
                  selectsEnd
                  error={
                    errors?.["time_configurations.date_end"] ||
                    errors?.["action.not-allowed-date-start-be-smaller-than-today"]
                  }
                  onChange={onCalendarChangeEnd}
                  customInput={<InputMask mask="99/99/9999" maskChar={null} />}
                />
              </Col>
            </Row>
            <InputLabeled
              id="timeStart"
              mask="99:99"
              type="tel"
              label={t("wizard.pages.aboutAction.form.time_start.label")}
              placeholder={t("wizard.pages.aboutAction.form.time_start.placeholder")}
              value={data?.time_start}
              error={
                errors?.time_start || hasErrorHour === "start"
                  ? t("wizard.pages.aboutAction.form.time_start.error")
                  : undefined
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                if (onChange) onChange({ ...(data || {}), time_start: value });
              }}
            />
            <InputLabeled
              id="timeEnd"
              mask="99:99"
              type="tel"
              label={t("wizard.pages.aboutAction.form.time_end.label")}
              placeholder={t("wizard.pages.aboutAction.form.time_end.placeholder")}
              value={data?.time_end}
              error={
                errors?.time_end || (hasErrorHour === "end" && time_end)
                  ? t("wizard.pages.aboutAction.form.time_end.error")
                  : undefined
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                if (onChange) onChange({ ...(data || {}), time_end: value });
              }}
            />
          </Div>
        </Container>
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={
          !!(
            errors?.["address.city"] ||
            errors?.["address.coordinates"] ||
            errors?.["address.country"] ||
            errors?.["address.state"] ||
            errors?.["time_configurations.date_start"] ||
            errors?.["time_configurations.date_end"] ||
            errors?.["time_configurations.time_start"] ||
            errors?.["time_configurations.time_end"] ||
            errors?.["action.not-allowed-date-start-be-smaller-than-today"] ||
            hasError ||
            isDisabled
          )
        }
      />
    </FormWizard>
  );
};

export default AboutActionPage;

const Container = styled(Centered)`
  max-width: 400px;
  margin: auto;

  & > span {
    margin-bottom: 15px;
  }
`;

const Div = styled.div`
  width: 100%;

  input {
    padding-right: 30px;
  }
`;
