import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { PhotoFile } from "wizard/pages/definitions/commonTypes";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import { ListByActionResponseParams, ListByActionResponse, ListByActionRequest } from "core/api/subscribe/listByAction";
import decoreThunk from "core/decorators/decorate";
import { LoadingStatus } from "core/api/definitions";
import { EditResponseParams } from "core/api/subscribe/edit";

interface IStateEntities {
  [k: string]: Partial<
    EditResponseParams & {
      loading: LoadingStatus | "partial";
    }
  >;
}

interface IState {
  coverImage?: PhotoFile;
  subscribe: {
    loading: LoadingStatus;
    response?: ListByActionResponseParams;
    projectSlug?: string;
    actionSlug?: string;
  };
  entities: IStateEntities;
}

// create requests the informations needed for creating a "subscribe".
export const create = asyncThunk("wizard/create/subscribe/create", api.subscribe.create, {
  condition: [
    (_, state) =>
      !state.supportData.functions ||
      state.supportData.functions.length === 0 ||
      !state.supportData.interests ||
      state.supportData.interests.length === 0,
  ],
});

export const edit = asyncThunk("wizard/create/subscribe/edit", api.subscribe.edit, {
  condition: [
    (action, state) =>
      !state.wizard.create.subscribe.entities[action.slug] ||
      !["partial", "ok"].some((d) => state.wizard.create.subscribe.entities[action.slug].loading === d) ||
      !state.wizard.create.subscribe.entities[action.slug]?.functions_data?.length, //TODO(Jeconias): This is necessary just get functions_data, but functions_data should be in unique local, no in each subscribe
  ],
});

export const assign = asyncThunk("wizard/create/subscribe/assign", api.subscribe.assign);

export const storeNewSubscribe = asyncThunk("wizard/create/subscribe/storeNew", api.subscribe.storeNewSubscribe);

export const update = asyncThunk("wizard/create/subscribe/update", api.subscribe.update);

export const listByAction = asyncThunk<ListByActionResponse, ListByActionRequest & { force?: boolean }>(
  "wizard/create/subscribe/listByAction",
  api.subscribe.listByAction,
  {
    condition: [
      (props, state) =>
        props.force ||
        (state.wizard.create.subscribe.subscribe.loading !== "ok" &&
          state.wizard.create.subscribe.subscribe.loading !== "loading") ||
        state.wizard.create.subscribe.subscribe?.actionSlug !== props.action ||
        state.wizard.create.subscribe.subscribe?.projectSlug !== props.project,
    ],
  }
);

const subscribeSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/create/subscribe",
  initialState: {
    subscribe: {
      loading: "idle",
    },
    entities: {},
  },
  reducers: {
    updateCoverImage(state, action: PayloadAction<PhotoFile>) {
      state.coverImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, listByAction, [
      {
        pending: (state, action) => {
          state.subscribe.loading = "loading";
          state.subscribe.actionSlug = action.meta?.arg?.action;
          state.subscribe.projectSlug = action.meta?.arg?.project;
        },
        fulfilled: (state, action) => {
          state.subscribe.loading = "ok";
          const listResponse = action.payload.params as ListByActionResponseParams;
          state.subscribe.response = listResponse;

          // Also update entities.
          const newEntities: IStateEntities = {};
          listResponse.subscribe_data.forEach((subscribeData) => {
            if (state.entities[subscribeData.slug]) {
              if (state.entities[subscribeData.slug].loading === "ok") {
                newEntities[subscribeData.slug] = { ...state.entities[subscribeData.slug], ...subscribeData };
                return;
              }
            }
            newEntities[subscribeData.slug] = { ...subscribeData, loading: "partial" };
          });
          state.entities = { ...state.entities, ...newEntities };
        },
        rejected: (state, _) => {
          state.subscribe.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, edit, [
      {
        pending: (state, action) => {
          state.subscribe.actionSlug = action.meta?.arg?.action;
          if (state.entities[action.meta?.arg?.slug]) {
            state.entities[action.meta?.arg?.slug].loading = "loading";
          } else {
            state.entities[action.meta?.arg?.slug] = { loading: "loading" };
          }
        },
        fulfilled: (state, action) => {
          state.entities[action.meta?.arg?.slug] = { ...action.payload.params, loading: "ok" };
        },
        rejected: (state, action) => {
          console.log("edit.rejected", action);
          if (state.entities[action.meta?.arg?.slug]) state.entities[action.meta?.arg?.slug].loading = "error";
        },
      },
    ]);
    builder.addCase(storeNewSubscribe.fulfilled.toString(), (state) => {
      state.coverImage = undefined;
    });
    builder.addCase(update.fulfilled.toString(), (state) => {
      state.coverImage = undefined;
    });
  },
});

export const { updateCoverImage } = subscribeSlice.actions;

export default subscribeSlice;
