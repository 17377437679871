import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { updateSocialNetwork } from "core/wizard/create/project";
import { useReduxSelector } from "hooks/useReduxSelector";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { ContainerSection } from "wizard/components/common/styles";
import InputLabeled from "wizard/components/Input/InputLabeled";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { ContentContainer, TitleContainer } from "wizard/components/Layout/Content";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import FormWizard from "./common/FormWizard";
import { checkUrl, checkFacebook, checkYoutube, checkInstagram } from "utils/helpers";

export interface SocialNetworkData {
  website?: string;
  instagram?: string;
  facebook?: string;
  youtube?: string;
}

interface SocialNetworksPageProps {
  onContinue: (param: SocialNetworkData) => void;
  onChange?: () => void;
  onChangeValues?: (values: SocialNetworkData) => void;
  skipTo: string;
  skipToLabel?: string;
  continueDisabled?: boolean;
  continueLabel?: string;
}

const HTTPS = "https://";

const protocolRemove = (url?: string) => url?.replace(HTTPS, "") || "";

const SocialNetworksPage = ({
  onContinue,
  onChange,
  onChangeValues,
  skipTo,
  skipToLabel,
  continueDisabled,
  continueLabel,
}: SocialNetworksPageProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const socialNetworkData = useReduxSelector<SocialNetworkData>((state) => {
    const project = state.wizard.create.project;
    const socialNetWork: SocialNetworkData = {
      website: project.socialNetWork?.website || project.project.response?.social_network?.website || "",
      facebook: project.socialNetWork?.facebook || project.project.response?.social_network?.facebook || "",
      instagram: project.socialNetWork?.instagram || project.project.response?.social_network?.instagram || "",
      youtube: project.socialNetWork?.youtube || project.project.response?.social_network?.youtube || "",
    };
    return socialNetWork;
  });

  const [website, setWebsite] = useState<string | undefined>(socialNetworkData?.website);
  const [instagram, setInstagram] = useState<string | undefined>(protocolRemove(socialNetworkData?.instagram));
  const [facebook, setFacebook] = useState<string | undefined>(protocolRemove(socialNetworkData?.facebook));
  const [youtube, setYoutube] = useState<string | undefined>(protocolRemove(socialNetworkData?.youtube));
  const [hasErrors, setHasErrors] = useState<SocialNetworkData>({});

  useEffect(() => {
    if (onChangeValues) {
      const hasFacebookDomain = /(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\//.test(facebook || "");
      const hasProtocolWebsite = /(http(s?)\:\/\/)/.test(website || "");

      const data = {
        website: website ? `${website.replace(/^(?:https?:\/\/)?/i, "")}` : undefined,
        instagram: instagram ? `${instagram}` : undefined,
        facebook: facebook
          ? `${hasFacebookDomain ? "" : "facebook.com/"}${facebook.replace(/^(?:https?:\/\/)?/i, "")}`
          : undefined,
        youtube: youtube ? `${youtube.replace(/^(?:https?:\/\/)?/i, "")}` : undefined,
      };

      onChangeValues(data);
    }
  }, [website, instagram, facebook, youtube]);

  const onChangeWebsiteCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!hasErrors?.website && checkUrl(e?.target?.value) === false)
        setHasErrors((prev) => ({ ...prev, website: t("wizard.pages.socialNetwork.error") }));
      if (hasErrors?.website && (checkUrl(e?.target?.value) === true || !e?.target?.value.length))
        setHasErrors((prev) => ({ ...prev, website: "" }));
      setWebsite(e?.target?.value);
      if (onChange) onChange();
    },
    [hasErrors, onChange, t]
  );

  const onChangeInstagramCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value?.replace("@", "");
      const match = checkInstagram(value);

      /*  const instagramInvalid = match === null || match?.length < 2;
      if (!hasErrors?.instagram && instagramInvalid)
        setHasErrors((prev) => ({ ...prev, instagram: t("wizard.pages.socialNetwork.error") }));
      if (hasErrors?.instagram && (!instagramInvalid || !value.length))
        setHasErrors((prev) => ({ ...prev, instagram: "" })); */

      setInstagram(e?.target?.value);
      if (onChange) onChange();
    },
    [hasErrors, onChange, t]
  );

  const onChangeFacebookCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;
      const facebookInvalid = checkFacebook(value);
      if (!hasErrors?.facebook && !facebookInvalid)
        setHasErrors((prev) => ({ ...prev, facebook: t("wizard.pages.socialNetwork.error") }));
      if (hasErrors?.facebook && (facebookInvalid || !value.length))
        setHasErrors((prev) => ({ ...prev, facebook: "" }));
      setFacebook(value);
      if (onChange) onChange();
    },
    [hasErrors, onChange, t]
  );

  const onChangeYoutubeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;
      const youtubeInvalid = checkYoutube(value);
      if (!hasErrors?.youtube && !youtubeInvalid)
        setHasErrors((prev) => ({ ...prev, youtube: t("wizard.pages.socialNetwork.error") }));
      if (hasErrors?.youtube && (youtubeInvalid || !value.length)) setHasErrors((prev) => ({ ...prev, youtube: "" }));
      setYoutube(value);
      if (onChange) onChange();
    },
    [hasErrors, onChange, t]
  );

  const handleSubmit = useCallback(
    (e?: React.SyntheticEvent) => {
      if (e) e.preventDefault();

      const hasFacebookDomain = /(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\//.test(facebook || "");
      const hasProtocolWebsite = /(http(s?)\:\/\/)/.test(website || "");

      const data = {
        website: website ? `${website.replace(/^(?:https?:\/\/)?/i, "")}` : undefined,
        instagram: instagram ? `${instagram.replace(/^(?:https?:\/\/)?/i, "")}` : undefined,
        facebook: facebook
          ? `${hasFacebookDomain ? "" : "facebook.com/"}${facebook.replace(/^(?:https?:\/\/)?/i, "")}`
          : undefined,
        youtube: youtube ? `${youtube.replace(/^(?:https?:\/\/)?/i, "")}` : undefined,
      };

      dispatch(updateSocialNetwork(data));
      if (onContinue) onContinue(data);
    },
    [dispatch, onContinue, website, instagram, facebook, youtube]
  );

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.socialNetwork.title")} />
          <BaseTitle size="md">{t("wizard.pages.socialNetwork.title")}</BaseTitle>
          <SupportText size="md" textAlign="center">
            {t("wizard.pages.socialNetwork.subtitle")}
          </SupportText>
        </TitleContainer>
        <CustomContainerSection className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 justify-content-center">
          <CustomFormWizard id="formSocialNetwork" onContinue={handleSubmit}>
            <InputLabeled
              icon={["far", "globe"]}
              autoFocus
              type="text"
              name="website"
              value={website}
              wrapperClassName="customInputLabeled"
              label={t("wizard.pages.socialNetwork.inputs.website.label")}
              placeholder={t("wizard.pages.socialNetwork.inputs.website.placeholder")}
              onChange={onChangeWebsiteCallback}
              error={hasErrors?.website}
            />
            <InputLabeled
              icon={["fab", "instagram"]}
              type="text"
              name="instagram"
              value={instagram}
              wrapperClassName="customInputLabeled"
              label={t("wizard.pages.socialNetwork.inputs.instagram.label")}
              placeholder={t("wizard.pages.socialNetwork.inputs.instagram.placeholder")}
              onChange={onChangeInstagramCallback}
              error={hasErrors?.instagram}
            />
            <InputLabeled
              icon={["fab", "facebook-f"]}
              type="text"
              name="facebook"
              value={facebook}
              wrapperClassName="customInputLabeled"
              label={t("wizard.pages.socialNetwork.inputs.facebook.label")}
              placeholder={t("wizard.pages.socialNetwork.inputs.facebook.placeholder")}
              onChange={onChangeFacebookCallback}
              error={hasErrors?.facebook}
            />
            <InputLabeled
              icon={["fab", "youtube"]}
              type="text"
              name="youtube"
              value={youtube}
              wrapperClassName="customInputLabeled"
              label={t("wizard.pages.socialNetwork.inputs.youtube.label")}
              placeholder={t("wizard.pages.socialNetwork.inputs.youtube.placeholder")}
              onChange={onChangeYoutubeCallback}
              error={hasErrors?.youtube}
            />
          </CustomFormWizard>
        </CustomContainerSection>
      </WizardContainer>
      <CustomActionsFooter
        form="formSocialNetwork"
        backLabel={skipToLabel || t("wizard.pages.socialNetwork.buttons.skipStep")}
        backIcon={false}
        onBack={skipTo}
        continueLabel={continueLabel || t("wizard.pages.socialNetwork.buttons.save")}
        continueDisabled={
          !!(hasErrors?.facebook || hasErrors?.instagram || hasErrors?.website || hasErrors?.youtube) ||
          continueDisabled
        }
      />
    </Fragment>
  );
};

export default SocialNetworksPage;

const CustomContainerSection = styled(ContainerSection)`
  height: auto;
`;

const CustomFormWizard = styled(FormWizard)`
  align-items: center;

  .customInputLabeled {
    width: 100%;
  }
`;

const CustomActionsFooter = styled(ActionsFooter)`
  ${ContentContainer} {
    flex-direction: column-reverse;
    align-items: center;

    button {
      margin-bottom: 70px;
    }
  }
`;
