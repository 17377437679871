import { ValidationError } from "core/api/definitions";
import ConfirmAge from "panel/components/ConfirmAge/ConfirmAge";
import { ConfirmMain } from "panel/components/ConfirmBase/ConfirBase";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { PageEvents } from "wizard/pages/definitions/commonTypes";

interface ConfirmAgePageProps extends PageEvents<boolean> {}

/** @deprecated */
const ConfirmAgePage = ({ onContinue, onBack, error }: ConfirmAgePageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={t("wizard.pages.confirmAge.title")} />
      <WizardContainer>
        <CustomConfirmMain>
          <ConfirmAge
            onConfirm={() => {
              if (onContinue) onContinue(true);
            }}
            onReject={() => {
              if (onBack && typeof onBack === "function") onBack();
            }}
            error={error as ValidationError}
          />
        </CustomConfirmMain>
      </WizardContainer>
    </Fragment>
  );
};

export default ConfirmAgePage;

const CustomConfirmMain = styled(ConfirmMain)`
  margin: 0 auto;
`;
