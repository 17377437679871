import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Nav as BSNav, Navbar as BSNavbar, NavbarBrand, NavItem as BSNavItem, NavLink as BSNavLink } from "reactstrap";
import { Link, LinkProps, matchPath, useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import { transition } from "utils/animation";
import Button from "../Button/Button";
import Container from "../Grid/Container";
import useTranslationV3 from "hooks/useTranslationV3";
import ModalsGlobal from "components/ModalsGlobal";
import mqV3 from "utils/mediaQueriesV3";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";
import MenuMobile from "./MenuMobile";
import { rem } from "polished";
import { Z_INDEX } from "utils/constants";
import UserMenuDropdown from "../UserMenu/UserMenu";
import { useUser } from "hooks/useUser";
import Notifications from "../Notifications/Notifications";
import Icon from "../Icons/Icon";
import logoSmall from "assets/imgs/logo_small.png";
interface NavItemProps extends Partial<Pick<LinkProps, "to">> {
  active?: boolean;
}

const NavItemLinks: React.FC<NavItemProps> = ({ to, children, active }) => {
  return (
    <NavItemStyled active={active}>
      <NavLinkStyled tag={Link} to={to}>
        {children}
      </NavLinkStyled>
    </NavItemStyled>
  );
};

const NavItemStyled = styled(BSNavItem)<{ active?: boolean }>`
  margin: 0 ${({ theme }) => theme.v3.spacing.xs};
  height: 100%;
  display: flex;
  align-items: center;

  :last-child {
    margin-right: 0;
  }
  :first-child {
    margin-left: 0;
  }

  ${({ active }) =>
    active &&
    css`
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: ${({ theme }) => theme.v3.colors.primary};
      }
    `};
`;

export const NavLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 500;
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  padding: ${({ theme }) => theme.v3.spacing.xs};
  transition: ${transition.fast("color")};

  &:hover {
    color: ${({ theme }) => theme.v3.colors.primary};
  }
`;

interface HeaderProps {
  className?: string;
}

const Header = ({ ...props }: HeaderProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const [show, setShow] = useState(false);
  const previousScrollTop = useRef(0);
  const { user } = useUser();

  const { t } = useTranslationV3();

  const { pathname } = useLocation();
  const history = useHistory();
  const { isXXS, isXS, isMD } = useMediaQueriesV3();

  const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);

  const { origin } = useReduxSelector((state) => ({
    origin: state.pagesNew.origin.params,
  }));

  const { title, logo, donate, logoIcon } = origin as Origin;

  const hasUser = !!user;

  useEffect(() => {
    const handleScroll = (e: Event) => {
      const currentScrollTop = document.documentElement.scrollTop;

      if (currentScrollTop >= 430) {
        setIsSticky(true);
      } else if (currentScrollTop < 340) {
        setIsSticky(false);
      }

      setShow(currentScrollTop < previousScrollTop.current && currentScrollTop >= 430);

      previousScrollTop.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  const onLogoClick = useCallback(
    (e: React.MouseEvent) => {
      if (isSticky) {
        e.preventDefault();
        e.stopPropagation();
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [isSticky]
  );

  const handleSignIn = (event: React.MouseEvent) => {
    event.preventDefault();
    history.push({ search: "?open=signIn" });
  };

  const handleSignUp = (event: React.MouseEvent) => {
    event.preventDefault();
    history.push({ search: "?open=signUp" });
  };

  const matchRoute = useCallback(
    (path: string) => {
      const result = matchPath(path, pathname);
      if (result) return result.isExact;
      return false;
    },
    [pathname]
  );

  return (
    <>
      <ModalsGlobal />
      <NavbarStyled isSticky={isSticky} isVisible={!isSticky || show} {...props}>
        <ContainerStyled>
          {!isMD && (
            <ButtonMobileMenuOpen onClick={() => setMenuMobileIsOpen((prev) => !prev)}>
              <span>{t("plain:Menu")}</span>
            </ButtonMobileMenuOpen>
          )}
          <NavbarBrandStyled tag={Link} to={routes.home} onClick={onLogoClick}>
            <NavbarBrandLogo src={logo} title={title} alt={title} isSticky={isSticky} height="48" width="auto" />
          </NavbarBrandStyled>
          <NavbarBrandStyled tag={Link} to={routes.home} onClick={onLogoClick}>
            <NavbarBrandLogoTwo src={logoIcon} title={title} alt={title} isSticky={isSticky} height="48" width="auto" />
          </NavbarBrandStyled>
          {isMD && (
            <NavLinks>
              <NavItemLinks to={routes.listActions} active={matchRoute(routes.listActions)}>
                {t("pages.actions.title")}
              </NavItemLinks>
              <NavItemLinks to={routes.opportunities.toString()} active={matchRoute(routes.opportunities.toString())}>
                {t("pages.opportunities.title")}
              </NavItemLinks>
              <NavItemLinks to={routes.listProject} active={matchRoute(routes.listProject)}>
                {t("pages.projects.title")}
              </NavItemLinks>
              {(window.location.hostname == "transformabrasil.com.br" ||
                window.location.hostname == "brasil.hom.transform.click" ||
                window.location.hostname == "brasil.local.transform.click" ||
                window.location.hostname == "localhost") && (
                <>
                  <NavLink
                    href="https://eventos.transformabrasil.com.br/"
                    target="_blank" /* active={matchRoute(routes.listProject)} */
                  >
                    {t("plain:Eventos")}
                  </NavLink>
                  <NavLink
                    href="https://ebook.transformabrasil.com.br/"
                    target="_blank" /* active={matchRoute(routes.listProject)} */
                  >
                    {t("plain:E-books")}
                  </NavLink>
                </>
              )}
              {(window.location.hostname == "transformabrasil.com.br" ||
                window.location.hostname == "brasil.hom.transform.click" ||
                window.location.hostname == "brasil.local.transform.click" ||
                window.location.hostname == "localhost") && (
                <NavItemLinks to={routes.blog.self.toString()} active={matchRoute(routes.blog.self)}>
                  {t("plain:Blog")}
                </NavItemLinks>
              )}
              {/*
              <NavItemLinks to="#" active={false}>
                {t("pages.blog.title")}
              </NavItemLinks>
               */}
            </NavLinks>
          )}

          {!hasUser && (
            <NavSign>
              <NavSignItem>
                <ButtonLogin className={"btn"} onClick={handleSignIn}>
                  {t("actions.login.label")}
                </ButtonLogin>
              </NavSignItem>
              <NavSignItem>
                <ButtonSignUp onClick={handleSignUp} size={isXS ? "sm" : "md"}>
                  {t("actions.signUp.label")}
                </ButtonSignUp>
              </NavSignItem>
            </NavSign>
          )}
          {hasUser && (
            <NavUser>
              <Button tag={Link} color="outline" size="sm" to={routes.wizard.projectList.toString()}>
                {isXS || isXXS ? t("actions.registerProject.labelSmall") : t("actions.registerProject.label")}
              </Button>
              <Notifications />
              <UserMenuDropdown />
            </NavUser>
          )}
          {donate && !isSticky && !show && (
            <LinkDonate href={donate.link} target="_blank" rel="noopener noreferrer">
              {donate.label} <Icon icon="heart" size="sm" color="feedbackError" />
            </LinkDonate>
          )}
        </ContainerStyled>
        {!isMD && <MenuMobile isOpen={menuMobileIsOpen} onClose={() => setMenuMobileIsOpen((prev) => !prev)} />}
      </NavbarStyled>
      {isSticky && <StickyHelper />}
    </>
  );
};

export default Header;

const StickyHelper = styled.div`
  height: 80px;
`;

const NavbarStyled = styled(({ isSticky, isVisible, ...props }) => <BSNavbar {...props} />)(
  ({ theme, isSticky, isVisible }) => css`
    background: #fff;
    height: 80px;
    border-bottom: 1px solid ${theme.v3.colors.neutralLightest};
    z-index: ${Z_INDEX.HEADER};
    transition: ${transition.normal("all")};

    ${mqV3.sm} {
      padding-left: ${theme.v3.spacing.sm};
      padding-right: ${theme.v3.spacing.sm};
    }

    ${isSticky &&
    css`
      position: fixed;
      top: -90px;
      left: 0;
      height: 80px;
      width: 100%;
      box-shadow: 0 0 5px 0 rgba(45, 44, 54, 0.3);
    `}

    ${isSticky &&
    isVisible &&
    css`
      top: 0;
    `}
  `
);

const ContainerStyled = styled(Container)`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${mqV3.smDown} {
    max-width: none;
  }
`;
export const NavLink = styled.a`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 500;
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  padding: ${({ theme }) => theme.v3.spacing.sm};
  transition: ${transition.fast("color")};

  &:hover {
    color: ${({ theme }) => theme.v3.colors.primary};
  }
`;

const NavbarBrandStyled = styled(NavbarBrand)`
  ${mqV3.smDown} {
    position: absolute;
    left: 14%;
  }
`;

const NavbarBrandLogoTwo = styled.img<{ isSticky: boolean }>`
  height: 38px;

  ${mqV3.xsDown} {
    height: 31px;
  }
  display: none;
  @media (max-width: 450px) {
    display: block;
  }
`;

const NavbarBrandLogo = styled.img<{ isSticky: boolean }>`
  height: 48px;

  ${mqV3.xsDown} {
    height: 31px;
  }
  display: block;
  @media (max-width: 450px) {
    display: none;
  }
`;

const NavLinks = styled(BSNav)`
  align-items: center;
  height: 100%;
`;

const NavSign = styled(BSNav)`
  align-items: center;
  height: 100%;
  margin: 0 -10px;

  ${mqV3.xsDown} {
    margin: 0 -3px;
  }
`;

const NavUser = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 -10px;

  > * {
    margin: 0 10px;
    &:nth-child(1) {
      :hover {
        background: ${({ theme }) => theme.v3.colors.primary}!important;
        color: ${({ theme }) => theme.v3.colors.neutralWhite};
      }
    }
  }
`;

const NavSignItem = styled(BSNavItem)`
  margin: 0 10px;

  ${mqV3.xsDown} {
    margin: 0 3px;
  }
`;

const ButtonLogin = styled((props) => <Button color="outline" size="md" {...props} />)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.secondary};
  border: 1px solid transparent;
  border-radius: ${rem(6)};
  transition: ${transition.fast("color", "border")};

  &:focus {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  &:hover {
    border-color: transparent;
    background: transparent;
    color: ${({ theme }) => theme.v3.colors.primaryLight};
  }

  &:active {
    color: ${({ theme }) => theme.v3.colors.primary};
  }
`;

const ButtonSignUp = styled(Button)(
  ({ theme, isSticky, isVisible }) => css`
    background: ${({ theme }) => theme.v3.colors.primary};
    color: ${({ theme }) => theme.v3.colors.secondary};
    &:hover {
      border-color: transparent;
      background: ${({ theme }) => theme.v3.colors.primaryLight};
      color: ${({ theme }) => theme.v3.colors.secondary};
    }
  `
);

const ButtonMobileMenuOpen = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  overflow: hidden;
  color: transparent;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  span {
    display: flex;
    background: ${({ theme }) => theme.v3.colors.neutralBase};
    width: 16px;
    height: 2px;
    border-radius: 2px;
    position: relative;
    text-indent: 9999em;

    &:before,
    &:after {
      content: "";
      display: flex;
      position: absolute;
      background: ${({ theme }) => theme.v3.colors.neutralBase};
      width: 16px;
      height: 2px;
      border-radius: 2px;
    }

    &:before {
      top: -7px;
    }

    &:after {
      bottom: -7px;
    }
  }
`;

const LinkDonate = styled.a(
  ({ theme }) => css`
    position: absolute;
    font-size: ${theme.v3.fontSize.sm};
    line-height: ${rem(20)};
    color: ${theme.v3.colors.feedbackError};
    padding: ${theme.v3.spacing.xs} ${theme.v3.spacing.sm};
    right: 15px;
    background: ${theme.v3.colors.neutralWhite};
    border: 1px solid ${theme.v3.colors.neutralLightest};
    box-sizing: border-box;
    box-shadow: ${theme.v3.boxShadow.l1};
    border-radius: 0px 0px 8px 8px;
    top: 100%;
    transition: ${transition.fast("background", "color")};

    ${Icon} {
      margin-left: ${theme.v3.spacing.xxs};
      animation: 1.8s infinite beatHeart;
    }

    &:hover {
      color: ${theme.v3.colors.neutralWhite};
      background: ${theme.v3.colors.feedbackError};

      ${Icon} {
        animation: 0.8s infinite beatHeart;
        .svg-fill {
          fill: ${theme.v3.colors.neutralWhite};
        }
      }
    }
  `
);
