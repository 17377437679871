import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";
import { math, rem, transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Size } from "utils/types";
import { i18n } from "i18next";
import { FormGroup as FormGroupRS, Input as InputRS, Label as LabelRS } from "reactstrap";
import InputMask from "react-input-mask";

import Icon, { IconType } from "components/Icon/Icon";
import { datePickerMixin } from "components/DatePicker/DatePicker";
import { BaseInputStyle } from "v3/components/Form/Input/BaseInput";
import { BaseLabelStyle } from "v3/components/Form/Label";

interface GetCustomPropsTypes extends Pick<ReactDatePickerProps, "onCalendarOpen" | "onCalendarClose"> {
  inputType?: InputType;
  mask?: string | RegExp;
  placeholder?: string;
  showPassword?: boolean;
  dateFormat?: string | string[];
  state?: StateType;
  i18n?: i18n;
  defaultValue?: string | number | string[];
}

const DISTANCES = {
  label: {
    top: "0",
    right: "0",
    bottom: "0",
    left: "8px",
  },
};

const LINE_HEIGHT = {
  default: 22,
  lg: 50,
  md: 35,
};

export const InputWrapperChildren = styled.div`
  background: #fff;
  border: 1px solid #bcc4de;
  border-radius: ${rem(4)};
`;

type InputSize = Exclude<Size, "xs" | "sm"> | "default";

interface InputWrapperProps {
  name?: string;
  size?: InputSize;
  label?: string;
  error?: string;
  className?: string;
  centered?: boolean;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  size = "default",
  name,
  label,
  error,
  className,
  children,
  centered,
}) => {
  return (
    <FormGroup size={size} className={className}>
      <Label for={name}>{label}</Label>
      <InputWrapperStyled>{children}</InputWrapperStyled>
      {error !== undefined ? <Error centered={centered}>{error}</Error> : null}
    </FormGroup>
  );
};

export { InputWrapper };

const Error = styled.div<{ centered?: boolean }>`
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  color: ${(props) => props.theme.colors.danger};
  margin: 10px 0 0 0;

  ${(props) =>
    props.centered &&
    css`
      margin: 10px 0 0;
      text-align: center;
    `}
`;

const InputWrapperStyled = styled.div`
  position: relative;
`;

export const FormGroup = styled(FormGroupRS)`
  padding-top: ${({ theme }) => theme.v3.spacing.xs};
  position: relative;

  ${({ tag }) =>
    tag === "fieldset" &&
    css`
      margin: 0 0 40px 0;

      &:not(:last-child) {
        border-bottom: 1px solid ${transparentize(0.6, "#c9cedd")};
      }
    `};

  > [class^="col-"] {
    > label {
      left: ${math(DISTANCES.label.left.concat("+", "15px"))};
    }
  }

  ${({ size }) =>
    size === "md" &&
    css`
      ${CounterStyled} {
        bottom: ${rem(LINE_HEIGHT.md / 2 + 20 - 7)};
      }
    `};

  ${({ size }) =>
    size === "lg" &&
    css`
      ${CounterStyled} {
        bottom: ${rem(LINE_HEIGHT.lg / 2 + 20 - 7)};
      }
    `};

  .react-datepicker-popper {
    z-index: 20;

    .react-datepicker {
      font-family: inherit;
      border-color: #e2e6f4;
      border-radius: 10px;

      &__month {
        margin: 0;
      }

      &__day {
        &,
        &-name {
          margin: 0;
          width: ${(props) => (props.size === "lg" ? 80 : 60)}px;
        }

        &-name {
          color: #8798ad;
          text-transform: uppercase;
          font-size: 12px;
        }

        line-height: ${(props) => (props.size === "lg" ? 78 : 58)}px;
        font-size: ${(props) => (props.size === "lg" ? 20 : 14)}px;
        color: #18235e;
        border: 1px solid #e2e6f4;

        &--outside-month {
          color: #8798ad;
          background-color: #f9fafc;
          font-weight: 300;
        }

        &:hover {
          border-radius: 0;
          border: 1px solid ${({ theme }) => theme.colors.secondary};
          background: initial;
        }

        &--in-selecting-range {
          &,
          &:hover {
            border-radius: 0;
            font-weight: 500;
            background-color: ${({ theme }) => transparentize(0.5, theme.colors.secondary)};
            border: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.secondary)};
          }
        }

        &--selected,
        &--keyboard-selected,
        &--in-range {
          &,
          &:hover {
            color: #ffffff;
            border-radius: 0;
            font-weight: 500;
            background-color: ${({ theme }) => theme.colors.secondary};
            border: 1px solid ${({ theme }) => theme.colors.secondary};
          }
        }
        &:focus {
          outline: 0;
        }
      }

      &__week:last-child {
        > :first-child {
          border-radius: 0px 0px 0px 10px;
        }

        > :last-child {
          border-radius: 0px 0px 10px 0px;
        }
      }

      &__header {
        border-radius: 10px 10px 0 0;
        border: none;
        background-color: #f9fafc;
      }

      &__current-month {
        font-size: ${(props) => (props.size === "lg" ? 25 : 20)}px;
        font-weight: normal;
        color: #18235e;
        line-height: ${(props) => (props.size === "lg" ? 32 : 28)}px;
        margin: ${(props) => (props.size === "lg" ? 12 : 10)}px 0 ${(props) => (props.size === "lg" ? 20 : 16)}px;
      }

      &__navigation {
        top: 30px;
      }

      &__day-names {
        border-top: 1px solid #e2e6f4;
      }

      &__day-name {
        padding-top: ${(props) => (props.size === "lg" ? 30 : 26)}px;
        padding-bottom: ${(props) => (props.size === "lg" ? 20 : 16)}px;
        line-height: ${(props) => (props.size === "lg" ? 14 : 10)}px;
      }
    }

    ${datePickerMixin}
  }

  .react-datepicker-wrapper {
    display: block;
  }
`;

const CounterStyled = styled.div<{ warning?: boolean }>`
  position: absolute;
  right: 20px;
  bottom: ${rem(LINE_HEIGHT.default / 2 + 20 - 7)};
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  letter-spacing: ${rem(1.13)};
  color: ${({ theme }) => theme.colors.support_text};

  ${({ warning }) =>
    warning &&
    css`
      color: ${(props) => props.theme.colors.danger};
    `};
`;

const IconWrapperStyled = styled.div<{ isPassword?: boolean; showPassword?: boolean }>`
  position: absolute;
  right: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  ${({ isPassword, showPassword, theme }) =>
    isPassword &&
    css`
      display: flex;
      height: 20px;
      overflow: hidden;

      :before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: 2px;
        transform: rotate(-45deg);
        margin: auto;
        background-color: ${theme.colors.support_text};
        transition: max-width 0.2s;
        ${showPassword &&
        css`
          max-width: 0;
        `};
      }
    `}

  path,
  svg {
    fill: ${({ theme }) => theme.colors.support_text};
    width: 100%;
  }
`;

const extractIcon = ({ type, icon }: { type: InputType; icon?: IconType }): IconType | null => {
  if (type === "select") {
    return "chevronDown";
  }

  if (type === "datepicker") {
    return "calendar";
  }

  if (icon) {
    return icon;
  }

  return null;
};

const IconWrapper = ({
  type,
  icon,
  hideIcon,
  onClickIcon,
}: {
  type: InputType;
  icon?: IconType;
  hideIcon?: boolean;
  onClickIcon?(getState?: boolean): ProxyOnClickIconType;
}) => {
  const iconType = extractIcon({ type, icon });

  if (!iconType) return null;

  return (
    <IconWrapperStyled
      isPassword={type === "password"}
      showPassword={onClickIcon && onClickIcon(true) ? true : false}
      onClick={() => {
        if (onClickIcon) onClickIcon();
      }}
    >
      <Icon style={{ display: hideIcon ? "none" : "" }} type={iconType} />
    </IconWrapperStyled>
  );
};

export const Counter = ({
  size,
  totalLength,
  maxLength,
  hideIcon,
}: {
  size?: number;
  totalLength?: number;
  maxLength?: number;
  hideIcon?: boolean;
}): JSX.Element | null => {
  if (maxLength && size !== undefined && totalLength !== undefined) {
    const warning = totalLength >= Math.floor(maxLength - maxLength * 0.05);
    return (
      <CounterStyled style={{ display: hideIcon ? "none" : "block" }} warning={warning}>
        {totalLength} / {maxLength}
      </CounterStyled>
    );
  }
  return null;
};

const Label = styled(LabelRS)`
  padding: 0 10px;
  top: ${DISTANCES.label.top};
  left: ${DISTANCES.label.left};
  position: absolute;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #fff;
    display: block;
    top: calc(50% + 1px);
    z-index: -1;
  }

  ${BaseLabelStyle}
`;

const InputStyled = styled(({ ...props }) => {
  delete props.size;
  delete props.totalLength;
  return <InputRS {...props} />;
})`
  ${BaseInputStyle}
`;

const getCustomProps = ({
  inputType,
  mask,
  placeholder,
  showPassword,
  state,
  i18n,
  defaultValue,
  dateFormat,
}: GetCustomPropsTypes) => {
  const customProps: ReactDatePickerProps | any = {};

  if (inputType && ["text", "tel"].indexOf(inputType) >= 0 && mask) {
    customProps.tag = InputMask;
    customProps.mask = mask;
  } else if (inputType === "datepicker") {
    customProps.showPopperArrow = false;
    customProps.tag = DatePicker;
    customProps.dateFormat = dateFormat || "P";
    customProps.selected = state?.value as string;
    customProps.autoComplete = "off";
    customProps.placeholderText = placeholder;
    customProps.locale = i18n?.language;
  } else if (inputType === "textarea") {
    customProps.rows = state?.rows;
  } else if (inputType === "password" && showPassword) {
    customProps.type = "text";
  }

  if (defaultValue !== undefined) {
    customProps.defaultValue = defaultValue;
  }

  return customProps;
};

type InputType = "text" | "tel" | "email" | "number" | "textarea" | "select" | "datepicker" | "password";

type DatePickerPropsFilter =
  | "dateFormat"
  | "maxDate"
  | "minDate"
  | "highlightDates"
  | "startDate"
  | "endDate"
  | "shouldCloseOnSelect"
  | "monthsShown"
  | "selectsStart"
  | "selectsEnd"
  | "onCalendarOpen"
  | "onCalendarClose";
type ProxyOnClickIconType = void | boolean;

interface InputProps extends Pick<ReactDatePickerProps, DatePickerPropsFilter> {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: InputType;
  size?: InputSize;
  maxLength?: number;
  adaptiveHeight?: boolean;
  error?: string;
  mask?: string | RegExp;
  maskChar?: string | null;
  icon?: IconType;
  hideIcon?: boolean;
  centered?: boolean;
  wrapperClassName?: string;
  onChange?(e: React.ChangeEvent, date?: Date | null): void;
  onClickIcon?(): void;
  handleFormatDate?(date: Date): void;
  totalLength?: number;
  tag?: React.ReactType;
  invalid?: boolean;
  defaultValue?: string | number | string[];
}

type StateType = {
  value: string | React.ChangeEvent<HTMLInputElement> | Date;
  rows: number;
  minRows: number;
  maxRows: number;
};

const Input: React.FC<InputProps & Omit<React.HTMLProps<HTMLInputElement>, "size">> = ({
  label,
  name,
  placeholder,
  type = "text",
  size = "default",
  adaptiveHeight,
  error,
  mask,
  icon,
  hideIcon,
  centered,
  totalLength,
  wrapperClassName,
  onChange,
  onClickIcon,
  defaultValue,
  dateFormat,
  ...props
}: InputProps) => {
  const { i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [state, setState] = useState<StateType>({
    value: "",
    rows: 1,
    minRows: 1,
    maxRows: 10,
  });
  const refInput = useRef<HTMLInputElement | null>(null);

  const customProps = getCustomProps({
    inputType: type,
    mask,
    placeholder,
    showPassword,
    state,
    i18n,
    defaultValue,
    dateFormat,
  });
  const textareaLineHeight = LINE_HEIGHT[size] || LINE_HEIGHT["default"];

  const textAreaAutoHeightMemorized = useCallback(
    (elementEvent) => {
      const { minRows, maxRows } = state;
      const element = elementEvent.target || elementEvent;

      const previousRows = element.rows;
      element.rows = minRows;

      const currentRows = ~~(element.scrollHeight / textareaLineHeight) - 1;

      if (currentRows === previousRows) {
        element.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        element.rows = maxRows;
        element.scrollTop = element.scrollHeight;
      }

      setState((prev) => ({ ...prev, value: element.value, rows: currentRows < maxRows ? currentRows : maxRows }));
    },
    [state, textareaLineHeight]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | Date) => {
      if (type === "textarea" && adaptiveHeight) {
        textAreaAutoHeightMemorized(event);
      } else {
        const value = type === "datepicker" ? event : (event as React.ChangeEvent<HTMLInputElement>).target.value;
        setState((state) => ({
          ...state,
          value,
        }));
      }
      if (props.handleFormatDate) {
        props.handleFormatDate(event as Date);
      }
    },
    [type, adaptiveHeight, props.handleFormatDate, textAreaAutoHeightMemorized]
  );

  // const current = refInput.current;
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      const currentInput = refInput.current;
      if (currentInput && currentInput?.type === "textarea" && adaptiveHeight) {
        textAreaAutoHeightMemorized(currentInput);
      }
    });
    return () => {
      clearTimeout(timer);
      refInput.current = null;
    };
  }, [refInput, adaptiveHeight, textAreaAutoHeightMemorized]);

  const hasIcon = !!extractIcon({ type, icon });

  const proxyOnClickIcon = (getState?: boolean): ProxyOnClickIconType => {
    if (getState) return showPassword;
    if (type === "password") setShowPassword((prev) => !prev);
    if (onClickIcon) onClickIcon();
  };

  return (
    <InputWrapper className={wrapperClassName} size={size} name={name} label={label} error={error} centered={centered}>
      <InputStyled
        innerRef={refInput}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        error={error}
        size={size}
        totalLength={totalLength}
        value={state.value ? state.value : customProps.defaultValue}
        onChange={(
          dateOrEvent: React.ChangeEvent<HTMLInputElement> | Date,
          eventDatePicker: React.ChangeEvent<HTMLInputElement>
        ) => {
          const isDatepicker = type === "datepicker";
          handleChange(dateOrEvent);
          if (onChange) {
            if (!isDatepicker) onChange(dateOrEvent as React.ChangeEvent<HTMLInputElement>);
            if (isDatepicker) {
              onChange(eventDatePicker, dateOrEvent as Date);
            }
          }
        }}
        extraMarginRight={props.maxLength !== undefined}
        hasIcon={hasIcon}
        centered={centered}
        {...props}
        {...customProps}
      />

      <Counter maxLength={props.maxLength} totalLength={totalLength} size={textareaLineHeight} hideIcon={hideIcon} />
      <IconWrapper type={type} icon={icon} hideIcon={hideIcon} onClickIcon={proxyOnClickIcon} />
    </InputWrapper>
  );
};

export { Input };

export const Legend = styled.legend`
  font-size: ${rem(16)};
  line-height: ${rem(21)};
  font-weight: 500;
  // color: ${({ theme }) => theme.colors.secondary_700};
  color: #18235e;
  margin-bottom: 25px;
  outline-style: none;
`;
