import React, { useEffect, useState } from "react";
import { ButtonDropdown, DropdownToggle } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import routes from "routes";
import useTranslationV3 from "hooks/useTranslationV3";
import { useUser } from "hooks/useUser";
import useEntity from "hooks/useEntity";
import { DropdownMenu, DropdownItem } from "../DropdownMenu/DropdownMenu";
import { getIsBetaPanel } from "../../../panel/services/SessionStorage";
import { reverse } from "named-urls";

interface UserMenuItemProps {
  tag?: React.ElementType;
  needsAdmin?: boolean;
  needsTB?: boolean;
  needsVolunteer?: boolean;
  needsOrganization?: boolean;
  [propName: string]: any;
}

const UserMenuItem = ({
  tag: Tag = DropdownItem,
  needsAdmin,
  needsTB,
  needsVolunteer,
  needsOrganization,
  ...props
}: UserMenuItemProps) => {
  const { isAdmin, isOrganization, isVolunteer } = useUser();
  const { isPlatform } = useEntity();

  // Exclusive
  if (needsTB && !isPlatform) return null;

  if (
    !(needsAdmin || needsOrganization || needsVolunteer) ||
    (needsAdmin && isAdmin) ||
    (needsOrganization && isOrganization) ||
    (needsVolunteer && isVolunteer)
  )
    return <Tag {...props} />;

  return null;
};

interface UserMenuProps {
  itemTag?: React.ElementType;
}

export const UserMenu = ({ itemTag }: UserMenuProps) => {
  const { t } = useTranslationV3();
  const isPanelBeta = getIsBetaPanel();
  const { user } = useUser();
  let aux = "";
  const { entity, language } = useEntity();

  /* "both" | "volunteer" | "project" | "admin" | "management"; */
  if (user) {
    switch (user?.last_context_access.type || user?.selected_dash_profile) {
      case "admin":
        aux = reverse(routes.panelBeta.admin.resume);
        break;
      case "project":
        aux = reverse(routes.panelBeta.project.summary, { slug: user?.last_context_access.project_slug ?? "" });
        break;
      case "volunteer":
        aux = reverse(routes.panelBeta.volunteer.resume);
        break;
      case "role management":
        aux = reverse(routes.panelBeta.admin.platform.self);
        break;
      case "both":
        aux = reverse(routes.panelBeta.volunteer.resume);
        break;
    }
  } else {
    aux = routes.panel.toString();
  }
  return (
    <>
      <UserMenuItem tag={itemTag} to={aux}>
        {t("pages.panel.summary.title")}
      </UserMenuItem>
      <UserMenuItem needsAdmin needsOrganization tag={itemTag} to={routes.panel.project.toString()}>
        {t("pages.panel.project.title")}
      </UserMenuItem>
      {language === "pt-pt" && (
        <UserMenuItem needsAdmin needsOrganization tag={itemTag} to={routes.wizard.projectList.toString()}>
          {t("panel.pages.projectResume.header.actions.createAction")}
        </UserMenuItem>
      )}

      <UserMenuItem needsAdmin needsOrganization tag={itemTag} to={routes.panel.validations.self}>
        {t("pages.panel.validations.title")}
      </UserMenuItem>
      <UserMenuItem needsOrganization tag={itemTag} to={routes.panel.donations.toString()}>
        {t("pages.panel.donations.title")}
      </UserMenuItem>
      <UserMenuItem needsAdmin needsTB tag={itemTag} to={routes.panel.programs.self}>
        {t("pages.panel.programs.title")}
      </UserMenuItem>
      <UserMenuItem needsAdmin needsTB tag={itemTag} to={routes.panel.self}>
        {t("pages.panel.tracking.title")}
      </UserMenuItem>
      <UserMenuItem needsVolunteer tag={itemTag} to={routes.panel.volunteer.donations}>
        {t("pages.panel.myDonations.title")}
      </UserMenuItem>
      {window.location.hostname == "transformabrasil.com.br" ||
      window.location.hostname == "brasil.hom.transform.click" ||
      window.location.hostname == "brasil.local.transform.click" ||
      window.location.hostname == "localhost" ? (
        <UserMenuItem tag={itemTag} to={routes.panel.settings.preferences}>
          {t("pages.panel.settings.title")}
        </UserMenuItem>
      ) : (
        <UserMenuItem tag={itemTag} to={routes.panel.settings.general}>
          {t("pages.panel.settings.title")}
        </UserMenuItem>
      )}
      <UserMenuItem tag={itemTag} to={routes.logout} ignoreProgram={true}>
        {t("actions.logout.label")}
      </UserMenuItem>
    </>
  );
};

const UserMenuDropdown = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <CustomDropdownToggle>
          <Avatar>
            <img src={user?.user_picture} alt="" />
          </Avatar>
          <Caret />
        </CustomDropdownToggle>
        <DropdownMenu tag="ul" right>
          <UserMenu />
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

export default UserMenuDropdown;

const Avatar = styled.div`
  margin: 0;
  width: 34px;
  height: 34px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 1px 1px ${({ theme }) => theme.v3.colors.neutralLightest};
  transition: ${transition.fast("box-shadow")};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center;";
  }
`;

const Caret = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: ${({ theme }) => theme.v3.colors.neutralLightness} transparent transparent transparent;
  margin-left: 12px;
  transition: ${transition.fast("border")};
`;

const CustomDropdownToggle = styled(DropdownToggle)`
  padding: 0;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: none;

    ${Avatar} {
      box-shadow: 0 0 1px 1px ${({ theme }) => theme.v3.colors.feedbackSupport};
    }
  }

  &:hover {
    ${Caret} {
      border-color: ${({ theme }) => theme.v3.colors.neutralLight} transparent transparent transparent;
    }
  }
`;
