import React from "react";
import { useTranslation } from "react-i18next";
import SimpleInputPage from "wizard/pages/common/SimpleInputPage";
import { PageEvents } from "../definitions/commonTypes";

interface InputActionNamePageProps extends PageEvents<string> {
  name: string;
}

const InputActionNamePage: React.FC<InputActionNamePageProps> = ({ name, ...props }) => {
  const { t } = useTranslation();
  return (
    <SimpleInputPage
      title={t("plain:De um titulo para sua acao")}
      description={t(
        "plain:Um titulo precisa ser simples e direto para que as pessoas entendam o que pretende realizar"
      )}
      placeholder={t("plain:Digite aqui...")}
      value={name}
      {...props}
    />
  );
};

export default InputActionNamePage;
