import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { rem } from "polished";
import styled from "styled-components/macro";
import { SIZE_IMAGE, ACCEPT_IMAGE, CROP_SIZE_COVER, CROP_CONFIG } from "utils/constants";
import Icon from "components/Icon/Icon";
import { PhotoFile } from "wizard/pages/definitions/commonTypes";
import { handleRejectFile, FileErrorType, getMinimumZoom, getImage, imageConverter } from "utils/helpers";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import { css } from "styled-components/macro";
import Cropper, { Point, CropInfo } from "components/Cropper";

interface DropzoneProps {
  file?: PhotoFile;
  getRootProps(): void;
  getInputProps(): React.InputHTMLAttributes<HTMLInputElement>;
}

interface ImageCoverCropperProps {
  disabled?: boolean;
  file?: PhotoFile;
  hideCropper?: boolean;
  onChange?: (file: PhotoFile | undefined) => void;
  validateImage: (file: HTMLImageElement) => boolean;
  onRejectedFile: <T extends File>(rejectedFiles: T[], error: FileErrorType) => void;
  onCropComplete: (cropInfo: CropInfo) => void;
}

const Dropzone = ({ getRootProps, getInputProps, ...props }: DropzoneProps) => {
  const { t } = useTranslation();
  return (
    <>
      <DropzoneWrapper {...getRootProps()} {...props} data-cy="cover-dropzone">
        <input {...getInputProps()} />
        <IconWrapper>
          <Icon color="#9FACBD" type="photoCamera" title={t("wizard.iconsTitle.photoCamera")} />
          <Text>{t("wizard.common.add")}</Text>
        </IconWrapper>
      </DropzoneWrapper>
    </>
  );
};

const ImageCoverCropper = ({
  file,
  hideCropper,
  onChange,
  validateImage,
  onRejectedFile,
  onCropComplete,
  disabled = false,
}: ImageCoverCropperProps): JSX.Element => {
  const { t } = useTranslation();

  const loadData = useLocalStorageRead(
    "creating.profile.coverCrop",
    () => ({ crop: CROP_CONFIG.crop, zoom: CROP_CONFIG.zoom }),
    "session"
  ) ?? { crop: CROP_CONFIG.crop, zoom: CROP_CONFIG.zoom };

  const [crop, setCrop] = useState<Point>(loadData.crop);
  const [zoom, setZoom] = useState(loadData?.zoom);
  const [minZoom, setMinZoom] = useState(CROP_CONFIG.zoom);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: SIZE_IMAGE.SIZE_2MB,
    accept: ACCEPT_IMAGE,
    multiple: false,
    disabled: disabled,

    onDrop: async (acceptedFiles, rejectedFiles) => {
      const file = acceptedFiles.shift();

      handleRejectFile(rejectedFiles, (errorType) => onRejectedFile(rejectedFiles, errorType));

      if (file && onChange) {
        const image = await getImage(URL.createObjectURL(file));
        if (!validateImage(image)) return;

        const dataURL = await imageConverter(image, undefined, "BASE64", 1);

        onChange({ cropped: dataURL, source: dataURL });
      }
    },
  });

  const onLoadMediaCallback = useCallback((mediaSize, containerSize) => {
    const z = getMinimumZoom(mediaSize, containerSize);
    setMinZoom(z);
    setZoom(z);
    setCrop({
      x: 0,
      y: 0,
    });
  }, []);

  useLocalStorageWrite({ crop, zoom }, "creating.profile.coverCrop", "session", 500);

  return (
    <>
      {!file?.source && <Dropzone file={file} getRootProps={getRootProps} getInputProps={getInputProps} />}
      {file?.source && (
        <CropperWrapper disabled={disabled} tabIndex={0} data-cy="cover-preview">
          {hideCropper && <ImgStyled src={file.source} />}
          {!hideCropper && (
            <Cropper
              src={file.source}
              crop={crop}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={3}
              cropSize={CROP_SIZE_COVER}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              onMediaLoaded={onLoadMediaCallback}
            />
          )}
          <button
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              if (onChange) onChange(undefined);
              setZoom(CROP_CONFIG.zoom);
              setCrop(CROP_CONFIG.crop);
            }}
            disabled={disabled}
          >
            <Icon type="close" title={t("wizard.iconsTitle.trash")} />
          </button>
        </CropperWrapper>
      )}
    </>
  );
};

export default ImageCoverCropper;

export const CropperWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  height: 100%;
  overflow: hidden;

  button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    margin: 0 auto;
    border: 0;
    transition: 0.3s;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 100%;

    svg {
      width: 10px;
      height: 10px;
      fill: #fff;
    }

    :focus {
      bottom: 10px;
    }
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover > button,
      :focus > button {
        bottom: 10px;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    `}
`;

const DropzoneWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  height: 100%;
`;

const Text = styled.span`
  color: #657d9b;
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  span {
    margin-top: 10px;
  }
`;

const ImgStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
