import React from "react";
import MultiLineInput from "../common/MultilineInputPage";
import { PageEvents } from "../definitions/commonTypes";
import { useTranslation } from "react-i18next";

interface DescriptionVacancyPageProps extends PageEvents {
  description?: string;
}

const InputDescriptionVacancyPage: React.FC<DescriptionVacancyPageProps> = ({ description, ...props }) => {
  const { t } = useTranslation();

  return (
    <MultiLineInput
      title={t("wizard.pages.vacancy.description.title")}
      description={t("wizard.pages.vacancy.description.subtitle")}
      clue={t("wizard.pages.vacancy.description.clue")}
      placeholder={""}
      value={description}
      continueDisabled={!!props.error}
      {...props}
      maxLength={1000}
      totalLength={description?.length || 0}
    />
  );
};

export default InputDescriptionVacancyPage;
