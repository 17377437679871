import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";

export interface UpdateMaterialItemRequest {
  action: string;
  materialId: string;
  itemId: string;

  // Data
  title: string;
  quantity: number;
}

export type UpdateMaterialItemResponse = ResponseWithParams<ResponseStatus, any>;

const updateMaterialItem = ({
  action,
  materialId,
  itemId,
  ...data
}: UpdateMaterialItemRequest): Promise<AxiosResponse<UpdateMaterialItemResponse>> =>
  http.put<UpdateMaterialItemResponse>(`/actions/${action}/donors/materials/${materialId}/item/${itemId}`, data);

export default updateMaterialItem;
