import Icon from "components/Icon/Icon";
import { rem } from "polished";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Point } from "react-easy-crop/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ACCEPT_IMAGE, CROP_CONFIG, CROP_SIZE, SIZE_IMAGE } from "utils/constants";
import { FileErrorType, getImage, getMinimumZoom, handleRejectFile, imageConverter } from "utils/helpers";
import { PhotoFile } from "wizard/pages/definitions/commonTypes";
import Cropper, { CropInfo } from "components/Cropper";

interface DropzoneProps {
  file?: PhotoFile;
  getRootProps(): void;
  getInputProps(): React.InputHTMLAttributes<HTMLInputElement>;
}

interface ImageProfileCropperProps {
  file?: PhotoFile;
  cropEnabled?: boolean;
  crop: Point;
  zoom: number;
  onCropChanged(crop: Point): void;
  onZoomChanged(zoom: number): void;
  onChange?: (data: PhotoFile | undefined) => void;
  validateImage: (file: HTMLImageElement) => boolean;
  onAcceptFile?: <T extends File>(file: T) => void;
  onRejectedFile?: <T extends File>(rejectedFiles: T[], error: FileErrorType) => void;
  onCropComplete?: (croppedArea: CropInfo) => void;
}

const Dropzone = ({ getRootProps, getInputProps, ...props }: DropzoneProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DropzoneWrapper {...getRootProps()} {...props} data-cy="profile-dropzone">
        <input {...getInputProps()} />
        <IconWrapper>
          <Icon color="#FFFFFF" type="photoCamera" />
          <Text>{t("wizard.common.add")}</Text>
        </IconWrapper>
      </DropzoneWrapper>
    </>
  );
};

const ImageProfileCropper = ({
  file,
  cropEnabled,
  crop,
  zoom,
  onCropChanged,
  onZoomChanged,
  onCropComplete,
  onChange,
  validateImage,
  onRejectedFile,
  onAcceptFile,
}: ImageProfileCropperProps): JSX.Element => {
  const [minZoom, setMinZoom] = useState(CROP_CONFIG.zoom);
  const [, setMaxZoom] = useState(CROP_CONFIG.zoom);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: SIZE_IMAGE.SIZE_2MB,
    accept: ACCEPT_IMAGE,
    multiple: false,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const file = acceptedFiles.shift();

      if (file && onAcceptFile) onAcceptFile(file);

      handleRejectFile(rejectedFiles, (errorType) => {
        if (onRejectedFile) return onRejectedFile(rejectedFiles, errorType);
      });

      if (file && onChange) {
        const image = await getImage(URL.createObjectURL(file));
        if (!validateImage(image)) return;

        const dataURL = await imageConverter(image, undefined, "BASE64", 1);

        onChange({ cropped: dataURL, source: dataURL });
      }
    },
  });

  const onMediaLoadedCallback = useCallback(
    (mediaSize, containerSize) => {
      const z = getMinimumZoom(mediaSize, containerSize);
      setMaxZoom(3);
      setMinZoom(z);
      onZoomChanged(z);
    },
    [onZoomChanged]
  );

  return (
    <>
      {!file?.cropped && <Dropzone file={file} getRootProps={getRootProps} getInputProps={getInputProps} />}
      {file?.cropped && (
        <Preview tabIndex={0} data-cy="profile-preview">
          <ImageWrapper>
            {cropEnabled !== false ? (
              <Cropper
                src={file?.source}
                crop={crop}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={3}
                cropSize={{ width: CROP_SIZE, height: CROP_SIZE }}
                onCropChange={onCropChanged}
                onZoomChange={onZoomChanged}
                onCropComplete={onCropComplete}
                onMediaLoaded={onMediaLoadedCallback}
              />
            ) : (
              <Image src={file.cropped} width="100%" height="100%" alt="" />
            )}
          </ImageWrapper>
          <button
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              if (onChange) onChange(undefined);
              onZoomChanged(CROP_CONFIG.zoom);
              onCropChanged(CROP_CONFIG.crop);
            }}
          >
            <Icon type="close" />
          </button>
        </Preview>
      )}
    </>
  );
};

export default ImageProfileCropper;

const IconWrapper = styled.section`
  position: absolute;
  left: 35%;
  top: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  p {
    margin-top: 8px;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: ${rem(15)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: ${rem(22)};
  text-align: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
`;

const CircleShape = styled.div`
  width: ${rem(200)};
  height: ${rem(200)};
  border-radius: 100%;
  outline: none !important;
  background-color: #fff;
`;

const DropzoneWrapper = styled(CircleShape)`
  position: relative;
  background-color: ${({ theme }) => theme.v3.colors.primary};
`;

const Preview = styled(CircleShape)`
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #e2e6f4;
  overflow: hidden;

  :hover > button,
  :focus > button {
    bottom: 15px;
  }

  & > button {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    border: 0;
    width: 30px;
    height: 30px;
    transition: 0.3s;

    :focus {
      bottom: 15px;
    }

    svg {
      width: 10px;
      height: 10px;
      fill: #fff;
    }
  }
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
`;
