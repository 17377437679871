import Password from "components/common/PasswordRules";
import { FormGroup } from "components/register/Form";
import emailCheck from "core/api/users/emailCheck";
import { ValidationErrorSet, ValidationError } from "core/api/definitions";
import { debounce } from "lodash";
import { rem } from "polished";
import React, { Fragment, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";
import InputLabeled from "wizard/components/Input/InputLabeled";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { PageEvents } from "../definitions/commonTypes";
import useCountry from "hooks/useCountry";
import { isPossiblePhoneNumber, isValidPhoneNumber, PhoneNumber } from "react-phone-number-input";
import InputPhone from "wizard/components/Input/InputPhone";

export interface AccessInfo {
  email: string;
  phone: {
    country?: string;
    ddd?: string;
    number: string;
  };
}

export type AccessInfoPasswords = {
  passwordConfirm: string;
  password: string;
};

export type AccessInfoData = {
  info?: AccessInfo;
  passwords?: AccessInfoPasswords;
};

type Field = "email" | "ddd" | "number" | "password";

export interface AccessDataInfoBaseProps<T = AccessInfoData> extends PageEvents<T> {
  formData?: T;
  onTouched?(field: Field): void;
  onBlur?(e?: React.FocusEvent, field?: string): void;
  handleError?(error: string): void;
  autoFocus?: boolean;
}

const initFormData: AccessInfoData = {
  info: {
    email: "",
    phone: {
      ddd: "",
      number: "",
    },
  },
  passwords: {
    password: "",
    passwordConfirm: "",
  },
};

const InputAccessInfoBase: React.FC<AccessDataInfoBaseProps> = ({
  formData,
  onTouched,
  onChange,
  handleError,
  autoFocus,
  onBlur,
  error,
}) => {
  const { t } = useTranslation();

  const { country: countryCode, iso3166_1: baseCountry, isInternationalPhone, getCountryCallingCode } = useCountry();

  const [hasError, setHasError] = useState<string>();
  const [hasPhoneError, setHasPhoneError] = useState<string>();
  const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);

  const minLength = Password.RulesTest.minLength(formData?.passwords?.password || "");
  const alphanumeric = Password.RulesTest.alphanumeric(formData?.passwords?.password || "");
  const capitalLetter = Password.RulesTest.capitalLetter(formData?.passwords?.password || "");
  const specialCharacters = Password.RulesTest.specialCharacters(formData?.passwords?.password || "");

  const equalPassword = formData?.passwords?.password === formData?.passwords?.passwordConfirm;
  const formatPassword = minLength && alphanumeric && capitalLetter && specialCharacters;

  const emailCheckCallback = useCallback(
    debounce(async (value: string) => {
      try {
        setIsCheckingEmail(true);
        setHasError(undefined);
        await emailCheck({ email: value });
      } catch (err) {
        const {
          response: { data },
        } = err;
        const strRes = data?.params?.email && data.params.email?.shift();
        setHasError(t([`error.${strRes}`, "error.genericDefault"]));
      } finally {
        setIsCheckingEmail(false);
      }
    }, 700),
    [hasError]
  );

  const emailOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value && value.length > 10 && /\S+@\S+\.\S+/.test(value)) emailCheckCallback(value);
      if (onChange) {
        const f = formData || initFormData;
        onChange({ ...f, info: { ...f!.info!, email: value || "" } });
      }
      if (onTouched) onTouched("email");
    },
    [onChange, onTouched, formData]
  );

  const phoneDDDOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (onChange) {
        const f = formData || initFormData;
        onChange({
          ...f,
          info: {
            ...(f?.info || { email: "", phone: { ddd: "", number: "" } }),
            phone: {
              ...(f?.info?.phone || { number: "" }),
              ddd: value,
            },
          },
        });
      }
      if (onTouched) onTouched("ddd");
    },
    [onChange, formData, onTouched]
  );

  const phoneNumberOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (onChange) {
        const f = formData || initFormData;
        onChange({
          ...f,
          info: {
            ...(f?.info || { email: "", phone: { ddd: "", number: "" } }),
            phone: {
              ...(f?.info?.phone || { ddd: "" }),
              number: value,
            },
          },
        });
      }
      if (onTouched) onTouched("number");
    },
    [onChange, formData, onTouched]
  );

  const phoneInternationalOnChangeCallback = useCallback(
    (phone: PhoneNumber | undefined) => {
      if (onChange) {
        const f = formData || initFormData;
        onChange({
          ...f,
          info: {
            ...(f?.info || { email: "", phone: { ddd: "", number: "" } }),
            phone: {
              ...(f?.info?.phone || { ddd: "" }),
              country: phone?.countryCallingCode ? `+${phone?.countryCallingCode}` : "",
              number: phone?.nationalNumber || "",
            },
          },
        });
      }
      if (onTouched) onTouched("number");

      const phoneError = isPossiblePhoneNumber(phone?.number ?? "");
      if (!phoneError && !hasPhoneError) setHasPhoneError("format-invalid");
      if (phoneError && hasPhoneError) setHasPhoneError("");
    },
    [onChange, formData, onTouched, setHasPhoneError, hasPhoneError]
  );

  const passwordOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (onChange) {
        const f = formData || initFormData;
        onChange({
          ...f,
          passwords: {
            ...f!.passwords!,
            password: value,
          },
        });
      }
    },
    [onChange, formData]
  );

  const passwordConfirmOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (onChange) {
        const f = formData || initFormData;
        onChange({
          ...f,
          passwords: {
            ...f!.passwords!,
            passwordConfirm: value,
          },
        });
      }
      if (onTouched) onTouched("password");
    },
    [onChange, formData]
  );

  const passwordErrors = (error as ValidationErrorSet)?.password as ValidationError;
  const isPasswordConfirmationError = passwordErrors?.find((error: string) => error === "confirmation-does-not-match");
  const dddError =
    typeof error === "object"
      ? (error as ValidationErrorSet)["phone.ddd"] || (error as ValidationErrorSet)["contacts.0.ddd"]
      : undefined;

  useEffect(() => {
    if (!handleError) return;
    if (!equalPassword) handleError(t("wizard.error.common.confirmation-does-not-match"));
    if (!formatPassword) handleError(t("wizard.error.common.format-invalid"));
    if (hasPhoneError) handleError(hasPhoneError);
    if (equalPassword && formatPassword && !hasPhoneError) handleError("");
  }, [equalPassword, formatPassword, handleError, hasPhoneError, t]);

  return (
    <Fragment>
      <FormGroup>
        <InputLabeled
          type="email"
          name="email"
          autoFocus={autoFocus}
          label={t("wizard.pages.accessData.form.email.label")}
          placeholder={t("wizard.pages.accessData.form.email.placeholder")}
          value={formData?.info?.email}
          required
          onChange={emailOnChangeCallback}
          onBlur={(e: React.FocusEvent) => {
            if (onBlur) onBlur(e, "email");
          }}
          error={hasError || (typeof error === "object" ? (error as ValidationErrorSet)?.email : undefined)}
        />
        {isCheckingEmail && <EmailFeedback>{t("wizard.pages.accessData.form.email.feedback")}</EmailFeedback>}
      </FormGroup>
      <Row>
        {isInternationalPhone ? (
          <Col className="col-12">
            <InputPhone
              placeholder={countryCode}
              onPhoneChange={phoneInternationalOnChangeCallback}
              value={`${formData?.info?.phone?.country || countryCode}${formData?.info?.phone?.number}`}
              defaultCountry={baseCountry}
              onCountryChange={getCountryCallingCode}
              error={hasPhoneError || (dddError as ValidationError)}
            />
          </Col>
        ) : (
          <>
            <Col className="col-3">
              <InputLabeled
                type="tel"
                name="ddd"
                mask="99"
                label={t("wizard.pages.accessData.form.ddd.label")}
                placeholder={t("wizard.pages.accessData.form.ddd.placeholder")}
                value={formData?.info?.phone?.ddd}
                required
                onChange={phoneDDDOnChangeCallback}
                onBlur={(e: React.FocusEvent) => {
                  if (onBlur) onBlur(e, "ddd");
                }}
                error={dddError ? (dddError as ValidationError)?.map((err) => `${err}-simple`) : undefined}
              />
            </Col>
            <Col className="col-9">
              <InputLabeled
                type="tel"
                name="phone"
                mask="9.9999-9999"
                label={t("wizard.pages.accessData.form.phone.label")}
                placeholder={t("wizard.pages.accessData.form.phone.placeholder")}
                value={formData?.info?.phone?.number}
                required
                onChange={phoneNumberOnChangeCallback}
                onBlur={(e: React.FocusEvent) => {
                  if (onBlur) {
                    onBlur(e, "number");
                    onBlur(e, "ddd");
                  }
                }}
                error={
                  typeof error === "object"
                    ? (error as ValidationErrorSet)["phone.number"] ||
                      (error as ValidationErrorSet)["contacts.0.number"]
                    : undefined
                }
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <InputLabeled
            type="password"
            name="password"
            label={t("wizard.pages.accessData.form.password.label")}
            placeholder={t("wizard.pages.accessData.form.password.placeholder")}
            value={formData?.passwords?.password}
            required
            onChange={passwordOnChangeCallback}
            onBlur={(e: React.FocusEvent) => {
              if (onBlur) onBlur(e, "password");
            }}
            error={
              typeof error === "object" &&
              !!passwordErrors?.filter((err) => err !== "confirmation-does-not-match")?.length
                ? " "
                : undefined
            }
          />
        </Col>
        <Col>
          <InputLabeled
            type="password"
            name="confirm_password"
            label={t("wizard.pages.accessData.form.password.labelConfirm")}
            placeholder={t("wizard.pages.accessData.form.password.placeholder")}
            value={formData?.passwords?.passwordConfirm}
            required
            onChange={passwordConfirmOnChangeCallback}
            error={
              isPasswordConfirmationError && passwordErrors?.length === 1 && formData?.passwords?.passwordConfirm
                ? " "
                : undefined
            }
          />
        </Col>
      </Row>
      {typeof error === "object" && (
        <WizardTrans id="passwordError">
          {passwordErrors?.length !== 1
            ? passwordErrors?.filter((err) => err !== "confirmation-does-not-match")
            : formData?.passwords?.passwordConfirm
            ? passwordErrors
            : ""}
        </WizardTrans>
      )}
      <CustomRow>
        <Col>
          <Password.Rules.MinLength isOk={minLength} />
          <Password.Rules.Alphanumeric isOk={alphanumeric} />
        </Col>
        <Col>
          <Password.Rules.CapitalLetter isOk={capitalLetter} />
          <Password.Rules.SpecialCharacters isOk={specialCharacters} />
        </Col>
      </CustomRow>
    </Fragment>
  );
};

export default InputAccessInfoBase;

const EmailFeedback = styled.small`
  color: ${({ theme }) => theme.colors.support_text};
  width: 100%;
  margin-top: ${rem(4)};
`;

const CustomRow = styled(Row)`
  margin-top: 20px;

  div > div {
    margin-bottom: 8px;
  }

  ${mediaQueries.sm(css`
    flex-direction: column;
  `)}
`;
