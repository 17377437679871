import SupportText from "components/common/SupportText";
import useCountry from "hooks/useCountry";
import useTranslationV3 from "hooks/useTranslationV3";
import {
  Cause,
  Filter as FilterInterface,
  filtersData,
  filtersDataChile,
  Interest,
  Option,
  Skill,
} from "pages/ListActions/DataOfFilters";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import mqV3 from "utils/mediaQueriesV3";
import ButtonBase from "v3/components/Button/ButtonBase";
import ActionLink from "v3/components/Link/ActionLink";
import Icon from "../Icons/Icon";
import FilterOptions from "./FilterOptions";

interface Filters {
  causes?: Cause[];
  abilities?: Interest[];
  functions?: Skill[];
}

export type RemoveFilterOptionType = (filters: FilterInterface[]) => FilterInterface[];

export type InitialFilters = Filters;

export interface FilterProps {
  className?: string;

  onSelected(el: Option[], searchType: SearchType): void;
  onApply?(): void;
  onClean(searchType?: SearchType): void;
  removeFilterOption?: RemoveFilterOptionType;
  initialFilters?: InitialFilters;
}

export type SearchType = "causes" | "functions" | "abilities" | "availability";

export const applyInitialFilters = (data?: InitialFilters, isChile?: boolean) => {
  if (!data) {
    if (isChile) {
      return filtersData;
    } else {
      return filtersDataChile;
    }
  }

  const handleOptions = (id: string, filters?: (Cause | Interest | Skill)[]) => {
    const filter = filtersData.find((f) => f.id === id);
    if (filter && filters && filters?.length) {
      const opts = filter.options.map((opt) =>
        filters.find((c) => c === opt.value) ? { ...opt, checked: true } : opt
      );
      return { ...filter, options: opts };
    }

    const filterChile = filtersDataChile.find((f) => f.id === id);
    if (filterChile && filters && filters?.length) {
      const opts = filterChile.options.map((opt) =>
        filters.find((c) => c === opt.value) ? { ...opt, checked: true } : opt
      );
      return { ...filterChile, options: opts };
    }

    if (isChile) {
      return filterChile;
    } else {
      return filter;
    }

    return filter;
  };

  const newFilterData = [];

  newFilterData.push(handleOptions("causes", data.causes));
  newFilterData.push(handleOptions("abilities", data.abilities));
  newFilterData.push(handleOptions("functions", data.functions));
  newFilterData.push(filtersData.find((f) => f.id === "availability"));

  return newFilterData as FilterInterface[];
};

const Filter: React.FC<FilterProps> = ({
  onSelected,
  onApply,
  onClean,
  initialFilters,
  removeFilterOption,
  children,
  className,
}) => {
  const { t } = useTranslationV3();
  const { iso3166_1 } = useCountry();

  const initialStateMemorized = useMemo(
    () =>
      removeFilterOption
        ? removeFilterOption(applyInitialFilters(initialFilters, iso3166_1 === "cl"))
        : applyInitialFilters(initialFilters, iso3166_1 === "cl"),
    [initialFilters, removeFilterOption]
  );

  const [filters, setFilters] = useState(initialStateMemorized);
  const [isOpen, setIsOpen] = useState(false);
  const [filtersIsOpen, setFiltersIsOpen] = useState<SearchType | undefined>();

  const searchOptionsMemorized = useMemo(() => filters.find((d) => d.id === filtersIsOpen), [filtersIsOpen, filters]);

  const onSelectedProxy = useCallback(
    (el: Option) => {
      if (!searchOptionsMemorized) return;
      const checkeds = searchOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = searchOptionsMemorized?.options.filter((opt) => opt.value !== el.value || opt?.param !== el?.param);
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...searchOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [searchOptionsMemorized, filtersIsOpen, onSelected]
  );

  const countMemorized = useMemo(
    () => ({
      causes: filters.find((el) => el.id === "causes")?.options.filter((opt) => opt.checked).length,
      functions: filters.find((el) => el.id === "functions")?.options.filter((opt) => opt.checked).length,
      abilities: filters.find((el) => el.id === "abilities")?.options.filter((opt) => opt.checked).length,
      availability: filters.find((el) => el.id === "availability")?.options.filter((opt) => opt.checked).length,
    }),
    [filters]
  );

  const onCleanBySearchTypeCallback = useCallback(() => {
    setFilters((prev) => {
      const filters = prev.filter((f) => f.id !== filtersIsOpen);
      const nFilter = filtersData.find((f) => f.id === filtersIsOpen);
      return [...filters, nFilter!];
    });
    onClean(filtersIsOpen);
  }, [filtersIsOpen, onClean]);

  const onCleanCallback = useCallback(() => {
    let cleanFilter = filtersData;
    if (iso3166_1 === "cl") cleanFilter = filtersDataChile;

    setFilters(cleanFilter);
    onClean();
  }, [onClean]);

  return (
    <Fragment>
      <FiltersContainer className={className}>
        <ActionsContainer show={isOpen}>
          <FilterOptions
            isOpen={!!filtersIsOpen}
            list={searchOptionsMemorized}
            onClose={() => setFiltersIsOpen(undefined)}
            onClean={onCleanBySearchTypeCallback}
            onSelected={onSelectedProxy}
            onApply={onApply}
          >
            <Button size="sm" onClick={() => setFiltersIsOpen("causes")}>
              {countMemorized.causes ? `${t("plain:Causas")} - ${countMemorized.causes}` : t("plain:Causas")}
            </Button>
            <Button size="sm" onClick={() => setFiltersIsOpen("functions")}>
              {countMemorized.functions ? `${t("plain:Função")} - ${countMemorized.functions}` : t("plain:Função")}
            </Button>
            <Button size="sm" onClick={() => setFiltersIsOpen("abilities")}>
              {countMemorized.abilities
                ? `${t("plain:Habilidades")} - ${countMemorized.abilities}`
                : t("plain:Habilidades")}
            </Button>
            <Button size="sm" onClick={() => setFiltersIsOpen("availability")}>
              {countMemorized.availability
                ? `${t("plain:Disponibilidade")} - ${countMemorized.availability}`
                : t("plain:Disponibilidade")}
            </Button>
          </FilterOptions>
          <ClearContainer>
            <ActionLink tag="button" onClick={onCleanCallback}>
              {t("actions.cleanFilter.label")}
            </ActionLink>
          </ClearContainer>
        </ActionsContainer>
        <CategoryContainer>
          <CheckboxContainer>{children}</CheckboxContainer>
          <div>
            <ButtonFilter size="sm" onClick={() => setIsOpen((prev) => !prev)}>
              {t("plain:Filtros")}
              <Icon icon="filter" />
            </ButtonFilter>
          </div>
        </CategoryContainer>
      </FiltersContainer>
    </Fragment>
  );
};

export default Filter;

const FiltersContainer = styled.div``;

const ButtonCSS = css`
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  box-sizing: border-box;
  border-radius: 5px;

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }
`;

// TODO: Remove ButtonBase usage
const Button = styled(ButtonBase)(
  ({ theme }) => css`
    ${ButtonCSS};
    ${transition.normal("transition background-color")};

    :focus,
    :hover {
      background-color: ${theme.v3.colors.secondary};
      color: ${theme.v3.colors.neutralWhite};
    }
  `
);

const ButtonFilter = styled(ButtonBase)`
  ${ButtonCSS};

  :hover {
    background-color: ${({ theme }) => theme.v3.colors.neutralBackground};
  }

  svg {
    margin-left: ${({ theme }) => theme.v3.spacing.xs};
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;

const ClearContainer = styled.div`
  ${SupportText} {
    margin: 0 ${({ theme }) => theme.v3.spacing.xs};
  }

  ${mqV3.smDown} {
    margin-top: ${({ theme }) => theme.v3.spacing.sm};
    margin-left: auto;
  }
`;

const ActionsContainer = styled.div<{ show?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -${({ theme }) => theme.v3.spacing.xs};
  padding: 0;
  border-bottom: 1px solid transparent;
  height: 0;
  overflow: hidden;
  ${transition.normal("transition")};

  ${({ show }) =>
    show &&
    css`
      height: 90px;
      border-bottom-color: ${({ theme }) => theme.v3.colors.neutralLightest};
      padding: ${({ theme }) => theme.v3.spacing.md} 0;
      overflow: visible;

      ${mqV3.smDown} {
        height: 165px;
      }

      ${mqV3.xsDown} {
        height: 225px;
      }
    `}

  .filterOptions > button {
    margin: ${({ theme }) => theme.v3.spacing.xs};
  }

  ${mqV3.smDown} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  padding: ${({ theme }) => theme.v3.spacing.md} 0;

  & > div:last-child {
    margin-left: auto;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: ${({ theme }) => theme.v3.spacing.md};
    margin: 0 12px ${({ theme }) => theme.v3.spacing.xs} 12px;
  }

  ${mqV3.smDown} {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin: 0 ${({ theme }) => theme.v3.spacing.xxs} ${({ theme }) => theme.v3.spacing.sm};
    }
  }
`;
