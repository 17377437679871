import React, { useCallback } from "react";
import { LargeInput } from "wizard/components/common/styles";
import { BaseProps, PageEvents } from "../definitions/commonTypes";
import { Centered } from "wizard/components/common/styles";
import Clue from "wizard/components/Clue/Clue";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import FormWizard from "./FormWizard";
import PlacesAutoCompleteResolved, {
  initialLocation,
  LocationWithAddress,
} from "wizard/components/Input/PlacesAutocompleteResolved";
import { WizardContainer as WizardContainerBase } from "wizard/components/Layout/Wizard";
import { BaseTitle } from "components/common/BaseTitle";
import { TitleContainer } from "wizard/components/Layout/Content";
import { Suggestion } from "react-places-autocomplete";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import { IconType } from "components/Icon/IconFontAwesome";
import CheckboxInput from "components/FormPanel/CheckboxInput";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Text from "v3/components/common/Text";
import { mq } from "utils/mediaQueries";

export interface SimpleInputCepPageProps extends Omit<BaseProps, "description">, PageEvents<LocationWithAddress> {
  value?: LocationWithAddress;
  onSelectRaw?: (address: string, placeID: string) => boolean;
  onSuggest?: (suggestions: ReadonlyArray<Suggestion>) => Suggestion[];
  icon?: IconType;
  continueDisabled?: boolean;
  withChecked?: boolean;
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
}

const SimpleInputCepPage: React.FC<SimpleInputCepPageProps> = ({
  title,
  placeholder,
  clue,
  value,
  error,
  onSelectRaw,
  onSuggest,
  onChange,
  onContinue,
  onBack,
  children,
  icon,
  continueDisabled,
  withChecked,
  checked,
  onChecked,
}) => {
  const { t } = useTranslation();
  const onContinueCallback = useCallback(() => {
    if ((onContinue && value) || (checked && onContinue)) onContinue(value || initialLocation);
  }, [onContinue, value, checked]);

  const onPlacesAutoCompleteChangeCallback = useCallback(
    (value) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <TitleContainer>
          <CustomText tag="h1" size="lg">
            {t(`plain:${title}`)}
          </CustomText>
        </TitleContainer>
        <PlacesAutoCompleteResolved
          inputTag={LargeInput}
          value={value}
          inputProps={{
            placeholder,
            disabled: checked,
          }}
          onSuggest={onSuggest}
          onSelectRaw={onSelectRaw}
          onSelect={onPlacesAutoCompleteChangeCallback}
          onChange={onPlacesAutoCompleteChangeCallback}
          icon={icon}
        />
        {(error || clue) && !checked && (
          <Centered>
            {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
            {clue && <Clue text={clue} />}
          </Centered>
        )}
        {withChecked && (
          <CheckboxInput
            id="simpleInputCep"
            value={checked}
            onChange={(checked) => {
              if (typeof checked === "boolean" && onChecked) onChecked(checked);
            }}
            label={t("wizard.pages.simpleInputCep.checkbox")}
          />
        )}
        {children}
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={continueDisabled || (!!error && !checked)}
        onContinue={onContinueCallback}
      />
    </FormWizard>
  );
};

export default SimpleInputCepPage;

const WizardContainer = styled(WizardContainerBase)`
  .custom-checkbox {
    max-width: 185px;
    margin: 10px auto 0;
  }
`;

const CustomText = styled(Text)`
  ${mq.xsDown} {
    text-align: center;
  }
`;
