import React, { Fragment, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import styled from "styled-components/macro";
import { CustomInput as Input } from "components/Form";
import { rem } from "polished";
import Scrollbars from "react-custom-scrollbars";
import { TrackHorizontal, TrackVertical } from "components/common/style";
import { PageEvents } from "../definitions/commonTypes";
import { ListContainer, ListItem } from "wizard/components/common/list";
import { orderBy } from "lodash";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { abilities as abilitiesSource, abilitiesChile, Option } from "pages/ListActions/DataOfFilters";
import SupportFeedback from "components/common/SupportFeedback";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { TitleContainer } from "wizard/components/Layout/Content";
import Text from "v3/components/common/Text";
import { default as BaseCheckbox } from "v3/components/Checkbox/Checkbox";
import { mq } from "utils/mediaQueries";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import useCountry from "hooks/useCountry";
export interface AbilitySet {
  [key: string]: boolean;
}

interface MatchmakerAbilitiesPageProps extends PageEvents<AbilitySet> {
  abilities: AbilitySet;
}

const MatchmakerAbilitiesPage: React.FC<MatchmakerAbilitiesPageProps> = ({
  abilities = {},
  onChange,
  onBack,
  onContinue,
  error,
}) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState("");
  const { iso3166_1 } = useCountry();

  const list =
    iso3166_1 === "cl" ? orderBy(abilitiesChile, ["description"]) : orderBy(abilitiesSource, ["description"]);

  const abilitiesSize = useMemo(() => Object.values(abilities).filter((k) => k).length, [abilities]);
  const limited = abilitiesSize > 2;
  const disabled = abilitiesSize === 0;

  return (
    <Fragment>
      <WizardContainer>
        <CustomTitleContainer>
          <CustomText tag="h1" size="lg">
            {t("wizard.pages.matchmaker.abilities.title")}
          </CustomText>
          <Text tag="span" size="sm" color="neutralLight">
            {t("wizard.pages.matchmaker.abilities.description")}
          </Text>
        </CustomTitleContainer>
        <ListContainer id="abilitiesList">
          <Scrollbars
            renderTrackVertical={() => <TrackVertical top={0} right={35} />}
            renderThumbHorizontal={() => <TrackHorizontal hidden />}
          >
            {list.map((ability: Option) => (
              <ListItem key={ability.value}>
                <Checkbox
                  id={ability.value}
                  checked={!!abilities[ability.value]}
                  label={ability.label}
                  onValueChange={() => {
                    if ((!limited && onChange) || (onChange && limited && abilities[ability.value])) {
                      onChange({ ...abilities, [ability.value]: !abilities[ability.value] });
                      setHasError("");
                    } else {
                      setHasError(t("error.functionsLimit"));
                    }
                  }}
                />
              </ListItem>
            ))}
          </Scrollbars>
        </ListContainer>
        {!hasError && error && <WizardTrans>{error as ValidationError}</WizardTrans>}
        {hasError && <SupportFeedback>{hasError}</SupportFeedback>}
      </WizardContainer>
      <ActionsFooter
        continueDisabled={disabled}
        onBack={onBack}
        onContinue={() => {
          if (onContinue)
            onContinue(
              Object.keys(abilities).reduce((acc, curr) => (abilities[curr] ? { ...acc, [curr]: true } : acc), {})
            );
        }}
      />
    </Fragment>
  );
};

export default MatchmakerAbilitiesPage;

const Checkbox = styled(BaseCheckbox)`
  .custom-control-label {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      font-weight: 500;
    }
  }
`;

const CustomText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
`;

const CustomTitleContainer = styled(TitleContainer)`
  ${mq.xsDown} {
    h1,
    span {
      text-align: center;
    }
  }
`;
