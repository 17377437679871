import useTranslationV3 from "hooks/useTranslationV3";
import { transparentize } from "polished";
import React, { useEffect, useRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { NavItem as BSNavItem, NavLink as BSNavLink } from "reactstrap";
import routes from "routes";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { Z_INDEX } from "utils/constants";
import SocialNetworks from "./SocialNetworks";

const MainMenuMobileItem: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <MainNavItemStyled>
      <MainNavLinkStyled tag={Link} to={to}>
        {children}
      </MainNavLinkStyled>
    </MainNavItemStyled>
  );
};

const MainNavItemStyled = styled(BSNavItem)`
  margin: 0 0 ${({ theme }) => theme.v3.spacing.sm} 0;
  display: flex;
  white-space: nowrap;
`;

const MainNavLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-size: ${({ theme }) => theme.v3.fontSize.xl};
  padding: ${({ theme }) => theme.v3.spacing.xxs} 0;
  transition: ${transition.fast("color")};
  line-height: 1;

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const MainNavStyled = styled.a`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-size: ${({ theme }) => theme.v3.fontSize.xl};
  padding: ${({ theme }) => theme.v3.spacing.xxs} 0;
  transition: ${transition.fast("color")};
  line-height: 1;
  text-decoration: none;
  outline: none;
  display: block;
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const SecondaryMenuMobileItem: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <SecondaryNavItemStyled>
      <SecondaryNavLinkStyled tag={Link} to={to}>
        {children}
      </SecondaryNavLinkStyled>
    </SecondaryNavItemStyled>
  );
};

const SecondaryNavItemStyled = styled(BSNavItem)`
  margin: 0 0 ${({ theme }) => theme.v3.spacing.xs} 0;
  display: flex;
  white-space: nowrap;
`;

const SecondaryNavLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  font-size: ${({ theme }) => theme.v3.fontSize.md};
  padding: ${({ theme }) => theme.v3.spacing.xxs} 0;
  transition: ${transition.fast("color")};
  line-height: 1;

  &:hover {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
`;

interface MenuMobileProps {
  isOpen: boolean;
  onClose: () => void;
}

const MenuMobile = ({ onClose, isOpen }: MenuMobileProps) => {
  const { t } = useTranslationV3();

  const wrapperNavMobileRef = useRef<HTMLDivElement>(null);
  const navMobileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (
        isOpen &&
        e.target &&
        navMobileRef.current &&
        (!navMobileRef.current.contains(e.target as any) ||
          navMobileRef.current.scrollHeight <= navMobileRef.current.offsetHeight)
      ) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    if (wrapperNavMobileRef.current) {
      wrapperNavMobileRef.current.addEventListener("mousewheel", handleScroll);
      wrapperNavMobileRef.current.addEventListener("touchmove", handleScroll);
    }

    return () => {
      if (wrapperNavMobileRef.current) {
        wrapperNavMobileRef.current.removeEventListener("mousewheel", handleScroll);
        wrapperNavMobileRef.current.removeEventListener("touchmove", handleScroll);
      }
    };
  }, [isOpen, wrapperNavMobileRef]);

  return (
    <WrapperNavMobile isOpen={isOpen} ref={wrapperNavMobileRef}>
      <NavMobile isOpen={isOpen} ref={navMobileRef}>
        <MenuMobileHeader>
          <span>{t("plain:Menu")}</span>
          <ButtonMobileMenuClose onClick={() => onClose()}>
            <span>{t("actions.closeMenu.label")}</span>
          </ButtonMobileMenuClose>
        </MenuMobileHeader>
        <MainMenuMobile>
          <MainMenuMobileItem to={routes.listActions}>{t("pages.actions.title")}</MainMenuMobileItem>
          <MainMenuMobileItem to={routes.opportunities.toString()}>{t("pages.opportunities.title")}</MainMenuMobileItem>
          <MainMenuMobileItem to={routes.listProject}>{t("pages.projects.title")}</MainMenuMobileItem>
          {(window.location.hostname == "transformabrasil.com.br" ||
            window.location.hostname == "brasil.hom.transform.click" ||
            window.location.hostname == "brasil.local.transform.click" ||
            window.location.hostname == "localhost") && (
            <>
              <MainNavStyled href="https://eventos.transformabrasil.com.br/" target="_blank">
                {t("plain:Eventos")}
              </MainNavStyled>
              <MainNavStyled href="https://ebook.transformabrasil.com.br/" target="_blank">
                {t("plain:E-books")}
              </MainNavStyled>
            </>
          )}
          {(window.location.hostname == "transformabrasil.com.br" ||
            window.location.hostname == "brasil.hom.transform.click" ||
            window.location.hostname == "brasil.local.transform.click" ||
            window.location.hostname == "localhost") && (
            <>
              <MainMenuMobileItem to={routes.blog.toString()}>{t("pages.blog.title")}</MainMenuMobileItem>
            </>
          )}
        </MainMenuMobile>
        <SecondaryMenuMobile>
          <SecondaryMenuMobileItem to={routes.about}>Sobre</SecondaryMenuMobileItem>
          {/* <SecondaryMenuMobileItem to={routes.affiliates}>Parceiros</SecondaryMenuMobileItem> */}
          <SecondaryMenuMobileItem to={"#"}>Causas</SecondaryMenuMobileItem>
          <SecondaryMenuMobileItem to={routes.contact}>Contato</SecondaryMenuMobileItem>
        </SecondaryMenuMobile>
        <SecondaryMenuMobile>
          <SecondaryMenuMobileItem to={routes.terms}>Termos de Uso</SecondaryMenuMobileItem>
          <SecondaryMenuMobileItem to={"#"}>Política de Privacidade</SecondaryMenuMobileItem>
        </SecondaryMenuMobile>
        <SocialNetworks />
      </NavMobile>
    </WrapperNavMobile>
  );
};

export default MenuMobile;

const WrapperNavMobile = styled.nav<{ isOpen: boolean }>`
  width: 0;
  height: 100vh;
  background: transparent;
  z-index: ${Z_INDEX.MENU_MOBILE.WRAPPER};
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  transition: background ease-in 0.2s, width ease-in 0.2s 0.2s;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 100vw;
      background: ${({ theme }) => transparentize(0.6, theme.v3.colors.neutralBase)};
      transition: background ease-in 0.2s;
    `};
`;
const NavMobile = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 100vh;
  padding: ${({ theme }) => `${theme.v3.spacing.sm} 0 ${theme.v3.spacing.lg} 0`};
  max-width: 90vw;
  background: #fff;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  transition: width ease-in 0.2s 0.1s, padding-left ease-in 0.2s 0.18s, padding-right ease-in 0.2s 0.18s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 340px;
      padding: ${({ theme }) =>
        `${theme.v3.spacing.sm} ${theme.v3.spacing.sm} ${theme.v3.spacing.lg} ${theme.v3.spacing.lg}`};
      overflow-y: auto;
    `};
`;

const MenuMobileHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;

  span {
    font-size: ${({ theme }) => theme.v3.fontSize.xs};
    color: ${({ theme }) => theme.v3.colors.neutralLight};
    text-transform: uppercase;
  }
`;

const ButtonMobileMenuClose = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  overflow: hidden;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  span {
    display: flex;
    background: ${({ theme }) => theme.v3.colors.neutralBase};
    width: 16px;
    height: 3px;
    border-radius: 2px;
    position: relative;
    text-indent: 9999em;
    transform: rotate(45deg);

    &:after {
      content: "";
      display: flex;
      position: absolute;
      background: ${({ theme }) => theme.v3.colors.neutralBase};
      width: 16px;
      height: 3px;
      border-radius: 2px;
      transform: rotate(90deg);
    }
  }
`;

const MainMenuMobile = styled.ul`
  margin: 0 0 ${({ theme }) => theme.v3.spacing.lg} 0;
  padding: 0;
  list-style: none;
`;

const SecondaryMenuMobile = styled.ul`
  margin: 0 0 ${({ theme }) => theme.v3.spacing.xl} 0;
  padding: 0;
  list-style: none;
`;
