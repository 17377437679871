import { yupResolver } from "@hookform/resolvers/yup";
import api from "core/api";
import useTranslationV3 from "hooks/useTranslationV3";
import { IconType } from "icons";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, ErrorOption, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Col } from "reactstrap";
import styled, { css } from "styled-components";
import { DEFAULT_NAME, DEFAULT_USER, DEFAULT_PASSWORD, DEFAULT_PHONE } from "utils/constants";
import yup from "utils/yup";
import PasswordRules from "v3/components/common/PasswordRules";
import Text from "v3/components/common/Text";
import InputController from "v3/components/Form/Input/InputController";
import { SignUpFormData } from ".";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import { ModalBodyRegister } from "../common";
import { CustomLink, Form, FormGroup, Info } from "./common";
import { APIParamsToString } from "utils/helpers";
import ModalTerms from "../ModalTerms";
import InputPhone from "v3/components/Form/Input/InputPhone";
import Spinner from "v3/components/Spinner/Spinner";
import { Input } from "components/register/Form";
import useCountry from "hooks/useCountry";
import useMask from "hooks/useMask";
import ModalRequestUniqueDocument, {
  ModalRequestDocumentError,
} from "panel/components/Modal/ModalRequestUniqueDocument";
import { UniqueDocument } from "core/api/users/getGeneralData";
import { validateCPF, validateNIF } from "utils/yup";
import { useUser } from "hooks/useUser";
import cookies from "core/cookies";
import { Login } from "core/api/definitions";
import { LoadingStatus } from "core/api/definitions";

export interface SubmitErrorSignUp {
  errorOption: ErrorOption;
  name: keyof SignUpFormData;
}
interface SignUpProps extends Base {
  data?: SignUpFormData | undefined;
  submitErrors?: SubmitErrorSignUp[];
  subscribeFlux?: boolean;
}

interface Base {
  onSubmit(e: SignUpFormData): void;
  toggleStep(): void;
  loading: LoadingStatus;
  setDocumentAux?(document: string): void;
  setSubscribeDocumentAux?(setSubscribeDocumentAux: boolean): void;
}

const schemaSignUp = yup.object().shape({
  name: yup
    .string()
    .required("errors.is-required")
    .min(2, "errors.does-not-minimum-character-required")
    .max(50, "errors.length-more-than-maximum-character-required"),
  email: yup.string().email("errors.format-is-invalid").required("errors.is-required"),
  phone: yup.string().required("errors.is-required").phone(),
  password: yup.string().required("errors.is-required").password(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "errors.password-does-not-match")
    .required("errors.is-required"),
  accept_terms: yup.boolean().required("errors.is-required").equals([true]),
  acceptToReceiveEmails: yup.boolean().notRequired(),
});

const SignUpStep = ({
  onSubmit,
  toggleStep,
  submitErrors,
  loading,
  data,
  subscribeFlux,
  setDocumentAux,
  setSubscribeDocumentAux,
}: SignUpProps) => {
  const { t } = useTranslationV3();
  const { personalDocumentType } = useCountry();
  const { personalDocument } = useMask();

  const [uniqueDocument, setUniqueDocument] = useState<UniqueDocument>({
    type: personalDocumentType,
    value: "",
  });
  const [hasErrorModalRequest, setHasErrorModalRequest] = useState<{
    type?: ModalRequestDocumentError;
    message?: string;
  }>();
  const [passwordIcon, setPasswordIcon] = useState<IconType>("eyeClosed");
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState<IconType>("eyeClosed");
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [termsIsOpen, setTermsIsOpen] = useState(false);
  const [emailAux, setEmailAux] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailMessageError, setEmailMessageError] = useState("");

  const { handleSubmit, control, errors, watch, setError, setValue } = useForm({
    resolver: yupResolver(schemaSignUp),
    mode: "onTouched",
    defaultValues: {
      name: data?.name ?? DEFAULT_NAME,
      email: data?.email ?? DEFAULT_USER,
      phone: data?.phone ?? DEFAULT_PHONE,
      password: DEFAULT_PASSWORD,
      password_confirmation: DEFAULT_PASSWORD,
      accept_terms: data?.accept_terms,
      acceptToReceiveEmails: data?.acceptToReceiveEmails,
    },
  });

  const watchForm = watch(["password", "password_confirmation", "accept_terms", "email"]);

  const { password, password_confirmation, accept_terms, email } = watchForm;
  const rulesMemorized = useMemo(
    () => ({
      minLength: PasswordRules.RulesTest.minLength(password),
      alphanumeric: PasswordRules.RulesTest.alphanumeric(password),
      capitalLetter: PasswordRules.RulesTest.capitalLetter(password),
      specialCharacters: PasswordRules.RulesTest.specialCharacters(password),
      isDiff: password !== password_confirmation,
    }),
    [password, password_confirmation]
  );

  const isValidatedEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const togglePasswordIconCallback = useCallback(
    () => setPasswordIcon((prev) => (prev === "eye" ? "eyeClosed" : "eye")),
    [setPasswordIcon]
  );
  const togglePasswordConfirmationIconCallback = useCallback(
    () => setPasswordConfirmationIcon((prev) => (prev === "eye" ? "eyeClosed" : "eye")),
    [setPasswordConfirmationIcon]
  );

   const onRequestCodeCallback = useCallback(
    debounce(async (value: string) => {
      setIsCheckingEmail(true);

      try {
        await api.users.emailCheck({ email: value });
      } catch (e) {
        const {
          //@ts-ignore
          response: { data },
        } = e;
        const params = data?.params;
        setError("email", {
          type: "validateEmail",
          message: `${data.response.message_translated ?? t("error.already-exists")}`,
        });
      } finally {
        setIsCheckingEmail(false);
      }
    }, 700),
    []
  );

  useEffect(() => {
    if (email && isValidatedEmail(email)) {
      onRequestCodeCallback(email);
    }
  }, [email, onRequestCodeCallback]);

  useEffect(() => {
    if (submitErrors)
      submitErrors.forEach((err) => {
        if (!errors[err.name]) setError(err.name, err.errorOption);
      });
  }, [submitErrors, errors]);

  const isLoading = loading === "loading";

  const handleDocumentValidation = useCallback(
    debounce((value: string) => {
      if (value === "") {
        setHasErrorModalRequest(undefined);
        return;
      }
      if (personalDocumentType === "cpf" && !validateCPF(value)) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
      } else if (personalDocumentType === "nif" && !validateNIF(value)) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
      } else if (personalDocumentType === "rut" && value.length < 7) {
        setHasErrorModalRequest({
          type: "document-format-invalid",
          message: t("v3:errors.socialCurriculum.document.document-invalid", {
            document: personalDocumentType.toUpperCase(),
          }),
        });
      } else {
        setHasErrorModalRequest(undefined);
      }
    }, 1000),
    []
  );

  const onSubmitValues = async (values: SignUpFormData) => {
    const usr = cookies.user.get() as Login;
    await cookies.user.set({
      ...usr,
      has_phone: true,
    });
    const newData = { ...values, document: uniqueDocument.value };
    if (setDocumentAux) setDocumentAux(newData.document);
    onSubmit(newData);
  };

  useEffect(() => {
    if (subscribeFlux && setSubscribeDocumentAux) setSubscribeDocumentAux(true);
  }, []);

  return (
    <>
      <ModalBodyRegister>
        <Info marginBottom="md">
          <Text size="xl" tag="h1" color="neutralBase">
            {t("plain:Crie sua conta")}
          </Text>
          <Text size="sm" color="neutralLight">
            <Trans
              t={t}
              ns="plain"
              i18nKey="Insira seus dados para continuar ou faça login"
              components={[<CustomLink tag="button" onClick={toggleStep} />]}
            />
          </Text>
        </Info>
        <Form onSubmit={handleSubmit(onSubmitValues)}>
          <InputController
            tag={FormGroup}
            control={control}
            type="text"
            name="name"
            label={t("plain:Nome Placeholder")}
            placeholder={t("plain:Digite seu nome completo")}
            invalid={!!errors.name}
            message={errors?.name?.message && t(errors.name.message)}
            defaultValue=""
            disabled={isLoading}
          />
          {subscribeFlux && (
            <Input
              type="tel"
              autoFocus
              mask={personalDocument}
              maskChar={null}
              label={personalDocumentType.toUpperCase()}
              placeholder={t("plain:Documento pessoal placeholder")}
              error={hasErrorModalRequest?.message ?? undefined}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e?.target || { value: "" };
                handleDocumentValidation(value);
                setUniqueDocument({ type: personalDocumentType, value: value });
                if (hasErrorModalRequest) setHasErrorModalRequest({});
              }}
            />
          )}
          <InputControllerWrapper>
            <InputController
              tag={FormGroup}
              control={control}
              type="email"
              name="email"
              label={t("plain:Email")}
              placeholder={t("plain:Email Placeholder")}
              invalid={!!errors.email}
              message={errors?.email?.message && t(errors.email.message)}
              defaultValue=""
              disabled={isLoading}
            />
            {isCheckingEmail && <CustomSpinner />}
          </InputControllerWrapper>
          <Controller
            control={control}
            name="phone"
            render={({ onChange, value, ...props }) => (
              <InputPhone
                {...props}
                value={value}
                onPhoneChange={(value) => {
                  onChange(value);
                }}
                placeholder={t("plain:Digite")}
                invalid={!!errors.phone}
                message={errors?.phone?.message && t(errors.phone.message)}
                disabled={isLoading}
              />
            )}
          />
          <FlexPassword>
            <InputController
              tag={FormGroup}
              control={control}
              type={passwordIcon === "eyeClosed" ? "password" : "text"}
              name="password"
              label={t("plain:Senha")}
              placeholder={t("plain:Digite")}
              invalid={!!errors?.password}
              message={errors?.password?.message && t(errors.password.message)}
              icon={passwordIcon}
              onClickIcon={togglePasswordIconCallback}
              defaultValue=""
              disabled={isLoading}
            />
            <InputController
              tag={FormGroup}
              control={control}
              type={passwordConfirmationIcon === "eyeClosed" ? "password" : "text"}
              name="password_confirmation"
              label={t("plain:Repetir Senha")}
              placeholder={t("plain:Digite")}
              invalid={!!errors?.password_confirmation}
              message={errors?.password_confirmation?.message && t(errors.password_confirmation.message)}
              icon={passwordConfirmationIcon}
              onClickIcon={togglePasswordConfirmationIconCallback}
              defaultValue=""
              disabled={isLoading}
            />
          </FlexPassword>
          <Row>
            <Col>
              <PasswordRules.Rules.MinLength isOk={rulesMemorized.minLength} t={t} />
              <PasswordRules.Rules.Alphanumeric isOk={rulesMemorized.alphanumeric} t={t} />
            </Col>
            <Col>
              <PasswordRules.Rules.CapitalLetter isOk={rulesMemorized.capitalLetter} t={t} />
              <PasswordRules.Rules.SpecialCharacters isOk={rulesMemorized.specialCharacters} t={t} />
            </Col>
          </Row>
          <TermsContainer>
            <Controller
              control={control}
              name="accept_terms"
              defaultValue={false}
              render={({ onChange, ref, ...props }) => (
                <Checkbox
                  {...props}
                  onValueChange={onChange}
                  id="acceptTerms"
                  checked={accept_terms}
                  innerRef={ref}
                  disabled={isLoading}
                  label={
                    <Trans
                      t={t}
                      ns="plain"
                      i18nKey="Li e concordo com os Termos de Uso e sou maior de 18 anos"
                      components={[<CustomLink tag="button" onClick={() => setTermsIsOpen(true)} />]}
                    />
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="acceptToReceiveEmails"
              defaultValue={false}
              render={({ onChange, ref, ...props }) => (
                <Checkbox
                  {...props}
                  onValueChange={onChange}
                  id="acceptToReceiveEmails"
                  label={t("plain:Desejo receber emails e informações sobre a plataforma")}
                  innerRef={ref}
                  disabled={isLoading}
                />
              )}
            />
          </TermsContainer>
          <Button
            color="primary"
            size="md"
            disabled={
              !password ||
              rulesMemorized.isDiff ||
              !accept_terms ||
              !!Object.keys(errors).length ||
              isCheckingEmail ||
              isLoading
            }
          >
            {t("plain:Efetuar Cadastro")}
          </Button>
        </Form>
      </ModalBodyRegister>
      <ModalTerms
        isOpen={termsIsOpen}
        onClose={() => setTermsIsOpen(false)}
        onConfirm={() => {
          setValue("accept_terms", true, { shouldValidate: true });
          setTermsIsOpen(false);
        }}
      />
    </>
  );
};

export default SignUpStep;

const Flex = styled.div`
  display: flex;
`;

const FlexDDD = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
  & > div {
    display: flex;
    & > div {
      margin-bottom: 0;
    }
  }
`;

const DDDFormGroup = styled(FormGroup)`
  max-width: 100px;
  margin-right: ${({ theme }) => theme.v3.spacing.sm};
`;

const FlexPassword = styled(Flex)`
  ${({ theme }) => css`
    margin-left: calc((${theme.v3.spacing.xxs} + ${theme.v3.spacing.xs}) - ${theme.v3.spacing.md});
    margin-right: calc((${theme.v3.spacing.xxs} + ${theme.v3.spacing.xs}) - ${theme.v3.spacing.md});
    ${FormGroup} {
      margin-left: calc(${theme.v3.spacing.xxs} + ${theme.v3.spacing.xs});
      margin-right: calc(${theme.v3.spacing.xxs} + ${theme.v3.spacing.xs});
    }
  `};
`;

const Row = styled.div`
  display: flex;
  margin: 0 -20px;
  padding-bottom: ${({ theme }) => theme.v3.spacing.md};
`;

const TermsContainer = styled.div`
  padding-top: ${({ theme }) => theme.v3.spacing.md};
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  & > div {
    margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
  }
  & ~ button {
    ${({ theme }) =>
      css`
        margin-top: calc(${theme.v3.spacing.sm} + ${theme.v3.spacing.xxs});
      `}
  }
`;

const InputControllerWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
  position: relative;
  ${FormGroup} {
    margin-bottom: 0;
  }
`;

const CustomSpinner = styled(Spinner)(
  ({ theme }) => css`
    position: absolute;
    top: 26px;
    right: ${theme.v3.spacing.sm};
  `
);
