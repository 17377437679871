import React, { useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { darken, transparentize } from "polished";
import { BaseTitle } from "components/common/BaseTitle";
import { LargeButton } from "wizard/components/common/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { Helmet } from "react-helmet";

interface MatchmakerIntroductionPageProps {
  to?: string;
}

const MatchmakerIntroductionPage = ({ to }: MatchmakerIntroductionPageProps): JSX.Element => {
  const { t } = useTranslation();
  const refAnchor = useRef<HTMLAnchorElement>();

  useEffect(() => {
    if (refAnchor.current) refAnchor.current.focus();
  }, []);

  return (
    <Main>
      <Helmet title={t("wizard.pages.matchmaker.title")} />
      <BaseTitle size="lg">{t("wizard.pages.matchmaker.introduction")}</BaseTitle>
      <LargeButton innerRef={refAnchor} tag={Link} to={to || routes.wizard.matchmaker.name} outline>
        {t("common.start")}
      </LargeButton>
    </Main>
  );
};

export default MatchmakerIntroductionPage;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};

  h1 {
    margin-top: 30px;
    text-align: center;
    max-width: 820px;
    color: #fff;
  }

  a {
    background-color: #fff;
    margin-top: 65px;
    transition: 0.3s;

    :hover {
      color: #fff;
      background-color: ${({ theme }) => transparentize(0.8, darken(0.3, theme.colors.secondary))};
    }
  }
`;
