import React from "react";
import { useTranslation } from "react-i18next";
import { PageEvents, PhotoFile } from "../definitions/commonTypes";
import SimpleImageCoverPage from "wizard/pages/common/SimpleImageCoverPage";

import { CropInfo } from "components/Cropper";

interface InputCoverImageProps extends PageEvents<PhotoFile | undefined> {
  file?: PhotoFile;
  onCropComplete?: (cropInfo: CropInfo) => void;
}

const InputCoverImagePage = ({ file, ...props }: InputCoverImageProps): JSX.Element => {
  const { t } = useTranslation();
  const title = t("plain:Sua imagem de capa");

  return <SimpleImageCoverPage file={file} title={title} {...props} />;
};

export default InputCoverImagePage;
