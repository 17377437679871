import routeToError from "core/validators/routeToError";
import vacancyValidation from "core/validators/vacancy";
import { updateCoverImage, listByAction } from "core/wizard/create/subscribe";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import { useReduxSelector } from "hooks/useReduxSelector";
import { reverse } from "named-urls";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import routes from "routes";
import { goto } from "utils/router";
import { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import Wizard from "wizard/components/Layout/Wizard";
import useValidation from "wizard/hooks/useValidation";
import SimpleInputCepPage from "../common/SimpleInputCepPage";
import { PhotoFile } from "../definitions/commonTypes";
import InputDescriptionVacancyPage from "./InputDescriptionVacancyPage";
import InputVacancyDatePage from "./InputVacancyDatePage";
import InputVacancyHourPage from "./InputVacancyHourPage";
import { Vacancy } from "./ListVacancyPage";
import SelectVacancyCoverImage from "./SelectVacancyCoverImage";
import SelectVacancyTypePage, { VacancyType as SubscribeType } from "./SelectVacancyTypePage";
import VacancyInfoPage, { VacancyInfo } from "./VacancyInfoPage";
import { SubscribeHour } from "components/SubscribeHours/SubscribeHours";
import { formatDateTimeForAPI } from "utils/date";
import { storeNewSubscribe } from "core/wizard/create/subscribe";
import DoingWork from "components/Progress/DoingWork";
import WizardDoingWorkText from "wizard/components/DoingWork/Text";

import { showAlert } from "core/alerts/actions";
import { isEmpty } from "lodash";
import ContinueRegistrationPage, { continuePageMessage } from "../common/ContinueRegistrationPage";
import { isAllPropsEmpty, storageGet } from "utils/helpers";
import { useUser } from "hooks/useUser";
import { LocationDescriptor } from "history";
import { getActionDetails } from "core/wizard/create/action";
import RetryOrSkipErrorPage from "../Error/RetryOrSkip";
import { useDispatch } from "react-redux";
import useTranslationV3 from "hooks/useTranslationV3";

export const WIZARD_CREATE_VACANCY = "creating.vacancy";

type VacancyPageData = {
  vacancies?: Vacancy[];
  type?: SubscribeType;
  info?: VacancyInfo;
  coverImage?: PhotoFile;
  description?: string;
  location?: LocationWithAddress;
  dates?: string[];
  hours?: SubscribeHour[];
  actionAddress?: boolean;
};

const defaultLocalStorageValue = (): VacancyPageData => ({});

interface CreateVacancyPageProps {
  onBack?: LocationDescriptor<any> | (() => void);
  onContinue?: string | (() => void);
}

const CreateVacancyPage = ({ onBack, onContinue }: CreateVacancyPageProps) => {
  const { t } = useTranslationV3();
  const { user } = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state as any;

  const data = useLocalStorageRead(WIZARD_CREATE_VACANCY, defaultLocalStorageValue)!;

  const [creatingSubscribe, setCreatingSubscribe] = useState(false);
  const { pathname } = useLocation();
  const url = pathname;

  const { actionSlug, projectSlug } = useParams<{ actionSlug: string; projectSlug: string }>();

  const [vacancies, setVacancies] = useState<Vacancy[] | undefined>(data.vacancies);

  const [subscribeType, setSubscribeType] = useState<SubscribeType | undefined>(data?.type);
  const [info, setInfo] = useState<VacancyInfo | undefined>(data?.info);
  const [description, setDescription] = useState(data?.description);
  const [location, setLocation] = useState<LocationWithAddress | undefined>(data?.location);
  const [dates, setDates] = useState<Date[] | undefined>(data?.dates?.map((d) => new Date(d)));
  const [hours, setHours] = useState<SubscribeHour[] | undefined>(data?.hours);
  const [actionAddress, setActionAddress] = useState(data?.actionAddress);

  const coverImage = useReduxSelector((state) => state.wizard.create.subscribe.coverImage);

  const { action: actionData, loading } = useReduxSelector((state) => ({
    action: state.wizard.create.action.action.response,
    loading: state.wizard.create.action.action.loading,
  }));

  const [enableRetry, setEnableRetry] = useState(loading === "error");

  useEffect(() => {
    if (!enableRetry) {
      (async () => {
        const resp: any = await dispatch(getActionDetails({ action: actionSlug }));
        if (getActionDetails.rejected.match(resp) && resp.error.name !== "ConditionError") {
          setEnableRetry(true);
        }
      })();
    }
  }, [actionSlug, enableRetry]);

  const setCoverImage = useCallback(
    (coverImage: PhotoFile) => {
      dispatch(updateCoverImage(coverImage));
    },
    [dispatch]
  );

  const isRemote = subscribeType === "remote",
    remoteOffset = isRemote ? -1 : 0;

  const currentStep = useMemo(() => {
    if (
      matchPath(url, {
        path: routes.wizard.project.action.vacancy.toString(),
        exact: true,
      })
    )
      return 0;
    if (
      matchPath(url, routes.wizard.project.action.vacancy.new.selectType) ||
      matchPath(url, { path: routes.wizard.project.action.vacancy.new.toString(), exact: true })
    )
      return 1;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.info)) return 2;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.details)) return 3;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.address)) return 4;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.cover)) return 5 + remoteOffset;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.date)) return 6 + remoteOffset;
    if (matchPath(url, routes.wizard.project.action.vacancy.new.hour)) return 7 + remoteOffset;
    return 0;
  }, [url, remoteOffset]);

  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    title: false,
    description: false,
    function_id: false,
    subscribeType: false,
    "address.city": false,
    "address.state": false,
    "address.country": false,
    "address.coordinates": false,
    "conditionals.interests": false,
    "time_configurations.times": false,
    "images_data.cover_original": false,
    "time_configurations.weekdays": false,
    "time_configurations.quantity": false,
  });

  const { errors, failed: vacancyHasError, assignErrorsToOriginal, errorsOriginal, setErrors } = useValidation(
    {
      title: info?.title,
      action_address: actionAddress,
      description,
      function_id: info?.function,
      address: {
        ...location,
        coordinates: (location?.coordinates || []).filter((cod) => cod),
      },
      images_data: {
        cover_original: coverImage?.cropped,
      },
      time_configurations: {
        times: hours,
        quantity: info?.amount,
        weekdays: (dates || []).map((weekday) => ({ day: weekday })),
      },
      time_accept_remote: subscribeType === "remote",
      conditionals: {
        interests: [info?.ability],
      },
      subscribeType,
    },
    actionAddress ? vacancyValidation.rules.create : vacancyValidation.rulesWithAddress.create,
    touched,
    [info, subscribeType, description, location, coverImage, hours, dates, actionAddress]
  );

  const vacancyTypePageOnContinueCallback = useCallback(
    (type: SubscribeType) => {
      setSubscribeType(type);
      goto(
        history,
        reverse(routes.wizard.project.action.vacancy.new.info, { actionSlug, projectSlug }),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, locationState]
  );

  const vacancyInfoPageOnContinueCallback = useCallback(
    (info) => {
      setInfo(info);
      goto(
        history,
        reverse(routes.wizard.project.action.vacancy.new.details, { actionSlug, projectSlug }),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, locationState]
  );

  const descriptionVacancyPageOnContinueCallback = useCallback(
    (description: string) => {
      setDescription(description);
      goto(
        history,
        reverse(
          isRemote ? routes.wizard.project.action.vacancy.new.cover : routes.wizard.project.action.vacancy.new.address,
          {
            actionSlug,
            projectSlug,
          }
        ),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, isRemote, locationState]
  );

  const inputAddressOnContinueCallback = useCallback(
    (address) => {
      setLocation(address);
      goto(
        history,
        reverse(routes.wizard.project.action.vacancy.new.cover, { actionSlug, projectSlug }),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, locationState]
  );

  const coverImageOnContinueCallback = useCallback(
    (cover: PhotoFile | undefined) => {
      setCoverImage(cover || { cropped: "", source: "" });
      goto(
        history,
        reverse(routes.wizard.project.action.vacancy.new.date, { actionSlug, projectSlug }),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, locationState]
  );

  const vacancyDatePageOnContinueCallback = useCallback(
    (dates: Date[]) => {
      setDates(dates);
      goto(
        history,
        reverse(routes.wizard.project.action.vacancy.new.hour, { actionSlug, projectSlug }),
        locationState
      )();
    },
    [history, actionSlug, projectSlug, locationState]
  );

  /** Continue page Vacancy */
  const vacancyRuleMemorized = useMemo(() => {
    const vacancyData = storageGet(WIZARD_CREATE_VACANCY);
    return !!vacancyData && !isAllPropsEmpty(vacancyData);
  }, []);

  const vacancyOnContinue = useCallback(() => {
    if (!user) return history.replace(routes.wizard.toString(), locationState);
    history.replace(
      reverse(routes.wizard.project.action.vacancy.new.selectType, {
        projectSlug,
        actionSlug,
      }),
      locationState
    );
  }, [user, projectSlug, actionSlug, history, locationState]);

  const vacancyOnUnregister = useCallback(() => {
    localStorage.removeItem(WIZARD_CREATE_VACANCY);
    if (!user) return history.replace(routes.wizard.toString());
    // Reset states
    setSubscribeType(undefined);
    setInfo(undefined);
    setDescription(undefined);
    setLocation(undefined);
    setDates([]);
    setHours([]);

    history.replace(
      reverse(routes.wizard.project.action.vacancy.new.selectType, {
        projectSlug,
        actionSlug,
      })
    );
  }, [history, user, projectSlug, actionSlug]);

  const vacancyHourPageOnContinueCallback = useCallback(async () => {
    if (vacancyHasError) {
      console.log(errorsOriginal);
      const r = routeToError(errorsOriginal!, vacancyValidation.routes.create);
      if (r) {
        assignErrorsToOriginal();
        return history.push(reverse(r, { projectSlug, actionSlug }));
      }
      return;
    }

    setCreatingSubscribe(true);
    try {
      const response: any = await dispatch(
        storeNewSubscribe({
          action: actionSlug,

          title: info!.title,
          action_address: subscribeType === "remote" ? false : !!actionAddress,
          description: description || "",
          function_id: info!.function,
          images_data: {
            cover_original: coverImage?.cropped || "",
            // cover_description: "Descrição", // TODO(Jota): To uncomment this for accessibility.
          },
          address: isRemote || actionAddress ? undefined : location,
          time_accept_remote: subscribeType === "remote",
          time_configurations: {
            times: (hours || []).map(({ start, end }) => ({
              start: start!,
              end: end!,
            })),
            quantity: info!.amount,
            weekdays: (dates || []).map((d) => ({
              day: formatDateTimeForAPI(d),
            })),
          },
          conditionals: {
            interests: [info!.ability!],
          },
        })
      );

      if (storeNewSubscribe.fulfilled.match(response)) {
        // Reset states
        setSubscribeType(undefined);
        setInfo(undefined);
        setDescription(undefined);
        setLocation(undefined);
        setDates(undefined);
        setHours(undefined);

        await dispatch(
          listByAction({
            action: actionSlug,
            project: projectSlug,
            force: true,
          })
        );

        setTimeout(() => {
          if (locationState?.returnTo) {
            history.push(locationState?.returnTo);
          } else if (typeof onContinue === "string") {
            goto(history, onContinue)();
          } else if (typeof onContinue === "function") {
            onContinue();
          }
        }, 50);
      } else {
        dispatch(showAlert("danger", t("wizard.stepsWizard.createVacancy.error.default")));
        setErrors(response.payload?.params);
        const r = routeToError(
          isEmpty(response.payload?.params)
            ? { [response.payload?.response?.message]: "error" }
            : response.payload?.params,
          vacancyValidation.routes.create
        );
        if (r) {
          history.push(reverse(r, { projectSlug, actionSlug }));
        }
      }
    } catch (e) {
      console.error(e);
      // TODO(Jota): Report the error.
    } finally {
      setCreatingSubscribe(false);
    }
  }, [
    t,
    history,
    vacancies,
    actionSlug,
    projectSlug,
    subscribeType,
    info,
    description,
    location,
    dates,
    coverImage,
    hours,
    vacancyHasError,
    errorsOriginal,
    assignErrorsToOriginal,
    isRemote,
    locationState,
    actionAddress,
  ]);

  useLocalStorageWrite<VacancyPageData>(
    {
      vacancies,
      type: subscribeType,
      info,
      description,
      location,
      dates: dates ? dates.map((d) => d.toString()) : undefined,
      hours,
      actionAddress,
    },
    WIZARD_CREATE_VACANCY
  );

  return (
    <Wizard
      header={{
        title: t("wizard.stepsWizard.createVacancy.header.title"),
        stepCurrent: currentStep,
        stepMax: 7 + remoteOffset,
      }}
    >
      <DoingWork isOpen={creatingSubscribe}>
        <WizardDoingWorkText>{t("wizard.pages.createVacancy.creating.text")}</WizardDoingWorkText>
      </DoingWork>
      {loading === "ok" && (
        <Switch>
          <Route path={routes.wizard.project.action.vacancy.new.continue}>
            <ContinueRegistrationPage
              message={continuePageMessage("vacancy", t)}
              rule={vacancyRuleMemorized}
              onContinue={vacancyOnContinue}
              onUnregister={vacancyOnUnregister}
            />
          </Route>
          <Route
            path={[
              routes.wizard.project.action.vacancy.new.toString(),
              routes.wizard.project.action.vacancy.new.selectType,
            ]}
            exact
          >
            <SelectVacancyTypePage
              vacancyType={subscribeType}
              onBack={onBack}
              onContinue={vacancyTypePageOnContinueCallback}
              onChange={(type) => {
                setSubscribeType(type);
                if (!touched.subscribeType) setTouched((prev) => ({ ...prev, subscribeType: true }));
              }}
              error={errors?.subscribeType}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.info}>
            <VacancyInfoPage
              vacancyInfo={info}
              onBack={reverse(routes.wizard.project.action.vacancy.new.selectType, { actionSlug, projectSlug })}
              onContinue={vacancyInfoPageOnContinueCallback}
              onChange={(info) => {
                setInfo(info);
                if (info.title && !touched.title) setTouched((prev) => ({ ...prev, title: true }));
                if (info.function && !touched.function_id) setTouched((prev) => ({ ...prev, function_id: true }));
                if (info.ability && !touched["conditionals.interests"])
                  setTouched((prev) => ({ ...prev, ["conditionals.interests"]: true }));
                if (info.amount && !touched["time_configurations.quantity"])
                  setTouched((prev) => ({ ...prev, ["time_configurations.quantity"]: true }));
              }}
              error={errors}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.details}>
            <InputDescriptionVacancyPage
              description={description}
              onBack={reverse(routes.wizard.project.action.vacancy.new.info, { actionSlug, projectSlug })}
              onContinue={descriptionVacancyPageOnContinueCallback}
              onChange={(description) => {
                setDescription(description);
                if (!touched.description) setTouched((prev) => ({ ...prev, description: true }));
              }}
              error={errors?.description}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.address}>
            <SimpleInputCepPage
              value={location}
              title="Informe o endereço ou local de encontro"
              placeholder={t("plain:Digite um endereço ou CEP")}
              onBack={reverse(routes.wizard.project.action.vacancy.new.details, { actionSlug, projectSlug })}
              onContinue={inputAddressOnContinueCallback}
              onChange={(address) => {
                setLocation(address);
                if (
                  !touched["address.city"] ||
                  !touched["address.state"] ||
                  !touched["address.country"] ||
                  !touched["address.coordinates"]
                )
                  setTouched((prev) => ({
                    ...prev,
                    ["address.city"]: true,
                    ["address.state"]: true,
                    ["address.country"]: true,
                    ["address.coordinates"]: true,
                  }));
              }}
              withChecked
              checked={actionAddress}
              onChecked={setActionAddress}
              error={
                errors?.["address.city"] ||
                errors?.["address.state"] ||
                errors?.["address.country"] ||
                errors?.["address.coordinates"]
              }
              continueDisabled={!actionAddress && !location?.city}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.cover}>
            <SelectVacancyCoverImage
              file={coverImage}
              onBack={reverse(
                isRemote
                  ? routes.wizard.project.action.vacancy.new.details
                  : routes.wizard.project.action.vacancy.new.address,
                {
                  actionSlug,
                  projectSlug,
                }
              )}
              onContinue={coverImageOnContinueCallback}
              onChange={(cover) => {
                setCoverImage(cover || { cropped: "", source: "" });
                if (!touched["images_data.cover_original"])
                  setTouched((prev) => ({ ...prev, ["images_data.cover_original"]: true }));
              }}
              error={errors?.["images_data.cover_original"]}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.date}>
            <InputVacancyDatePage
              dates={dates || []}
              onBack={reverse(routes.wizard.project.action.vacancy.new.cover, { actionSlug, projectSlug })}
              actionDateEnd={
                actionData?.time_configurations ? new Date(actionData?.time_configurations.date_end) : undefined
              }
              actionDateStart={
                actionData?.time_configurations ? new Date(actionData?.time_configurations.date_start) : undefined
              }
              onContinue={vacancyDatePageOnContinueCallback}
              onChange={(dates) => {
                setDates(dates);
                if (!touched["time_configurations.weekdays"])
                  setTouched((prev) => ({ ...prev, ["time_configurations.weekdays"]: true }));
              }}
              error={errors?.["time_configurations.weekdays"]}
            />
          </Route>
          <Route path={routes.wizard.project.action.vacancy.new.hour}>
            <InputVacancyHourPage
              hours={hours || []}
              onBack={reverse(routes.wizard.project.action.vacancy.new.date, { actionSlug, projectSlug })}
              onContinue={vacancyHourPageOnContinueCallback}
              onChange={(hours) => {
                setHours(hours);
                if (!touched["time_configurations.times"])
                  setTouched((prev) => ({ ...prev, ["time_configurations.times"]: true }));
              }}
              isLoading={creatingSubscribe}
              error={errors?.["time_configurations.times"]}
              continueLabel={creatingSubscribe ? t("wizard.stepsWizard.createVacancy.actions.loading") : ""}
            />
          </Route>
        </Switch>
      )}
      {loading === "error" && (
        <RetryOrSkipErrorPage
          message={t("plain:Não foi possível carregar as informações da ação")}
          onRetry={() => setEnableRetry(false)}
        ></RetryOrSkipErrorPage>
      )}
    </Wizard>
  );
};

export default CreateVacancyPage;
