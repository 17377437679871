import React from "react";
import LinkBase, { LinkBaseProps } from "./LinkBase";
import styled, { css } from "styled-components";
import { rem } from "polished";

interface ActionLinkPrpops extends LinkBaseProps {}

const ActionLinkComp: React.FC<ActionLinkPrpops> = (props) => <ActionLinkStyled {...props} />;

const ActionLink = styled(ActionLinkComp)``;

export default ActionLink;

const ActionLinkStyled = styled(LinkBase)`
  line-height: ${rem(20)};
`;
