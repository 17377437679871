import { rem } from "polished";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { parsePhoneNumber, PhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import { InputStyle, InputWrapper } from "wizard/components/Input/InputLabeled";
import "react-phone-number-input/style.css";
import { ValidationError, ValidationErrorSet } from "core/api/definitions";
import { translateValidationError } from "../WizardTrans/WizardTrans";

interface InputPhoneProps {
  value: string;
  placeholder?: string;
  error?: string | ValidationError;
  defaultCountry?: string;
  onCountryChange?(countryCode?: string): void;
  onPhoneChange(value: PhoneNumber | undefined): void;
}

/** @deprecated */
const InputPhone = ({
  value,
  placeholder,
  onPhoneChange,
  onCountryChange,
  defaultCountry,
  ...props
}: InputPhoneProps) => {
  const { t } = useTranslation();

  const onPhoneChangeCallback = useCallback(
    (value: string) => {
      if (typeof value === "string") return onPhoneChange(parsePhoneNumber(value));
      onPhoneChange(value);
    },
    [onPhoneChange]
  );

  return (
    <InputWrapperStyled {...props} label={t("wizard.pages.accessData.form.phone.label")} type="tel">
      <PhoneInputStyled
        value={value}
        onChange={onPhoneChangeCallback}
        placeholder={placeholder}
        defaultCountry={defaultCountry?.toUpperCase()}
        onCountryChange={onCountryChange}
        international
        limitMaxLength
        error={props.error && translateValidationError(props.error)}
      />
    </InputWrapperStyled>
  );
};

export default InputPhone;

const InputWrapperStyled = styled(InputWrapper)``;

const PhoneInputStyled = styled(PhoneInput)`
  position: relative;

  .PhoneInputCountry {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 22px;
    margin: auto 0;
  }

  .PhoneInputInput {
    ${InputStyle}
    width: 100%;
    padding-left: 65px;
    border-radius: ${rem(4)};

    :focus {
      outline: none;
    }
  }
`;
