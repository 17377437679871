import { BaseTitle } from "components/common/BaseTitle";
import { TrackHorizontal, TrackVertical } from "components/common/style";
import TermsOfUse from "components/TermsOfUse/TermsOfUse";
import { rem } from "polished";
import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";
import { shadow } from "wizard/components/common/styles";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { PageEvents } from "wizard/pages/definitions/commonTypes";

export type Terms = "accepted" | "rejected";

interface TermsPageProps extends Omit<PageEvents<Terms>, "onChange"> {}

/** @deprecated */
const TermsPage: React.FC<TermsPageProps> = ({ onBack, onContinue, error }) => {
  const { t } = useTranslation();

  return (
    <>
      <WizardContainer>
        <Terms>
          <Scrollbars
            renderTrackVertical={({ style }) =>
              !(style.display && style.display === "none") ? <CustomTrackVertical top={0} right={35} /> : <></>
            }
            renderThumbHorizontal={({ style }) =>
              !(style.display && style.display === "none") ? <TrackHorizontal hidden /> : <></>
            }
          >
            <Section tabIndex={0}>
              <CustomBaseTitle size="md">{t("pages.terms.title")}</CustomBaseTitle>
              <TermsOfUse />
            </Section>
          </Scrollbars>
        </Terms>
      </WizardContainer>
      <ActionsFooter
        backLabel="Não concordo com esses Termos"
        backIcon={false}
        onBack={onBack}
        continueLabel="Concordo com os termos"
        onContinue={() => {
          if (onContinue) onContinue("accepted");
        }}
      />
    </>
  );
};

export default TermsPage;

const CustomBaseTitle = styled(BaseTitle)`
  margin-bottom: 20px;

  ${mediaQueries.xs(css`
    font-size: ${rem(20)};
    line-height: ${rem(26)};
  `)}
`;

export const TitleTermsCss = css`
  font-size: ${rem(18)};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.secondary_700};
`;

export const TextTermsCss = css`
  font-size: ${rem(14)};
  line-height: ${rem(30)};
  margin-bottom: ${rem(15)};
  color: ${({ theme }) => theme.colors.support_text};
  text-align: left;
`;

const Section = styled.section`
  padding-bottom: 15px;
  h2 {
    ${TitleTermsCss};
  }
  p {
    ${TextTermsCss};
  }
`;

const Terms = styled.section`
  position: relative;
  align-self: center;
  width: 100%;
  height: 450px;
  border: 1px solid #9facbd;
  border-radius: 6px;
  background-color: #ffffff;
  overflow-y: auto;
  ${shadow()};

  ${Section} {
    padding: 45px 140px 30px;

    ${mediaQueries.sm(css`
      padding: 30px 60px 0;
    `)}
    ${mediaQueries.xs(css`
      padding: 15px 20px 0;
    `)}
  }
`;

const CustomTrackVertical = styled(TrackVertical)`
  top: 40px;
  ${mediaQueries.xs(css`
    right: ${rem(8)};
  `)}
`;
