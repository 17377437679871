import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";

export interface AddMaterialItemRequest {
  action: string;
  materialId: string;

  // Data
  title: string | string[];
  quantity: number | number[];
}

export type AddMaterialItemResponse = ResponseWithParams<ResponseStatus, any>;

const addMaterialItem = ({
  action,
  materialId,
  ...data
}: AddMaterialItemRequest): Promise<AxiosResponse<AddMaterialItemResponse>> =>
  http.post<AddMaterialItemResponse>(`/actions/${action}/donors/materials/${materialId}/item`, data);

export default addMaterialItem;
