import React, { useCallback, useMemo } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { reverse } from "named-urls";
import { goto } from "utils/router";
import DonationsListDataPage, { MaterialListInfo } from "./DonationListInfoPage";
import DonationsListDataItem from "./DonationListItemsPage";
import { MaterialItemInfo } from "./DonationListItems";
import { ValidationErrorSet } from "core/api/definitions";
import ContinueRegistrationPage, { continuePageMessage } from "../common/ContinueRegistrationPage";
import { isAllPropsEmpty, storageGet } from "utils/helpers";
import routes from "routes";
import { WIZARD_CREATE_DONATION } from "./CreateDonationPage";
import { useUser } from "hooks/useUser";
import { useTranslation } from "react-i18next";

export interface DonationData {
  info: MaterialListInfo;
  items: MaterialItemInfo[];
}

interface InputDonationPageProps {
  onContinue: (donation: DonationData) => void;
  onBack: string | (() => void);

  info?: MaterialListInfo;
  onChangeInfo?: (info: MaterialListInfo) => void;

  items?: MaterialItemInfo[];
  onAddItem: (info: MaterialItemInfo) => void;
  onChangeItem: (info: MaterialItemInfo, idx: number) => void;
  onRemoveItem: (info: MaterialItemInfo, idx: number) => void;

  routeInfo: string;
  routeItems: string;

  error?: ValidationErrorSet;
  onTouched?(key: string): void;

  loading?: boolean;
  resetForm?(): void;
}

const InputDonationPage = ({
  info,
  onChangeInfo,
  items,
  onAddItem,
  onChangeItem,
  onRemoveItem,
  onContinue,
  onBack,
  routeInfo,
  routeItems,
  error,
  onTouched,
  loading,
  resetForm,
}: InputDonationPageProps) => {
  const history = useHistory();
  const { user } = useUser();
  const { t } = useTranslation();

  const routeParams = useParams();
  const routeState = history.location.state as any;

  const donationListDataPageOnContinueCallback = useCallback(
    (info: MaterialListInfo) => {
      if (onChangeInfo) onChangeInfo(info);
      goto(history, reverse(routeItems, routeParams), routeState)();
    },
    [history, routeParams]
  );

  const onContinueCallback = useCallback(() => {
    if (onContinue)
      onContinue({
        info: info || {},
        items: items || [],
      });
  }, [onContinue, info, items]);

  /** DONATION */
  const donationRuleMemorized = useMemo(() => {
    const donationData = storageGet(WIZARD_CREATE_DONATION);
    return !!donationData && !isAllPropsEmpty(donationData);
  }, []);

  const donationOnContinue = useCallback(() => {
    if (!user) return history.replace(routes.wizard.toString(), routeState);
    history.replace(reverse(routes.wizard.project.action.donation.new.info, routeParams), routeState);
  }, [user, history, routeParams, routeState]);

  const donationOnUnregister = useCallback(() => {
    localStorage.removeItem(WIZARD_CREATE_DONATION);
    if (!user) return history.replace(routes.wizard.toString());
    if (resetForm) resetForm();
    history.replace(reverse(routes.wizard.project.action.donation.new.info, routeParams));
  }, [history, user, routeParams, resetForm]);

  return (
    <Switch>
      <Route path={routes.wizard.project.action.donation.new.continue}>
        <ContinueRegistrationPage
          message={continuePageMessage("donation", t)}
          rule={donationRuleMemorized}
          onContinue={donationOnContinue}
          onUnregister={donationOnUnregister}
        />
      </Route>
      <Route path={routeInfo} exact>
        <DonationsListDataPage
          info={info}
          onChange={(info) => {
            if (onChangeInfo) onChangeInfo(info);
          }}
          onContinue={donationListDataPageOnContinueCallback}
          onBack={onBack}
          error={error}
          onTouched={onTouched}
        />
      </Route>
      <Route path={routeItems}>
        <DonationsListDataItem
          items={items}
          onBack={reverse(routeInfo, routeParams)}
          onAddItem={onAddItem}
          onChangeItem={onChangeItem}
          onRemoveItem={onRemoveItem}
          onContinue={onContinueCallback}
          error={error}
          continueDisabled={loading}
        />
      </Route>
    </Switch>
  );
};

export default InputDonationPage;
