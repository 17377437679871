import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "components/Button/Button";
import Input from "../Input/Input";
import { BaseTitle } from "components/common/BaseTitle";
import InputLabeled from "wizard/components/Input/InputLabeled";
import { rem } from "polished";
import { BorderFocus } from "components/common/style";

interface CenteredComponentProps {
  tag?: "div" | "section";
}

const CenteredComponent: React.FC<CenteredComponentProps & React.HTMLAttributes<HTMLElement>> = ({
  tag: Wrapper = "section",
  ...props
}) => <Wrapper {...props} />;

export const Centered = styled(CenteredComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VerticalAlign = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LargeButton = styled(Button)`
  padding: 12px 0;
  ${({ block }) =>
    !block &&
    css`
      width: 260px;
    `}
`;

export const LargeInput = styled(Input)<{ hasCounter?: boolean }>`
  margin-top: 30px;

  ${({ hasCounter }) =>
    hasCounter &&
    css`
      padding-bottom: 40px;
    `}
`;

export const MainTitle = styled(BaseTitle)`
  padding-top: 70px;
`;

export const CheckedSteps = ({ text, isChecked }: { text: string; isChecked?: boolean }): JSX.Element => {
  return (
    <ContainerChecked isChecked={isChecked}>
      <span>{text}</span>
    </ContainerChecked>
  );
};

export const ContainerChecked = styled.div<{ isChecked?: boolean }>`
  color: #9facbd;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 8px;

  :before,
  :after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    border-radius: 100%;
    border: 1px solid #9facbd;
    box-sizing: border-box;
    background-color: #fff;
  }
  ${({ isChecked }) =>
    isChecked &&
    css`
      color: ${({ theme }) => theme.colors.secondary};

      :before {
        border: none;
        background-color: ${({ theme }) => theme.colors.primary};
      }
      :after {
        height: 7px;
        width: 5px;
        left: 5px;
        top: 7px;
        border-style: solid;
        border-color: #fff;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        background: transparent;
      }
    `}
`;

export const shadow = (shadowPosition?: number) => css`
  ::after {
    position: absolute;
    content: "";
    right: 0;
    left: 0;
    bottom: calc(-${shadowPosition || 0}px);
    height: 82px;
    width: 100%;
    background: linear-gradient(180deg, rgb(255, 255, 255, 0) 0%, #fff 100%);
  }
`;

export const ContainerSection = styled.section`
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputLabeledCodeStyled = styled(InputLabeled)`
  border: 1px solid #cdd6e2;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
  min-height: 70px;
  font-size: ${rem(36)};
  padding: 0;
  text-align: center;
  overflow: hidden;
  font-weight: ${rem(300)};
  letter-spacing: ${rem(10)};
  margin-top: 50px;

  :focus {
    outline: none;
    box-shadow: none;
    ${BorderFocus};
  }

  ::placeholder {
    color: #9facbd;
  }

  + div > svg {
    cursor: pointer;
  }
`;
