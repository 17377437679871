import SupportText from "components/common/SupportText";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MainTitle } from "wizard/components/common/styles";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import FormWizard from "wizard/pages/common/FormWizard";
import InputAccessInfoBase, {
  AccessDataInfoBaseProps,
  AccessInfo,
  AccessInfoPasswords,
} from "../profile/InputAccessInfoBase";
import { InputAccessContainer } from "../profile/InputAccessInfoPage";
import { FormGroup } from "components/register/Form";
import InputLabeled from "wizard/components/Input/InputLabeled";
import { FormFeedback } from "reactstrap";
import { ValidationErrorSet } from "core/api/definitions";

export interface AccessInfoWithName extends AccessInfo {
  name: string;
}

export interface AccessInfoWithPassword {
  info?: AccessInfoWithName;
  passwords?: AccessInfoPasswords;
}

interface InputAccessInfoVolunteerPageProps extends AccessDataInfoBaseProps<AccessInfoWithPassword> {}

const InputAccessInfoVolunteerPage: React.FC<InputAccessInfoVolunteerPageProps> = ({
  formData,
  onContinue,
  onTouched,
  onChange,
  onBack,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = useState<string>();

  const onContinueCallback = useCallback(() => {
    if (onContinue) onContinue(formData ?? {});
  }, [onContinue, formData]);

  const onChangeCallback = useCallback(
    (data) => {
      if (onChange) onChange(data);
    },
    [onChange]
  );

  const onChangeNameCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e?.target;
      if (onChange)
        onChange({
          ...formData,
          info: { ...(formData?.info || { name: "", email: "", phone: { ddd: "", number: "" } }), name: value },
        });
    },
    [onChange, formData]
  );

  const onTouchedCallback = useCallback(
    (touched) => {
      if (onTouched) onTouched(touched);
    },
    [onTouched]
  );

  const onErrorCallback = useCallback((error) => setHasError(error), []);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <InputAccessContainer>
        <Helmet title={t("wizard.pages.accessData.title")} />
        <MainTitle size="lg">{t("wizard.pages.accessData.mainTitle")}</MainTitle>
        <SupportText size="md">{t("wizard.pages.accessData.description")}</SupportText>
        <div>
          <FormGroup>
            <InputLabeled
              name="name"
              type="text"
              label={t("wizard.pages.accessData.form.name.label")}
              placeholder={t("wizard.pages.accessData.form.name.placeholder")}
              value={formData?.info?.name}
              onChange={onChangeNameCallback}
              autoFocus
              required
              error={(error as ValidationErrorSet)?.name}
            />
            <FormFeedback>{hasError}</FormFeedback>
          </FormGroup>
          <InputAccessInfoBase
            formData={formData}
            onChange={onChangeCallback}
            handleError={onErrorCallback}
            onTouched={onTouchedCallback}
            error={error}
          />
        </div>
      </InputAccessContainer>
      <ActionsFooter onBack={onBack} continueDisabled={!!hasError || loading} />
    </FormWizard>
  );
};

export default InputAccessInfoVolunteerPage;
