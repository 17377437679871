import React from "react";
import { PageEvents } from "../definitions/commonTypes";
import InputSelectOption from "../InputSelectOption";
import { functions, functionsChile, Skill } from "pages/ListActions/DataOfFilters";
import { useTranslation } from "react-i18next";
import useCountry from "hooks/useCountry";

interface MatchmakerWorkPageProps extends PageEvents<Skill> {
  value?: Skill;
}

const MatchmakerWorkPage: React.FC<MatchmakerWorkPageProps> = ({ value, ...props }) => {
  const { t } = useTranslation();
  const { iso3166_1 } = useCountry();

  const options = iso3166_1 === "cl" ? functionsChile : functions;

  return (
    <InputSelectOption
      title={t("wizard.stepsWizard.createProfile.header.description").toString()}
      placeholder={t("wizard.stepsWizard.selectAOption.input.title").toString()}
      clue={t("wizard.stepsWizard.chooseCurrentOcupation.text.title").toString()}
      value={value}
      {...props}
    >
      {options.map((f) => (
        <option key={f.value} value={f.value}>
          {f.label}
        </option>
      ))}
    </InputSelectOption>
  );
};

export default MatchmakerWorkPage;
