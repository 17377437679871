import { get } from "core/pages/origin";
import { Dispatch, AnyAction, MiddlewareAPI, Middleware } from "redux";
import { updateActionFeatures, createStoreNewAction, WIZARD_CREATE_ACTION } from "core/wizard/create/action";
import { createStoreNewProject } from "core/wizard/create/project";
import { WIZARD_CREATE_PROJECT } from "wizard/pages/project/CreateProjectPage";
import { WIZARD_LOCAL_CREATE_ACTION } from "wizard/pages/action/CreateActionPage";
import { WIZARD_CREATE_VACANCY } from "wizard/pages/vacancy/CreateVacancyPage";
import { WIZARD_CREATE_MATCHMAKER } from "wizard/pages/matchmaker/MatchmakerPage";
import { storeNewSubscribe } from "core/wizard/create/subscribe";
import { storeNewListMaterial } from "core/wizard/create/donation";
import { createStoreNewUser } from "core/wizard/create/user";
import { WIZARD_CREATE_DONATION } from "wizard/pages/donation/CreateDonationPage";
import { WIZARD_CREATE_VOLUNTEER } from "wizard/pages/volunteer/CreateVolunteerPage";
import { RootState } from "core/state";

const newFlowCreateHandle: Middleware = (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  if (action.type === get.fulfilled.toString()) {
    // Initialize state.
  }

  const rs = next(action);
  try {
    /** ----- Removes ----- */
    if (action.type === createStoreNewUser.fulfilled.toString()) {
      // Removes user data from localStorage if success
      if (action.payload?.success) {
        localStorage.removeItem(WIZARD_CREATE_MATCHMAKER);
        localStorage.removeItem(WIZARD_CREATE_VOLUNTEER);
      }
    }

    if (action.type === createStoreNewProject.fulfilled.toString()) {
      // Removes project data from localStorage if success
      const storedProject = store.getState().wizard.create.project?.project?.response;
      if (storedProject) localStorage.removeItem(WIZARD_CREATE_PROJECT);
    }

    if (action.type === createStoreNewAction.fulfilled.toString()) {
      // Removes action data from localStorage if success
      const storedAction = store.getState().wizard.create.action.action.response;
      if (storedAction) localStorage.removeItem(WIZARD_LOCAL_CREATE_ACTION);
    }

    if (action.type === storeNewSubscribe.fulfilled.toString()) {
      // Removes vacancy data from localStorage if success
      localStorage.removeItem(WIZARD_CREATE_VACANCY);
    }

    if (action.type === storeNewListMaterial.fulfilled.toString()) {
      // Removes donations data from localStorage if success
      localStorage.removeItem(WIZARD_CREATE_DONATION);
    }

    if (action.type === createStoreNewAction.fulfilled.toString()) {
      // Removes action data from localStorage if success
      const storedAction = store.getState().wizard.create.action.action.response;
      if (storedAction) localStorage.removeItem(WIZARD_LOCAL_CREATE_ACTION);
    }

    /** ----- Saves ----- */
    if (action.type === createStoreNewAction.fulfilled.toString()) {
      // Save the action into the localStorage
      const storedAction = store.getState().wizard.create.action.action.response;
      if (storedAction) {
        localStorage.setItem(WIZARD_CREATE_ACTION, JSON.stringify(storedAction));
      } else {
        localStorage.removeItem(WIZARD_CREATE_ACTION);
      }
    }
    if (action.type === updateActionFeatures.toString()) {
      // Save the action into the localStorage
      const storedAction = store.getState().wizard.create.action.action.response;
      if (storedAction) {
        localStorage.setItem(WIZARD_CREATE_ACTION, JSON.stringify(storedAction));
      } else {
        localStorage.removeItem(WIZARD_CREATE_ACTION);
      }
    }
  } catch (e) {
    console.error("newFlowCreateHandle: error handling new flow", e);
  }
  return rs;
};

export default newFlowCreateHandle;
