import React, { Fragment } from "react";
import { PageEvents } from "../definitions/commonTypes";
import { useTranslation } from "react-i18next";
import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { TitleContainer } from "wizard/components/Layout/Content";
import ImageGalleryCropper, { ImageCropper } from "components/common/ImageGalleryCropper";
import { Helmet } from "react-helmet";

interface PicturesPageProps extends PageEvents<ImageCropper[]> {
  images?: ImageCropper[];
  continueDisabled: boolean;
}

const PicturesPage: React.FC<PicturesPageProps> = ({
  images,
  continueDisabled,
  onChange,
  onContinue,
  onBack,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.pictures.title")} />
          <BaseTitle size="md">{t("wizard.pages.pictures.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.pictures.description")}</SupportText>
        </TitleContainer>
        <ImageGalleryCropper
          images={images}
          onChange={onChange}
          error={typeof error === "string" ? error : Array.isArray(error) ? error[0] : ""}
        />
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={continueDisabled}
        onContinue={() => {
          if (onContinue) onContinue(images || []);
        }}
      />
    </Fragment>
  );
};

export default PicturesPage;
