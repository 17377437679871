import React from "react";
import Circle from "components/Icon/Circle";
import Icon, { IconType } from "components/Icon/Icon";
import styled from "styled-components/macro";
import { mq } from "utils/mediaQueries";
import Text from "v3/components/common/Text";

interface ClueProps {
  text: string;
  icon?: IconType;
  [key: string]: any;
}

const Clue: React.FC<ClueProps> = ({ text, icon = "checkout", ...props }) => {
  return (
    <Flex {...props}>
      <Circle>
        <Icon type={icon} />
      </Circle>
      <Text size="sm" color="neutralLight">
        {text}
      </Text>
    </Flex>
  );
};

export default Clue;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.v3.spacing.lg};
  ${mq.mdUp} {
    max-width: 75%;
  }

  svg {
    /** //TODO(Jeconias): Remove color after */
    color: ${({ theme }) => theme.colors.primary};
  }
`;
