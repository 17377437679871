import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";
import layers from "components/Layers";
import { CSSTransition } from "react-transition-group";

interface WorkingProps {
  isOpen?: boolean;
}

const Overlay: React.FC<WorkingProps> = ({ isOpen, children }) => {
  const [container, setContainer] = useState<HTMLDivElement | undefined>();

  useEffect(() => {
    const c = document.createElement("div");
    setContainer(c);
    document.body.appendChild(c);
    return () => {
      c.parentNode?.removeChild(c);
    };
  }, []);

  if (!container) return null;

  return ReactDOM.createPortal(
    <CSSTransition cCSSTransitionlassNames="fade-in" in={isOpen} mountOnEnter unmountOnExit timeout={1000}>
      <Container>{children}</Container>
    </CSSTransition>,
    container
  );
};

export default Overlay;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: ${layers.Overlay};
`;
