import { PayloadAction, unwrapResult } from "@reduxjs/toolkit";
import DoingWork from "components/Progress/DoingWork";
import FuncRoute from "components/Routes/FuncRoute";
import { showAlert } from "core/alerts/actions";
import { ProjectType, ResponseStatus, ResponseWithParams, ValidationErrorSet } from "core/api/definitions";
import {
  StoreNewProjectOrganizationRequest,
  StoreNewProjectPersonalRequest,
  StoreNewProjectResponse,
} from "core/api/projects/storeNewProject";
import projectValidation from "core/validators/project";
import routeToError from "core/validators/routeToError";
import {
  createStoreNewProject,
  updateCoverImage,
  updateDescription,
  updateProfileImage,
} from "core/wizard/create/project";
import { listNewProject } from "core/wizard/list/project";
import useCountry from "hooks/useCountry";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import useProjectType from "hooks/useProjectType";
import { useReduxSelector } from "hooks/useReduxSelector";
import { useUser } from "hooks/useUser";
import { isEmpty } from "lodash";
import { reverse } from "named-urls";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { matchPath, Route, Switch, useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { isAllPropsEmpty, storageGet } from "utils/helpers";
import { goto } from "utils/router";
import WizardDoingWorkText from "wizard/components/DoingWork/Text";
import { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import Wizard from "wizard/components/Layout/Wizard";
import useValidation from "wizard/hooks/useValidation";
import InputProfileImagePage from "wizard/pages/project/InputProfileImagePage";
import InputProjectAddressPage from "wizard/pages/project/InputProjectAddressPage";
import InputProjectDescriptionPage from "wizard/pages/project/InputProjectDescriptionPage";
import InputProjectNamePage from "wizard/pages/project/InputProjectNamePage";
import SelectProjectTypePage from "wizard/pages/project/SelectProjectTypePage";
import ContinueRegistrationPage, { continuePageMessage } from "../common/ContinueRegistrationPage";
import CausesPage, { CauseSet } from "../common/SelectCausesPage";
import { PhotoFile } from "../definitions/commonTypes";
import MatchmakerAbilitiesPage, { AbilitySet } from "../matchmaker/MatchmakerAbilitiesPage";
import MatchmakerWorkPage from "../matchmaker/MatchmakerWorkPage";
import { APIParamsToString } from "utils/helpers";
import useTranslationV3 from "hooks/useTranslationV3";
import { ListProject } from "core/panel/beta/projects/actions";
import SocialNetworksPage, { SocialNetworkData } from "../SocialNetworksPage";

export const WIZARD_CREATE_PROJECT = "creating.project";

export type CreateUserType = (code: string) => Promise<any> | undefined;

export type CreateProjectType = {
  projectType?: ProjectType;
  name?: string;
  description?: string;
  location?: LocationWithAddress;

  // Only for personal projects
  myWork?: Skill;
  abilities?: AbilitySet;
  causes?: CauseSet;
};

const defaultLocalStorageValue = (): CreateProjectType => ({});

const CreateProjectPage = (): JSX.Element => {
  const { t } = useTranslationV3();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const qs = queryString.parse(search);
  const projectTypeQs = typeof qs?.project_type === "string" ? qs?.project_type : undefined;

  // For checking wheter or not the the user is already logged in.
  const { user } = useUser();

  // projectTypes from the origin
  const { projectTypeJoined } = useProjectType();

  const hasOneProjectType = projectTypeJoined.length === 1;

  const projectLocalStorageData = useLocalStorageRead(WIZARD_CREATE_PROJECT, defaultLocalStorageValue)!;

  const { projectProfileImage, projectCoverImage } = useReduxSelector((state) => ({
    projectProfileImage: state.wizard.create.project.profileImage,
    projectCoverImage: state.wizard.create.project.coverImage,
  }));

  /**
   * Setter that saves the profile image into the redux state.
   */
  const setProjectProfileImage = useCallback(
    (image: PhotoFile) => {
      dispatch(updateProfileImage(image));
    },
    [dispatch]
  );

  /**
   * Setter that saves the cover image into the redux state.
   */
  const setProjectCover = useCallback(
    (image: PhotoFile) => {
      dispatch(updateCoverImage(image));
    },
    [dispatch]
  );

  //////////////////////////////////////////////////////////////////////////////
  // State definition for the project and user data.

  const [projectType, setProjectType] = useState<ProjectType | undefined>(
    projectLocalStorageData.projectType || (projectTypeQs as ProjectType)
  );
  const [name, setName] = useState(projectLocalStorageData.name);
  const [description, setDescription] = useState(projectLocalStorageData.description);
  const [location, setLocation] = useState<LocationWithAddress | undefined>(projectLocalStorageData.location);

  ///////////////////////////////
  // Only for personal project.

  const [myWork, setMyWork] = useState<Skill | undefined>(projectLocalStorageData?.myWork);
  const [abilities, setAbilities] = useState<AbilitySet | undefined>(projectLocalStorageData.abilities);
  const [causes, setCauses] = useState<CauseSet | undefined>(projectLocalStorageData.causes);
  const [socialNetWork, setSocialNetWork] = useState<SocialNetworkData>({});

  //////////////////////////////////////////////////////////////////////////////
  // Calculated fields section

  const isPersonalProject = projectType === "personal";

  //////////////////////////////////////////////////////////////////////////////
  // Validation section

  /**
   * Touched saves what field was already set by the user in order to avoid some validation errors being shown as soon
   * as the user opens the screen.
   *
   * This is for project data.
   */
  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    type: false,
    name: false,
    description: false,
    "images_data.profile.original": false,
    "images_data.cover.original": false,
    "locations.city": false,
    "locations.coordinates": false,
    "locations.country": false,
    "locations.state": false,
  });

  /**
   * Define the validations rules for creating a project.
   */
  const {
    errors: projectErrors,
    setErrors: setProjectErrors,
    failed: projectHasErrors,
    assignErrorsToOriginal: assignProjectErrorsToOriginal,
    errorsOriginal: projectErrorsOriginal,
  } = useValidation(
    {
      type: projectType,
      name,
      description,
      locations: location,
      images_data: {
        cover: {
          original: "NONE", //projectCoverImage?.cropped, //TODO(Jeconias): Removed because the project does not use a cover image
        },
        profile: {
          original: projectProfileImage?.cropped,
        },
      },
      conditionals: {
        causes: causes && (Object.keys(causes).filter((ckey) => causes[ckey]) as Cause[]),
        interests: abilities && (Object.keys(abilities).filter((akey) => abilities[akey]) as Interest[]),
        skills: myWork ? [myWork] : [],
      },
    },
    isPersonalProject ? projectValidation.rulesPersonalProject : projectValidation.rules,
    touched,
    [projectType, name, description, location, projectCoverImage, projectProfileImage]
  );

  /**
   * Flag that is set to true while creating a project.
   */
  const [creatingProject, setCreatingProject] = useState(false);

  useLocalStorageWrite<CreateProjectType>(
    {
      projectType,
      name,
      description,
      location,

      // For personal projects only
      abilities,
      causes,
      myWork,
    },
    WIZARD_CREATE_PROJECT
  );

  //////////////////////////////////////////////////////////////////////////////
  // Calculated fields section

  /**
   * Set the current step based on the routes.
   */
  const stepCurrent = useMemo(() => {
    const isPersonaProjectDelta = isPersonalProject ? 3 : 0;
    if (matchPath(pathname, routes.wizard.newProject.inputName)) return 1;
    if (matchPath(pathname, routes.wizard.newProject.inputDescription)) return 2;
    if (matchPath(pathname, routes.wizard.newProject.inputAddress)) return 3;
    if (matchPath(pathname, routes.wizard.newProject.inputProfileImage)) return 4 + isPersonaProjectDelta;
    if (matchPath(pathname, routes.wizard.newProject.socialMedia)) return 5;
    //if (matchPath(pathname, routes.wizard.newProject.inputCoverImage)) return 5; //TODO(Jeconias): Removed because the project does not use a cover image
    if (matchPath(pathname, routes.wizard.newProject.personalCauses)) return 6;
    if (matchPath(pathname, routes.wizard.newProject.personalAbilities)) return 5;
    if (matchPath(pathname, routes.wizard.newProject.personalWork)) return 4;
    return 0;
  }, [pathname, isPersonalProject]);

  //////////////////////////////////////////////////////////////////////////////
  // Support functions section

  /**
   * Check if the project information has any error. If it doesn't, it returns true. Otherwise,
   * the system tries to route it to the right screen and returns false.
   */
  const isProjectDataValid = useCallback(() => {
    if (projectHasErrors) {
      const r = routeToError(projectErrorsOriginal!, projectValidation.routes.create);
      assignProjectErrorsToOriginal();
      if (r) {
        history.push(r);
      }
      return false;
    }
    return true;
  }, [history, projectHasErrors, assignProjectErrorsToOriginal, projectErrorsOriginal]);

  /**
   * It is called whenever the flow is finished. This will drive the user to the next screen. If the user has social
   * networks filled, the next screen will be the "create action CTA". Otherwise, it will show the social network page.
   *
   * TODO Not implemented.
   */
  const gotoPageAfterProjectCreated = useCallback(
    (projectSlug: string) => {
      // TODO(Jota): Check if the user needs to have its socialNetworksPage updated.
      // goto(history, reverse(routes.wizard.project.socialNetworksPage))();

      // This uses a setTimeout to avoid memory leak while dealing with try ... finally statements.
      setTimeout(() => {
        history.push(
          reverse(routes.wizard.project.toString(), {
            projectSlug,
          })
        );
      });
    },
    [history]
  );

  /**
   * Creates the project based on the current state.
   */
  const createProject = useCallback(async () => {
    let projectRequest: StoreNewProjectPersonalRequest | StoreNewProjectOrganizationRequest | undefined;

    // Check if the project data is valid.
    if (!isProjectDataValid()) {
      return;
    }

    if (isPersonalProject) {
      projectRequest = {
        description: description,
        resume: description!,
        type: "personal",
        locations: location!,
        conditionals: {
          causes: Object.keys(causes || {}) as Cause[],
          interests: Object.keys(abilities || {}) as Interest[],
          skills: myWork ? [myWork] : [],
        },
        images_data: {
          //cover: { original: projectCoverImage!.cropped }, //TODO(Jeconias): Removed because the project does not use a cover image
          profile: { original: projectProfileImage!.cropped },
        },
        social_network: {
          facebook: socialNetWork.facebook,
          instagram: socialNetWork.instagram,
          website: socialNetWork.website,
          youtube: socialNetWork.youtube,
        },
      };
    } else {
      projectRequest = {
        name: name!,
        description: description!,
        images_data: {
          //cover: { original: projectCoverImage!.cropped }, //TODO(Jeconias): Removed because the project does not use a cover image
          profile: { original: projectProfileImage!.cropped },
        },
        locations: location!,
        type: projectType!,
        social_network: {
          facebook: socialNetWork.facebook,
          instagram: socialNetWork.instagram,
          website: socialNetWork.website,
          youtube: socialNetWork.youtube,
        },
      };
    }

    setCreatingProject(true);
    try {
      const projectResponse = ((await dispatch(createStoreNewProject(projectRequest!))) as any) as PayloadAction<
        StoreNewProjectResponse | ResponseWithParams<ResponseStatus, ValidationErrorSet>
      >;
      if (createStoreNewProject.fulfilled.match(projectResponse)) {
        const projectData = unwrapResult(projectResponse);
        if (projectResponse.payload.success) {
          dispatch(
            listNewProject({
              slug: projectData?.params?.slug,
              title: name,
              type: projectType!,
              profileImage: projectProfileImage!.cropped,
            })
          );
          setProjectProfileImage({ cropped: "", source: "" });
          gotoPageAfterProjectCreated(projectData!.params!.slug);
          dispatch(ListProject({}));
          return;
        } else {
          dispatch(showAlert("danger", projectData));
        }
      } else {
        if (projectResponse.payload?.params && !isEmpty(projectResponse.payload.params)) {
          const r = routeToError(
            projectResponse.payload!.params! as ValidationErrorSet,
            projectValidation.routes.create
          );
          if (r) {
            history.push(r);
            return;
          }

          const paramsFormated = APIParamsToString(projectResponse.payload?.params);
          if (
            Object.keys(paramsFormated.params).find(
              (el) => el === "contacts" && paramsFormated.params[el] === "is-required"
            )
          ) {
            dispatch(
              showAlert(
                "danger",
                t(
                  "plain:Para continuar você precisa adicionar o seu telefone Por favor, visite as configurações e atualize suas informações"
                )
              )
            );
          } else {
            dispatch(showAlert("danger", t(["wizard.error.common.projectCreation"])));
          }
        } else {
          dispatch(showAlert("danger", projectResponse.payload.response?.message_translated));
        }
        // TODO(Jota): Report to sentry.
      }
    } catch (e) {
      dispatch(showAlert("danger", e?.response?.data?.response?.message_translated));
    } finally {
      setCreatingProject(false);
    }
  }, [
    dispatch,
    history,
    t,
    isProjectDataValid,
    name,
    socialNetWork,
    projectType,
    projectCoverImage,
    projectProfileImage,
    isPersonalProject,
    setProjectProfileImage,
  ]);

  //////////////////////////////////////////////////////////////////////////////
  // Page functions

  /**
   * Sets the project type and goes to the next screen.
   */
  const selectProjectTypeOnContinue = useCallback(
    (projectType, replace?: boolean) => {
      setProjectType(projectType);
      if (replace) {
        history.replace(reverse(routes.wizard.newProject.inputName));
      } else {
        goto(history, reverse(routes.wizard.newProject.inputName))();
      }
    },
    [history]
  );

  /**
   * Sets the name and goes to the next screen.
   */

  const inputProjectNameOnContinue = useCallback(
    (name) => {
      if (!isNaN(name)) {
        setProjectErrors({
          name: "O Nome não pode conter apenas números",
        });
      } else {
        setName(name);
        goto(history, reverse(routes.wizard.newProject.inputDescription))();
      }
    },
    [history]
  );

  /**
   * Sets the description and goes to the next screen.
   */
  const selectDescriptionOnContinue = useCallback(
    (description: string) => {
      setDescription(description);
      goto(history, reverse(routes.wizard.newProject.inputAddress))();
    },
    [history]
  );

  /**
   * Sets the location (address) and goes to the next screen.
   */

  const selectLocationOnContinue = useCallback(
    (location) => {
      setLocation(location);

      if (isPersonalProject) {
        goto(history, reverse(routes.wizard.newProject.personalWork))();
      } else {
        goto(history, reverse(routes.wizard.newProject.inputProfileImage))();
      }
    },
    [history, isPersonalProject]
  );

  //////////////////////////////////
  // Personal project pages only

  const selectMyWorkOnContinue = useCallback(() => {
    goto(history, routes.wizard.newProject.personalAbilities)();
  }, [history]);

  const selectAbilitiesOnContinue = useCallback(
    (data: AbilitySet) => {
      setAbilities(data);
      goto(history, routes.wizard.newProject.personalCauses)();
    },
    [history]
  );

  const selectCausesOnContinue = useCallback(
    (data: CauseSet) => {
      setCauses(data);
      goto(history, routes.wizard.newProject.inputProfileImage)();
    },
    [history]
  );

  const socialMediaOnContinue = useCallback(
    (socialMedia: SocialNetworkData) => {
      setSocialNetWork(socialMedia);
      createProject();
    },
    [createProject, history]
  );

  // End of the personal project pages
  //////////////////////////////////

  /**
   * Sets the profile image and goes to the next screen.
   */
  const selectProfileImageOnContinue = useCallback(
    (profileImage) => {
      setProjectProfileImage(profileImage);
      goto(history, routes.wizard.newProject.socialMedia)();
    },
    [setProjectProfileImage, history]
  );

  /**
   * Sets the project cover. Then, we check if the user is logged in. If positive, we create the project. Otherwise,
   * we must continue must go to the next screen.
   */
  /*const selectProfileCoverOnContinue = useCallback(
    (profileCover) => {
      setProjectCover(profileCover);

      if (user) {
        createProject();
      } else {
        if (gt18year && terms === "accepted") return goto(history, reverse(routes.wizard.newProject.inputAccessInfo))();
        if (gt18year && terms !== "accepted") return goto(history, reverse(routes.wizard.newProject.acceptTerms))();
        goto(history, reverse(routes.wizard.newProject.confirmAge))();
      }
    },
    [history, user, createProject, gt18year, terms]
  );*/

  /** Continue page project */
  const projectRuleMemorized = useMemo(() => {
    const projectData = storageGet(WIZARD_CREATE_PROJECT);
    return !!projectData && !isAllPropsEmpty(projectData);
  }, []);

  const projectOnContinue = useCallback(() => {
    history.replace(routes.wizard.newProject.selectType);
  }, [history, hasOneProjectType, projectTypeJoined]);

  const projectOnUnregister = useCallback(() => {
    localStorage.removeItem(WIZARD_CREATE_PROJECT);
    setName(undefined);
    setDescription(undefined);
    setLocation(undefined);
    setMyWork(undefined);
    setCauses(undefined);
    setAbilities(undefined);
    setProjectType(undefined);
    setProjectProfileImage({ cropped: "", source: "" });
    setProjectCover({ cropped: "", source: "" });
    projectOnContinue();
  }, [projectOnContinue]);

  const stepMax = isPersonalProject ? 7 : 5;

  const initialRoute = useMemo(() => (!user ? reverse(routes.wizard.selectProfileType) : undefined), [user]);

  return (
    <Wizard
      header={{
        title: user
          ? t("wizard.stepsWizard.createProject.header.title")
          : t("wizard.stepsWizard.createProfile.header.title"),
        stepCurrent,
        stepMax,
      }}
    >
      <DoingWork isOpen={creatingProject}>
        {creatingProject && (
          <WizardDoingWorkText>{t("wizard.pages.createProject.creating.projectText")}</WizardDoingWorkText>
        )}
      </DoingWork>
      <Switch>
        <Route path={routes.wizard.newProject.continue}>
          <ContinueRegistrationPage
            message={continuePageMessage("project", t)}
            rule={projectRuleMemorized}
            onContinue={projectOnContinue}
            onUnregister={projectOnUnregister}
          />
        </Route>
        {/* 2 */}
        <RouteRule path={routes.wizard.newProject.selectType} exact>
          <SelectProjectTypePage
            projectType={projectType}
            onBack={initialRoute}
            onContinue={selectProjectTypeOnContinue}
            onChange={(projectType) => {
              setProjectType(projectType);
              setTouched((prev) => ({ ...prev, projectType: true }));
            }}
            error={projectErrors?.["type"]}
          />
        </RouteRule>
        {/* 3 */}
        <RouteRule path={routes.wizard.newProject.inputName} exact>
          <InputProjectNamePage
            name={name || ""}
            error={projectErrors?.name}
            onChange={(name) => {
              setName(name);
              setTouched((prev) => ({ ...prev, name: true }));
            }}
            onBack={hasOneProjectType ? initialRoute : reverse(routes.wizard.newProject.selectType)}
            onContinue={inputProjectNameOnContinue}
            projectType={projectType}
          />
        </RouteRule>
        {/* 4 */}
        <RouteRule path={routes.wizard.newProject.inputDescription} exact>
          <InputProjectDescriptionPage
            minlength={180}
            description={description || ""}
            onBack={reverse(routes.wizard.newProject.inputName)}
            onContinue={selectDescriptionOnContinue}
            onChange={(description) => {
              setDescription(description);
              setTouched((prev) => ({ ...prev, description: true }));
            }}
            error={projectErrors?.description}
          />
        </RouteRule>
        {/* 5 */}
        <RouteRule path={routes.wizard.newProject.inputAddress} exact>
          <InputProjectAddressPage
            location={location}
            onBack={reverse(routes.wizard.newProject.inputDescription)}
            onContinue={selectLocationOnContinue}
            onChange={(address) => {
              setLocation(address);

              if (address?.address && !touched?.["locations.city"])
                setTouched((prev) => ({
                  ...prev,
                  ["locations.city"]: true,
                  ["locations.coordinates"]: true,
                  ["locations.country"]: true,
                  ["locations.state"]: true,
                }));
            }}
            error={
              projectErrors?.["locations.city"] ||
              projectErrors?.["locations.coordinates"] ||
              projectErrors?.["locations.country"] ||
              projectErrors?.["locations.state"]
            }
          />
        </RouteRule>
        {/* Personal project pages *******************************************/}
        <RouteRule path={routes.wizard.newProject.personalWork}>
          <MatchmakerWorkPage
            value={myWork}
            onBack={routes.wizard.newProject.inputAddress}
            onChange={(data) => {
              setTouched((prev) => ({ ...prev, myWork: true }));
              setMyWork(data);
            }}
            onContinue={selectMyWorkOnContinue}
            error={projectErrors?.myWork}
          />
        </RouteRule>
        <RouteRule path={routes.wizard.newProject.personalAbilities}>
          <MatchmakerAbilitiesPage
            abilities={abilities || {}}
            onBack={routes.wizard.newProject.personalWork}
            onChange={(data) => {
              setTouched((prev) => ({ ...prev, abilities: true }));
              setAbilities(data);
            }}
            onContinue={selectAbilitiesOnContinue}
            error={projectErrors?.abilities}
          />
        </RouteRule>
        <RouteRule path={routes.wizard.newProject.personalCauses}>
          <CausesPage
            causes={causes || {}}
            title={t("wizard.pages.causes.titleVolunteer")}
            onBack={routes.wizard.newProject.personalAbilities}
            onChange={(data) => {
              setTouched((prev) => ({ ...prev, causes: true }));
              setCauses(data);
            }}
            onContinue={selectCausesOnContinue}
            error={projectErrors?.causes}
          />
        </RouteRule>
        {/* / Personal project pages *****************************************/}

        {/* 6 */}
        <RouteRule path={routes.wizard.newProject.inputProfileImage} exact>
          <InputProfileImagePage
            file={projectProfileImage}
            onBack={
              isPersonalProject
                ? reverse(routes.wizard.newProject.personalCauses)
                : reverse(routes.wizard.newProject.inputAddress)
            }
            onContinue={selectProfileImageOnContinue}
            onChange={(profileImage) => {
              setProjectProfileImage(profileImage!);
              setTouched((prev) => ({ ...prev, "images_data.profile.original": true }));
            }}
            error={projectErrors?.["images_data.profile.original"]}
          />
        </RouteRule>
        <RouteRule path={routes.wizard.newProject.socialMedia} exact>
          <SocialNetworksPage
            onContinue={socialMediaOnContinue}
            onChangeValues={setSocialNetWork}
            skipTo={routes.wizard.newProject.inputProfileImage}
            skipToLabel={t("actions.toBack.label")}
            continueDisabled={creatingProject}
          />
        </RouteRule>

        {/* 7 */}
        {/* 
          TODO(Jeconias): Removed because the project does not use a cover image
        <Route path={routes.wizard.newProject.inputCoverImage} exact>
          <InputCoverImagePage
            file={projectCoverImage}
            onBack={reverse(routes.wizard.newProject.inputProfileImage)}
            onContinue={selectProfileCoverOnContinue}
            onChange={(projectCover) => {
              setProjectCover(projectCover!);
              setTouched((prev) => ({ ...prev, "images_data.cover.original": true }));
            }}
            loading={sendingEmailCode}
            error={projectErrors?.["images_data.cover.original"]}
          />
        </Route> */}
        {/* 8 */}
      </Switch>
    </Wizard>
  );
};

export default CreateProjectPage;

const RouteRule = ({ ...props }) => {
  const { user } = useUser();
  const dispatch = useDispatch();
  return (
    <FuncRoute
      {...props}
      rule={!!user}
      unauthorized={() => {
        return {
          pathname: routes.home,
          search: "?open=signUp",
          state: {
            from: routes.wizard.newProject.selectType,
          },
        };
      }}
    />
  );
};
