import React, { useRef, useEffect, useState } from "react";
import { Input as BaseInput, InputProps as BaseInputProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { BorderFocus } from "components/common/style";
import { rem } from "polished";

type Types = keyof Extract<BaseInputProps, "type">;

export interface InputProps extends BaseInputProps {}

const Input: React.FunctionComponent<InputProps> = ({ type, onChange, children, ...props }) => {
  const refInput = useRef<HTMLTextAreaElement | null>(null);

  const [state, setState] = useState<{ rows: number; minRows: number; maxRows: number }>({
    rows: 1,
    minRows: 1,
    maxRows: 10,
  });

  const getProps = (type?: Types) => {
    if (type === "textarea")
      return {
        rows: state.rows,
      };

    return {};
  };

  const calcRow = (scrollHeight: number): number => ~~(scrollHeight / 22) - 2;

  const textAreaAutoHeight = (element: React.ChangeEvent<HTMLTextAreaElement> | HTMLTextAreaElement) => {
    if (type !== "textarea") return;

    const { minRows, maxRows } = state;
    const textArea = element instanceof HTMLTextAreaElement ? element : element.currentTarget;

    const previousRows = textArea.rows;
    textArea.rows = minRows;

    const currentRows = calcRow(textArea.scrollHeight);

    if (currentRows === previousRows) {
      textArea.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      textArea.rows = maxRows;
      textArea.scrollTop = textArea.scrollHeight;
    }

    setState((prev) => ({ ...prev, rows: currentRows < maxRows ? currentRows : maxRows }));
  };

  const proxyOnchange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    textAreaAutoHeight(event as React.ChangeEvent<HTMLTextAreaElement>);
    if (onChange) onChange(event as React.ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    if (refInput.current !== null) textAreaAutoHeight(refInput.current);

    return () => {
      refInput.current = null;
    };
  }, [refInput]);

  if (type === "select")
    return (
      <Wrapper>
        <Arrow />
        <BaseInputStyled
          type={type}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(e);
          }}
        >
          {children}
        </BaseInputStyled>
      </Wrapper>
    );

  return (
    <BaseInputStyled innerRef={refInput} type={type} {...props} {...getProps(type)} onChange={proxyOnchange}>
      {children}
    </BaseInputStyled>
  );
};

export default Input;

export const BaseUnputStyledCommon = css`
  border: 1px solid #cdd6e2;
  border-radius: 6px;
`;

const BaseInputStyled = styled(BaseInput)`
  ${BaseUnputStyledCommon};
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
  color: #9facbd;
  min-height: 70px;
  font-size: ${rem(17)};
  padding: 25px;
  text-align: center;
  overflow: hidden;

  :focus {
    outline: none;
    box-shadow: none;
    ${BorderFocus};
  }

  ::placeholder,
  :focus {
    color: #9facbd;
    line-height: ${rem(18)};
    font-weight: ${rem(300)};
    letter-spacing: 0;
  }

  ${({ type }) =>
    type === "textarea" &&
    css`
      resize: none;
      padding: 30px;
      letter-spacing: 0;
      min-height: 220px;
      text-align: left;
      font-size: ${rem(15)};

      :focus::-webkit-input-placeholder {
        color: transparent;
      }

      ::placeholder,
      :focus {
        font-size: ${rem(15)};
        line-height: ${rem(22)};
      }
    `}

    ${({ type }) =>
      type === "select" &&
      css`
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-progress-appearance: none;
        appearance: none;
        font-size: ${rem(13)};
        line-height: ${rem(20)};
        letter-spacing: 0;
      `}

    ${({ type }) =>
      (type === "tel" || type === "number") &&
      css`
        text-align: center;
        font-size: ${rem(36)};
        ::placeholder,
        :focus {
          line-height: ${rem(50)};
          font-size: ${rem(36)};
          letter-spacing: ${rem(10)};
        }
      `}
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto 0;
  height: 12px;
  width: 12px;
  border: 2px solid #bcc4de;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
  z-index: 1;
`;
