import React from "react";
import { useTranslation } from "react-i18next";
import SimpleInputPage from "wizard/pages/common/SimpleInputPage";
import { PageEvents } from "../definitions/commonTypes";
import { ProjectType } from "core/api/definitions";

interface InputProjectNamePageProps extends PageEvents<string> {
  name: string;
  projectType?: ProjectType;
}

const InputProjectNamePage: React.FC<InputProjectNamePageProps> = ({ name, projectType, ...props }) => {
  const { t } = useTranslation();

  return (
    <SimpleInputPage
      title={t([`wizard.pages.projectName.title.${projectType}`, `wizard.pages.projectName.title.default`])}
      placeholder={t(`wizard.pages.projectName.placeholder`)}
      clue={t(`wizard.pages.projectName.cue`)}
      value={name}
      {...props}
    />
  );
};

export default InputProjectNamePage;
