import React from "react";
import { useTranslation } from "react-i18next";
import { PageEvents, PhotoFile } from "../definitions/commonTypes";
import SimpleImageCoverPage from "wizard/pages/common/SimpleImageCoverPage";

interface VacancyCoverImageProps extends PageEvents<PhotoFile | undefined> {
  file?: PhotoFile;
  skippable?: boolean;
  continueLabel?: string;
}

const SelectVacancyCoverImage: React.FC<VacancyCoverImageProps> = ({ file, ...props }) => {
  const { t } = useTranslation();
  const title = t("plain:Adicione uma imagem para sua vaga");
  return <SimpleImageCoverPage file={file} title={title} {...props} />;
};

export default SelectVacancyCoverImage;
