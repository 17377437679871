import React from "react";
import styled, { css } from "styled-components/macro";
import { IconType as FAIconType } from "./IconFontAwesome";
import { ReactComponent as Bolt } from "assets/svgs/bolt.svg";
import { ReactComponent as IconError } from "assets/svgs/v3/error.svg";
import { ReactComponent as Calendar } from "assets/svgs/calendar.svg";
import { ReactComponent as CheckIcon } from "assets/svgs/check.svg";
import { ReactComponent as ChevronDown } from "assets/svgs/chevron-down.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close.svg";
import { ReactComponent as DownChevronIcon } from "assets/svgs/down-chevron.svg";
import { ReactComponent as Envelope } from "assets/svgs/envelope.svg";
import { ReactComponent as FacebookIcon } from "assets/svgs/facebook.svg";
import { ReactComponent as Heart } from "assets/svgs/heart.svg";
import { ReactComponent as InstagramIcon } from "assets/svgs/instagram.svg";
import { ReactComponent as Link } from "assets/svgs/logout.svg";
import { ReactComponent as MarkerIcon } from "assets/svgs/marker.svg";
import { ReactComponent as Movie } from "assets/svgs/movie.svg";
import { ReactComponent as MultiplyIcon } from "assets/svgs/multiply.svg";
import { ReactComponent as Next } from "assets/svgs/next.svg";
import { ReactComponent as PhotoCamera } from "assets/svgs/photo-camera.svg";
import { ReactComponent as Pin } from "assets/svgs/pin.svg";
import { ReactComponent as PinThin } from "assets/svgs/pin-thin.svg";
import { ReactComponent as Plus } from "assets/svgs/plus.svg";
import { ReactComponent as Search } from "assets/svgs/search.svg";
import { ReactComponent as Send } from "assets/svgs/send.svg";
import { ReactComponent as Share } from "assets/svgs/share.svg";
import { ReactComponent as Star } from "assets/svgs/star.svg";
import { ReactComponent as Timer } from "assets/svgs/timer.svg";
import { ReactComponent as Trash } from "assets/svgs/trash.svg";
import { ReactComponent as TwitterIcon } from "assets/svgs/twitter.svg";
import { ReactComponent as Upload } from "assets/svgs/upload.svg";
import { ReactComponent as UploadCloud } from "assets/svgs/upload_cloud.svg";
import { ReactComponent as UserIcon } from "assets/svgs/user.svg";
import { ReactComponent as YoutubeIcon } from "assets/svgs/youtube.svg";
import { ReactComponent as LoadingIcon } from "assets/svgs/loading.svg";
import { ReactComponent as Arrow } from "assets/svgs/arrow.svg";
import { ReactComponent as IconHeart } from "assets/svgs/icone-coracao.svg";
import { ReactComponent as IconLocation } from "assets/svgs/icone-location.svg";
import { ReactComponent as IconHours } from "assets/svgs/icone-horas.svg";
import { ReactComponent as Settings } from "assets/svgs/settings.svg";
import { ReactComponent as Checkout } from "assets/svgs/checkout.svg";
import { ReactComponent as Resume } from "assets/svgs/resume.svg";
import { ReactComponent as Projects } from "assets/svgs/projects.svg";
import { ReactComponent as HeartThin } from "assets/svgs/heart-thin.svg";
import { ReactComponent as Validation } from "assets/svgs/validation.svg";
import { ReactComponent as Program } from "assets/svgs/progrma-icon.svg";
import { ReactComponent as Token } from "assets/svgs/token.svg";
import { ReactComponent as Tracking } from "assets/svgs/tracking-icon.svg";
import { ReactComponent as GoogleLogo } from "assets/svgs/google-g-logo.svg";
import { ReactComponent as Eye } from "assets/svgs/eye.svg";
import { ReactComponent as PrevSmall } from "assets/svgs/prevSmall.svg";
import { ReactComponent as NextSmall } from "assets/svgs/nextSmall.svg";
import { ReactComponent as EllipsisV } from "assets/svgs/ellipsis-v.svg";

/**
 * //TODO(Jeconias) Add pattern
 *
 * Persisting the "WithColor" function may be an error.
 * The best way to solve this is to add all icons with a pattern to receive property "fill" to change color.
 */

const WithColor = (cls: any) => styled(cls)`
  path {
    fill: ${({ theme, color }) => theme.colors[color] || color || `currentColor`};
  }
`;

const WithStroke = (cls: any) => styled(cls)`
  path {
    stroke: ${({ theme, color }) => theme.colors[color] || color || `currentColor`};
  }
`;

export const CameraCustom = (cls: any) => styled(cls)`
  ${({ color }) =>
    color &&
    css`
      * {
        stroke-width: 2;
        opacity: 1;
      }
      path {
        stroke: ${color};
      }
      circle {
        display: none;
      }
      ellipse {
        stroke: ${color};
        fill: none;
      }
    `}
`;

const Prev = styled(Next)`
  transform: rotate(180deg);
`;

const LeftChevronIcon = styled(DownChevronIcon)`
  transform: rotate(90deg);
`;

const UpChevronIcon = styled(DownChevronIcon)`
  transform: rotate(180deg);
`;

const availableIcons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  instagram: InstagramIcon,
  multiply: MultiplyIcon,
  "down-chevron": WithColor(DownChevronIcon),
  "left-chevron": WithColor(LeftChevronIcon),
  "up-chevron": WithColor(UpChevronIcon),
  check: CheckIcon,
  photoCamera: CameraCustom(PhotoCamera),
  trash: WithColor(Trash),
  chevronDown: ChevronDown,
  calendar: Calendar,
  close: CloseIcon,
  pin: WithColor(Pin),
  "pin-thin": PinThin,
  upload: Upload,
  timer: WithColor(Timer),
  heart: WithColor(Heart),
  star: Star,
  next: WithColor(Next),
  prev: WithColor(Prev),
  search: WithColor(Search),
  envelope: Envelope,
  marker: MarkerIcon,
  user: UserIcon,
  link: WithColor(Link),
  share: WithColor(Share),
  plus: WithColor(Plus),
  send: WithColor(Send),
  uploadCloud: UploadCloud,
  movie: Movie,
  bolt: WithColor(Bolt),
  LoadingIcon,
  arrow: Arrow,
  iconHeart: IconHeart,
  iconLocation: IconLocation,
  iconHours: IconHours,
  settings: WithColor(WithStroke(Settings)),
  checkout: WithColor(Checkout),
  resume: WithColor(WithStroke(Resume)),
  projects: Projects,
  "heart-thin": HeartThin,
  validation: WithColor(Validation),
  program: WithColor(Program),
  token: WithColor(Token),
  action: WithColor(HeartThin),
  project: WithColor(Projects),
  subscribe: WithColor(Validation),
  function: WithColor(WithStroke(Settings)),
  tracking: WithColor(Tracking),
  google: GoogleLogo,
  eye: Eye,
  prevSmall: PrevSmall,
  nextSmall: NextSmall,
  ellipsisV: EllipsisV,
};

export type IconType = keyof typeof availableIcons;

interface IconProps {
  type: IconType;
  color?: string;
  width?: number;
  height?: number;
  id?: string;
  title?: string;
  style?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = ({ type, ...rest }) => {
  const Component = availableIcons[type];
  return Component ? <Component {...rest} /> : null;
};

export default Icon;

export const isValidIcon = (icon: string | FAIconType) => {
  if (typeof icon !== "string") return false;
  return availableIcons.hasOwnProperty(icon);
};
