import { generatePath } from "react-router";
import routes from "../routes";
import { Area } from "react-easy-crop/types";
import { SIZE_IMAGE } from "./constants";
import { Address, AddressCoordinates, ProjectType, Origin } from "core/api/definitions";
import { AxiosError } from "axios";
import { Response } from "core/api/definitions";
import { store } from "core";

export const createODSDetailsUrl = (odsData: any, translator?: any) => {
  return generatePath(routes.ods.detail, { code: odsData.number });
};

type Callback = (param: boolean) => void;
export const hasImage = (image: string, callback: Callback) => {
  const img = new Image();
  img.onerror = img.onabort = () => {
    //console.log("Error when load image of Header");
    callback(false);
  };
  img.onload = () => {
    //console.log("Success when load image of Header");
    callback(true);
  };
  img.src = image;
};

export type StorageType = "local" | "session";

/**
 * Get a element from localStorage
 *
 * @param key string
 * @param storage local | session - Location to save the data. local | session.
 *
 * @return string | object| null
 */
export const storageGet = <T = any>(key: string, storage: StorageType = "local"): T | null => {
  const element = storage === "session" ? sessionStorage.getItem(key) : localStorage.getItem(key);
  if (element === null) return null;

  return JSON.parse(element);
};

type StorageSetHandleError = (errorType: "LimitExceeded" | "unknow") => void;

/**
 * Add new element in localStorage
 *
 * @param key string
 * @param value string | object
 * @param storage local | session - Location to save the data. local | session.
 *
 * @return void
 */
export const storageSet = <T = Object>(
  key: string,
  value: string | T,
  storage: StorageType = "local",
  onError?: StorageSetHandleError
) => {
  if (!key || !value) return;
  try {
    if (storage === "session") sessionStorage.setItem(key, JSON.stringify(value));
    if (storage === "local") localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
    if (onError) {
      if (e?.name === "QuotaExceededError") onError("LimitExceeded");
      if (onError) onError("unknow");
    }
  }
};

export const dontPaste = (e: React.ChangeEvent) => {
  e.target.addEventListener("paste", function (E) {
    E.preventDefault();
    return false;
  });
};

export const getImage = (url: string, anonymous = false) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    if (anonymous) {
      img.setAttribute("crossorigin", "anonymous");
    }
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      console.log(err);
      reject(err);
    };
    img.src = url;
  });

type OuputType = "BLOB" | "BASE64";

export const croppedImg = async (url: string, croppedAreaPixels: Area, output: OuputType = "BLOB") => {
  const image = await getImage(url, true);
  return await imageConverter(image, croppedAreaPixels, output);
};

export const imageConverter = async (
  image: HTMLImageElement,
  croppedAreaPixels?: Area,
  output: OuputType = "BLOB",
  quality?: number
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels?.width || image.width;
  canvas.height = croppedAreaPixels?.height || image.height;

  ctx?.drawImage(image, 0 - (croppedAreaPixels?.x || 0), 0 - (croppedAreaPixels?.y || 0));

  return await new Promise<string>((resolve) => {
    if (output === "BASE64") resolve(canvas.toDataURL("image/jpeg", quality || 0.8));
    if (output === "BLOB") canvas.toBlob((file) => resolve(URL.createObjectURL(file)));
  });
};

type ValidHour = {
  hours: number;
  minutes: number;
};

export const validHour = (hour: string): ValidHour | undefined => {
  const hourArr = hour.split(":");

  if (hour.indexOf("_") !== -1 || hourArr.length < 2 || parseInt(hourArr[0]) > 23 || parseInt(hourArr[1]) > 59)
    return undefined;
  return {
    hours: parseInt(hourArr[0]),
    minutes: parseInt(hourArr[1]),
  };
};

export const keyDownConfirm = (e: React.KeyboardEvent, callback: () => void): boolean => {
  const { keyCode } = e;
  if (keyCode === 13 || keyCode === 32) {
    callback();
    return true;
  }
  return false;
};

export type FileErrorType = "size" | "type" | "resolution" | "none";
export const handleRejectFile = <T extends File>(rejectedFiles: T[], callback: (errorType: FileErrorType) => void) => {
  if (rejectedFiles.length !== 0) {
    const firstFile = rejectedFiles.find((_, k) => k === 0);
    if (firstFile && firstFile.size > SIZE_IMAGE.SIZE_2MB) {
      callback("size");
    } else {
      callback("type");
    }
  } else {
    callback("none");
  }
};

export const cacheHasExpired = (cacheTime: number, expireAt = -1): boolean => {
  const currentLimit = expireAt !== -1 ? expireAt : 3 * 60 * 1000;
  return cacheTime + currentLimit >= new Date().getTime();
};

export const addressToShortString = (address: Address) => {
  const parts = [];
  if (address.street) parts.push(address.street);
  if (address.number) parts.push(address.number);
  if (address.complement) parts.push(address.complement);
  if (address.district) parts.push(address.district);
  return parts.join(", ");
};

export const addressToFullString = (address?: Address) => {
  if (!address) return "";

  const parts = [];
  if (address.street) parts.push(address.street);
  if (address.number) parts.push(address.number);
  if (address.complement) parts.push(address.complement);
  if (address.district) parts.push(address.district);
  if (address.zipcode) parts.push(address.zipcode);

  if (address.city && address.state) parts.push(`${address.city}/${address.state}`);
  else if (address.city) parts.push(address.city);
  else if (address.state) parts.push(address.state);

  return parts.join(", ");
};

export const isNotFoundError = (resp?: Response) => /not[\-._]?found/g.test(resp?.response?.message || "");

export const isNetworkError = <T = any>(error: AxiosError<T>) => error?.message === "Network Error";

export const phoneFormat = (phone: string) => phone.replace(/[.|-]/g, "");

export const checkUrl = (text: string) =>
  /(?:https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    text
  );

export const checkFacebook = (text: string) =>
  /(^(?:https?:\/\/)?(www\.)?(mbasic.facebook|m\.facebook|facebook|fb))\.(com|me)\/[A-Za-z0-9_.]{2,}$/i.test(text);

export const checkInstagram = (text: string) =>
  text.match(/^(?:http(?:s?):\/\/)?(www\.)?(?:(?:instagram\.com|instagr\.am)\/[A-Za-z0-9_.]{2,30})?$/);

export const checkYoutube = (text: string) => /(^(?:https?:\/\/)?(www\.)?youtu(?:be\.com\/|\.be\/)(.+))/.test(text);

export const checkTwitter = (text: string) =>
  /(^(http(s?):\/\/)?(www\.))?twitter\.com\/[A-Za-z0-9_]{1,15}$/i.test(text);

export const formatLanguageToISO = (language: string) => {
  const hifenIndex = language.indexOf("-");

  if (hifenIndex < 0) return language;

  return language.slice(0, hifenIndex * -1) + language.slice(hifenIndex + 1).toUpperCase();
};

export const isAllPropsEmpty = (obj: { [propName: string]: any }) => {
  if (!obj) return true;
  for (const k in obj) {
    if (obj[k]) return false;
  }
  return true;
};

export const hitCondition = (obj: { error?: Error }) => obj?.error?.name === "ConditionError";

export const getMinimumZoom = (
  mediaSize: { width: number; height: number },
  containerSize: { width: number; height: number }
) => {
  const ratioMedia = mediaSize.width / mediaSize.height,
    ratioContainer = containerSize.width / containerSize.height;
  return ratioMedia < ratioContainer
    ? containerSize.width / (mediaSize.width || 1)
    : containerSize.height / (mediaSize.height || 1);
};

export const convertLatLngToArray = (
  location?: google.maps.LatLng | google.maps.LatLngLiteral
): AddressCoordinates | undefined => {
  if (!location) return undefined;

  if (location instanceof google.maps.LatLng) return [location.lng(), location.lat()];

  return [location.lng, location.lat];
};

export const convertArraytoLatLng = (location: number[]): google.maps.LatLng =>
  new google.maps.LatLng(location[1], location[0]);

export const convertLatLngToString = (location?: number[] | google.maps.LatLng): string => {
  if (!location) return "";

  if (location instanceof google.maps.LatLng) return [location.lng(), location.lat()].join(",");

  return location.join(",");
};

export const getCurrency = (language: string) => {
  if (language === "pt-BR") return "BRL";
  if (language === "en-US") return "USD";
  //TODO: Add all currency
  return "BRL";
};

export interface APIParamsFormated {
  toString(): string;
  getParamKey(): string | undefined;
  params: { [index: string]: string };
}

export const APIParamsToString = (params: any): APIParamsFormated => {
  if (Array.isArray(params) && params.length > 0)
    return {
      params: {},
      getParamKey: () => undefined,
      toString: () => params[0],
    };
  if (typeof params === "object") {
    const keys = Object.keys(params);
    const nParams: { [index: string]: string } = keys.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: Array.isArray(params[curr]) && params[curr].length > 0 ? params[curr][0] : params[curr],
      }),
      {}
    );

    const nParamsKeys = Object.keys(nParams);

    return {
      params: nParams,
      getParamKey: () => {
        // Return the first param key if exists
        if (nParamsKeys.length) return nParamsKeys[0] ?? "";
      },
      toString: () => {
        // Return the first error of the 'params'
        if (nParamsKeys.length) return nParams[nParamsKeys[0]] ?? "";
        return "";
      },
    };
  }
  return { params: {}, getParamKey: () => undefined, toString: () => "" };
};

export const openInRoutes = (routes: string[], parents: string[]) => {
  if (routes.length !== parents.length) return undefined;

  const getParent = (path: string) => {
    const index = routes.findIndex((r) => r === path);
    if (index !== -1) return parents[index];
    return "";
  };

  return {
    routes,
    parents,
    getParent,
  };
};

export const hourToDate = (hour: string) => {
  const h = validHour(hour);
  if (h) {
    const d = new Date();
    d.setHours(h.hours);
    d.setMinutes(h.minutes);
    return d;
  }
};

export const checkAPIErrorMessageDocumentInvalid = (message: string) =>
  /brazilian-|document\.?(\w+)?-invalid/.test(message);
