import { rem } from "polished";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import Text from "v3/components/common/Text";
import Button from "../../Button/Button";
import Input from "../../Form/Input/Input";
import Icon from "../../Icons/Icon";
import Link from "../../Link/Link";
import { ModalBodyRegister } from "../common";
import { Base } from ".";
import useTranslationV3 from "hooks/useTranslationV3";
import { LoadingStatus } from "core/api/definitions";
import { useDispatch } from "react-redux";
import { showAlert } from "core/alerts/actions";
import { APIParamsToString } from "utils/helpers";
import api from "core/api";
import { debounce } from "lodash";
import mqV3 from "utils/mediaQueriesV3";
import { parsePhoneNumber } from "react-phone-number-input";
import useCountry from "hooks/useCountry";
import StopWatch from "../../StopWatch/StopWatch";

interface ConfirmationProps extends Base {
  data: { email: string | undefined };
  newData: any;
  setModalStep: (prev: void) => void;
  timer?: number;
}

const ConfirmationStep = ({ onSubmit, toggleStep, loading, data, newData, setModalStep, timer }: ConfirmationProps) => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();
  const [requestingNewEmail, setRequestingNewEmail] = useState<LoadingStatus>("idle");
  const { country } = useCountry();
  const { email } = data;
  const onRequestNewCodeCallback = useCallback(
    debounce(async () => {
      if (!email) return;
      try {
        let phoneNumber = "";
        let phoneCountry = country;

        const phoneParsed = parsePhoneNumber(newData.phone as any);
        phoneNumber = phoneParsed?.nationalNumber ?? "";
        if (phoneParsed?.countryCallingCode) phoneCountry = `+${phoneParsed.countryCallingCode}`;

        const handleData = {
          name: newData?.name || "",
          email: newData?.email || "",
          contacts: [
            {
              country: phoneCountry,
              type: "phone",
              value: `${phoneNumber}`.replace(/[.|-]/g, ""),
            },
          ],
          password: newData?.password || "",
          password_confirmation: newData?.password_confirmation || "",
          accept_terms: newData.accept_terms,
          gt_18_years: newData.accept_terms,
          receive_emails: newData.acceptToReceiveEmails || false,
        };
        setRequestingNewEmail("loading");
        await api.users.sendEmailValidationCode(handleData);
        dispatch(showAlert("info", t("plain:Um novo código foi enviado para o seu email")));
      } catch (e) {
        const {
          response: { data },
        } = e;
        const params = data?.params;
        dispatch(showAlert("danger", `errors.${APIParamsToString(params).toString()}`));
      } finally {
        setRequestingNewEmail("ok");
      }
    }, 700),
    [email, dispatch, t, setRequestingNewEmail]
  );

  return (
    <ModalBodyRegister>
      <FormConfirmation onSubmit={onSubmit}>
        <ConfirmationInfo>
          <Text size="sm">{t("plain:Um código vai chegar no seu e-mail")}</Text>
          <EmailStyled size="lg" color="primary" tag="h2">
            {data.email}
          </EmailStyled>
        </ConfirmationInfo>
        <InputConfirmation type="tel" name="code" mask="9999" placeholder="____" minLength={4} maxLength={4} required />
        <Button color="primary" size="md" disabled={loading === "loading" || requestingNewEmail === "loading"}>
          {t("plain:Validar e Concluir")}
        </Button>
      </FormConfirmation>
      <StepsConfirmation>
        <StopWatch setModalStep={setModalStep} timer={timer ? timer : 5} />
        <Text size="sm">{t("plain:Se não chegou, confira os ítens abaixo")}</Text>
        <Ul>
          <Text tag="li" size="xs">
            <Icon icon="check" color="primary" />
            {t("plain:Confira se o email está correto")}
          </Text>
          <Text tag="li" size="xs">
            <Icon icon="check" color="primary" />
            {t("plain:Verifique sua caixa de spam")}
          </Text>
          <li>
            <Icon icon="check" color="primary" />
            <Link tag="button" onClick={onRequestNewCodeCallback} disabled={requestingNewEmail === "loading"}>
              {t("plain:Solicite um novo código")}
            </Link>
          </li>
          <li>
            <Icon icon="check" color="primary" />
            <Link tag="button" onClick={toggleStep}>
              {t("plain:Informe um outro email")}
            </Link>
          </li>
        </Ul>
      </StepsConfirmation>
    </ModalBodyRegister>
  );
};

export default ConfirmationStep;

const ConfirmationInfo = styled.div`
  text-align: center;
  & > p {
    margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
  }
`;

const EmailStyled = styled(Text)`
  ${({ theme }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: ${theme.v3.spacing.xl};
  `}
`;

const InputConfirmation = styled(Input)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
  font-size: ${({ theme }) => theme.v3.spacing.xl};
  letter-spacing: ${rem(10)};
  text-align: center;

  & ~ button {
    width: 100%;
  }
`;

const FormConfirmation = styled.form``;

const Ul = styled.ul`
  ${({ theme }) => css`
    text-align: left;
    margin: auto;
    padding: ${theme.v3.spacing.sm} 0 0 0;
    list-style: none;

    button {
      padding: 0;
      font-size: ${theme.v3.fontSize.xs};
    }

    ${mqV3.smUp} {
      padding-left: ${theme.v3.spacing.sm};
    }

    ${mqV3.xs} {
      max-width: 100%;
      margin: 0 auto;
    }
  `}
`;

const StepsConfirmation = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.v3.spacing.xl};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mqV3.xs} {
      display: flex;
      flex-direction: column;
    }
  `};
`;
