import { ReactComponent as IconError } from "assets/svgs/v3/error.svg";
import { ReactComponent as IconImportant } from "assets/svgs/v3/important.svg";
import { ReactComponent as IconInfo } from "assets/svgs/v3/info.svg";
import { ReactComponent as IconValidation } from "assets/svgs/v3/validation.svg";
import { ReactComponent as IconClose } from "assets/svgs/v3/close.svg";
import { ReactComponent as IconBell } from "assets/svgs/v3/bell.svg";
import { ReactComponent as IconFilter } from "assets/svgs/v3/filter.svg";
import { ReactComponent as IconVolunteer } from "assets/svgs/v3/volunteer.svg";
import { ReactComponent as IconCollection } from "assets/svgs/v3/collection.svg";
import { ReactComponent as IconMaterial } from "assets/svgs/v3/material.svg";
import { ReactComponent as IconShare } from "assets/svgs/v3/share.svg";
import { ReactComponent as IconWhatsapp } from "assets/svgs/v3/whatsapp.svg";
import { ReactComponent as IconFacebook } from "assets/svgs/v3/facebook.svg";
import { ReactComponent as IconTwitter } from "assets/svgs/v3/twitter.svg";
import { ReactComponent as IconGoogle } from "assets/svgs/v3/google.svg";
import { ReactComponent as IconLinkedin } from "assets/svgs/v3/linkedin.svg";
import { ReactComponent as IconCopy } from "assets/svgs/v3/copy.svg";
import { ReactComponent as IconPlus } from "assets/svgs/v3/plus.svg";
import { ReactComponent as IconMinus } from "assets/svgs/v3/minus.svg";
import { ReactComponent as IconClock } from "assets/svgs/v3/clock.svg";
import { ReactComponent as IconEye } from "assets/svgs/v3/eye.svg";
import { ReactComponent as IconEyeClosed } from "assets/svgs/v3/eye-closed.svg";
import { ReactComponent as IconCheck } from "assets/svgs/v3/check.svg";
import { ReactComponent as IconChevronDown } from "assets/svgs/v3/chevron-down.svg";
import { ReactComponent as IconHeart } from "assets/svgs/v3/heart.svg";
import { ReactComponent as IconHeartOutline } from "assets/svgs/v3/heart-outline.svg";
import { ReactComponent as IconComment } from "assets/svgs/v3/comment.svg";
import { ReactComponent as IconArrowLeft } from "assets/svgs/v3/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "assets/svgs/v3/arrow-right.svg";
import { ReactComponent as IconSearch } from "assets/svgs/v3/search.svg";
import { ReactComponent as IconLike } from "assets/svgs/v3/like.svg";
import { ReactComponent as IconLocal } from "assets/svgs/v3/local.svg";
import { ReactComponent as IconDonate } from "assets/svgs/v3/donate.svg";
import { ReactComponent as IconChevronLeft } from "assets/svgs/v3/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "assets/svgs/v3/chevron-right.svg";
import { ReactComponent as IconSignInAlt } from "assets/svgs/v3/sign-in-alt.svg";
import { ReactComponent as IconDashboard } from "assets/svgs/v3/dashboard.svg";
import { ReactComponent as IconChat } from "assets/svgs/v3/chat.svg";
import { ReactComponent as IconActionOpportunities } from "assets/svgs/v3/actions_opportunities.svg";
import { ReactComponent as IconPage } from "assets/svgs/v3/page.svg";
import { ReactComponent as IconVolunteerUser } from "assets/svgs/v3/volunteer_user.svg";
import { ReactComponent as IconMenu } from "assets/svgs/v3/menu.svg";
import { ReactComponent as IconSettings } from "assets/svgs/v3/settings.svg";
import { ReactComponent as IconAdd } from "assets/svgs/v3/add.svg";
import { ReactComponent as Star } from "assets/svgs/v3/star.svg";
import { ReactComponent as Chart } from "assets/svgs/v3/chart.svg";
import { ReactComponent as Ranking } from "assets/svgs/v3/ranking.svg";

// ODS
import { ReactComponent as ODS01 } from "assets/svgs/ods-icons/ods01.svg";
import { ReactComponent as ODS02 } from "assets/svgs/ods-icons/ods02.svg";
import { ReactComponent as ODS03 } from "assets/svgs/ods-icons/ods03.svg";
import { ReactComponent as ODS04 } from "assets/svgs/ods-icons/ods04.svg";
import { ReactComponent as ODS05 } from "assets/svgs/ods-icons/ods05.svg";
import { ReactComponent as ODS06 } from "assets/svgs/ods-icons/ods06.svg";
import { ReactComponent as ODS07 } from "assets/svgs/ods-icons/ods07.svg";
import { ReactComponent as ODS08 } from "assets/svgs/ods-icons/ods08.svg";
import { ReactComponent as ODS09 } from "assets/svgs/ods-icons/ods09.svg";
import { ReactComponent as ODS10 } from "assets/svgs/ods-icons/ods10.svg";
import { ReactComponent as ODS11 } from "assets/svgs/ods-icons/ods11.svg";
import { ReactComponent as ODS12 } from "assets/svgs/ods-icons/ods12.svg";
import { ReactComponent as ODS13 } from "assets/svgs/ods-icons/ods13.svg";
import { ReactComponent as ODS14 } from "assets/svgs/ods-icons/ods14.svg";
import { ReactComponent as ODS15 } from "assets/svgs/ods-icons/ods15.svg";
import { ReactComponent as ODS16 } from "assets/svgs/ods-icons/ods16.svg";
import { ReactComponent as ODS17 } from "assets/svgs/ods-icons/ods17.svg";
import { ReactComponent as ODSONU } from "assets/svgs/ods-icons/ods-onu.svg";

const icons = {
  error: IconError,
  important: IconImportant,
  info: IconInfo,
  validation: IconValidation,
  close: IconClose,
  bell: IconBell,
  filter: IconFilter,
  volunteer: IconVolunteer,
  material: IconMaterial,
  collection: IconCollection,
  share: IconShare,
  whatsapp: IconWhatsapp,
  facebook: IconFacebook,
  google: IconGoogle,
  twitter: IconTwitter,
  linkedin: IconLinkedin,
  copy: IconCopy,
  plus: IconPlus,
  minus: IconMinus,
  heart: IconHeart,
  heartOutline: IconHeartOutline,
  clock: IconClock,
  eye: IconEye,
  eyeClosed: IconEyeClosed,
  check: IconCheck,
  chevronDown: IconChevronDown,
  comment: IconComment,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  search: IconSearch,
  like: IconLike,
  local: IconLocal,
  donate: IconDonate,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  signInAlt: IconSignInAlt,
  dashboard: IconDashboard,
  chat: IconChat,
  actionsOpportunities: IconActionOpportunities,
  page: IconPage,
  volunteerUser: IconVolunteerUser,
  menu: IconMenu,
  settings: IconSettings,
  add: IconAdd,
  star: Star,
  chart: Chart,
  ranking: Ranking,

  //ODS
  ODS01: ODS01,
  ODS02: ODS02,
  ODS03: ODS03,
  ODS04: ODS04,
  ODS05: ODS05,
  ODS06: ODS06,
  ODS07: ODS07,
  ODS08: ODS08,
  ODS09: ODS09,
  ODS10: ODS10,
  ODS11: ODS11,
  ODS12: ODS12,
  ODS13: ODS13,
  ODS14: ODS14,
  ODS15: ODS15,
  ODS16: ODS16,
  ODS17: ODS17,
  ODSONU: ODSONU,
};

export type IconType = keyof typeof icons;

export default icons;
