import { BaseTitle } from "components/common/BaseTitle";
import { TrackHorizontal, TrackVertical } from "components/common/style";
import SupportText from "components/common/SupportText";
import { CustomInput as Input } from "components/Form";
import { ODSType } from "components/Icon/ODSIcon";
import Loading from "components/Loading/Loading";
import { ActionCreateResponseData } from "core/api/actions/create";
import { ValidationError } from "core/api/definitions";
import { create } from "core/wizard/create/action";
import { useReduxSelector } from "hooks/useReduxSelector";
import { causes as causesSource, causesChile } from "pages/ListActions/DataOfFilters";
import { rem } from "polished";
import React, { useEffect, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { ListContainer, ListItem } from "wizard/components/common/list";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { TitleContainer } from "wizard/components/Layout/Content";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { PageEvents } from "../definitions/commonTypes";
import FormWizard from "./FormWizard";
import Text from "v3/components/common/Text";
import { default as BaseCheckbox } from "v3/components/Checkbox/Checkbox";
import ODSIcon from "v3/components/ODSIcon/ODSIcon";
import routes from "routes";
import { reverse } from "named-urls";
import { mq } from "utils/mediaQueries";
import useCountry from "hooks/useCountry";

export interface CauseSet {
  [key: string]: boolean;
}

interface CausesPageProps extends PageEvents<CauseSet> {
  title: string;
  causes: CauseSet;
}

type ODSByCause = { [key: string]: ODSType[] };

const getOdsByCause = (data: ActionCreateResponseData): ODSByCause =>
  data?.causes?.reduce(
    (acc, curr) => ({ ...acc, [curr.slug]: curr?.ods_data?.map((ods) => ods.reference) }),
    {} as ODSByCause
  );

const CausesPage = ({ title, causes, onChange, onContinue, onBack, error }: CausesPageProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasError, setHasError] = useState("");

  const causesSize = useMemo(() => Object.values(causes).filter((k) => k).length, [causes]);
  const limited = causesSize > 2;
  const disabled = causesSize === 0;

  const { iso3166_1 } = useCountry();

  const listCauses = iso3166_1 === "cl" ? causesChile : causesSource;

  const { causesResponse, loading } = useReduxSelector((state) => ({
    causesResponse: state.wizard.create.action.support.data,
    loading: state.wizard.create.action.support.loading,
  }));

  const odsBySlug = useMemo(() => getOdsByCause(causesResponse || { causes: [] }), [causesResponse]);

  useEffect(() => {
    (async () => {
      await dispatch(create());
    })();
  }, [dispatch]);

  return (
    <FormWizard
      onContinue={() => {
        if (onContinue)
          onContinue(Object.keys(causes).reduce((acc, curr) => (causes[curr] ? { ...acc, [curr]: true } : acc), {}));
      }}
    >
      <WizardContainer>
        <CustomTitleContainer>
          <Helmet title={title} />
          <CustomText tag="h1" size="lg">
            {title}
          </CustomText>
          <Text tag="span" size="sm" color="neutralLight">
            {t("wizard.pages.causes.description")}
          </Text>
        </CustomTitleContainer>
        <ListContainer id="causesList">
          <Scrollbars
            renderTrackVertical={() => <TrackVertical top={0} right={35} />}
            renderThumbHorizontal={() => <TrackHorizontal hidden />}
          >
            {loading === "loading" ? (
              <Loading />
            ) : (
              <ul>
                {listCauses.map((cause) => {
                  return (
                    <ListItem key={cause.value}>
                      <Checkbox
                        id={cause.value}
                        checked={!!causes[cause.value]}
                        label={cause.label}
                        onValueChange={() => {
                          if ((!limited && onChange) || (onChange && limited && causes[cause.value])) {
                            onChange({ ...causes, [cause.value]: !causes[cause.value] });
                            setHasError("");
                          } else {
                            setHasError(t("error.causeLimit"));
                          }
                        }}
                      />
                      <CauseBrand>
                        {odsBySlug[cause.value]?.map((ods) => {
                          const r = ods.match(/([0-9]{1,2}$)/);
                          if (r && r.length >= 1)
                            return (
                              <a
                                key={`${cause.value}-${ods}`}
                                href={reverse(routes.ods.detail, { code: parseInt(r[0]) })}
                                target="_blank"
                              >
                                <ODSIcon tag="li" icon={ods} size="sm" />
                              </a>
                            );

                          return <ODSIcon key={`${cause.value}-${ods}`} tag="li" icon={ods} size="sm" />;
                        })}
                      </CauseBrand>
                    </ListItem>
                  );
                })}
              </ul>
            )}
          </Scrollbars>
        </ListContainer>
        {(hasError || error) && <WizardTrans>{(hasError || error) as ValidationError}</WizardTrans>}
      </WizardContainer>
      <ActionsFooter continueDisabled={disabled} onBack={onBack} />
    </FormWizard>
  );
};

export default CausesPage;

const Checkbox = styled(BaseCheckbox)`
  margin-right: ${({ theme }) => theme.v3.spacing.xs};

  .custom-control-label {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      font-weight: 500;
    }
  }
`;

const CauseBrand = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0 -3px;

  & > li,
  & > a {
    margin: 3px;
  }
`;

const CustomText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
`;

const CustomTitleContainer = styled(TitleContainer)`
  ${mq.xsDown} {
    h1,
    span {
      text-align: center;
    }
  }
`;
