import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { ValidationError } from "core/api/definitions";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { CardCol, CardContainer } from "wizard/components/Card";
import CardItem, { CardItemProps } from "wizard/components/Card/CardItem";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { TitleContainer } from "wizard/components/Layout/Content";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { PageEvents } from "../definitions/commonTypes";

export type VacancyType = "remote" | "presential";

interface VacancyTypePageProps extends PageEvents<VacancyType> {
  vacancyType?: VacancyType;
}

const SelectVacancyTypePage: React.FC<VacancyTypePageProps> = ({
  vacancyType,
  onBack,
  onContinue,
  onChange,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.vacancyType.title")} />
          <BaseTitle size="md">{t("wizard.pages.vacancyType.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.vacancyType.description")}</SupportText>
        </TitleContainer>
        <CustomCardContainer id="vacancyTypeContainer">
          <CustomCard
            withCheckbox
            icon="map-marker-smile"
            selected={vacancyType === "presential"}
            onClick={() => {
              if (onChange) onChange("presential");
            }}
            title={t("wizard.pages.vacancyType.options.presential")}
            content={t("wizard.pages.vacancyType.text.presential")}
          />
          <CustomCard
            withCheckbox
            icon="laptop-house"
            selected={vacancyType === "remote"}
            onClick={() => {
              if (onChange) onChange("remote");
            }}
            title={t("wizard.pages.vacancyType.options.remote")}
            content={t("wizard.pages.vacancyType.text.remote")}
          />
        </CustomCardContainer>
        {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={!vacancyType}
        onContinue={() => {
          if (onContinue && vacancyType) onContinue(vacancyType);
        }}
      />
    </Fragment>
  );
};

export default SelectVacancyTypePage;

const CustomCardContainer = styled(CardContainer)`
  margin: 0 auto;
  max-width: 450px;
`;

const CustomCard = (props: CardItemProps) => (
  <CardCol sm={6}>
    <CardItem {...props} />
  </CardCol>
);
