import http from "config/http";
import { ImageData, ResponseStatus, ResponseWithParams } from "../definitions";
import { Conditionals } from "../definitions";
import { ProjectType } from "../definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";
import { SocialNetworkData } from "wizard/pages/SocialNetworksPage";

interface BaseStoreNewProjectRequest {
  locations: Address;
  images_data?: {
    cover?: ImageData;
    profile: ImageData;
  };
}

export interface StoreNewProjectOrganizationRequest extends BaseStoreNewProjectRequest {
  name: string;
  description: string;
  type: ProjectType;
  social_network: SocialNetworkData;
}

export interface StoreNewProjectPersonalRequest extends BaseStoreNewProjectRequest {
  resume: string;
  type: "personal";
  conditionals: Conditionals;
}

export type StoreNewProjectResponse = ResponseWithParams<ResponseStatus, { slug: string; _id: string }>;

const storeNewProject = async (
  data: StoreNewProjectOrganizationRequest | StoreNewProjectPersonalRequest
): Promise<AxiosResponse<StoreNewProjectResponse>> => {
  return http.post<StoreNewProjectResponse>(`/projects`, data);
};

export default storeNewProject;
