import React, { useCallback, useState, useContext, useEffect } from "react";
import SupportText from "components/common/SupportText";
import { BaseTitle } from "components/common/BaseTitle";
import { InputLabeledCodeStyled, LargeButton } from "wizard/components/common/styles";
import styled, { css, ThemeContext } from "styled-components/macro";
import { rem } from "polished";
import Icon from "components/Icon/Icon";
import mediaQueries from "utils/mediaQueries";
import { useTranslation } from "react-i18next";
import FormWizard from "wizard/pages/common/FormWizard";
import { Link, useLocation } from "react-router-dom";
import SupportFeedback from "components/common/SupportFeedback";
import BaseCentralized from "wizard/components/Layout/BaseCentralized";
import { ValidationError } from "core/api/definitions";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { Helmet } from "react-helmet";

interface ValidateEmailPageUserData {
  email: string;
  name: string;
}

interface ValidateEmailPageProps {
  loading?: boolean;
  userData: ValidateEmailPageUserData;
  error?: ValidationError;
  onNewEmail: string;
  onContinue: (code: string) => void;
  onRequestCode: () => void;
}

interface LocalFeedback {
  message: "";
  type: "success" | "error" | undefined;
}

/** @deprecated */
const ValidateEmailPage = ({
  loading,
  userData,
  error,
  onNewEmail,
  onContinue,
  onRequestCode,
}: ValidateEmailPageProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { state } = useLocation();

  const [code, setCode] = useState("");
  const [localFeedback, setLocalFeedback] = useState<LocalFeedback>({
    message: "",
    type: undefined,
  });

  const onContinueCallback = useCallback(async () => {
    onContinue(code);
  }, [onContinue, code]);

  const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  }, []);

  const { type: localType } = localFeedback;
  useEffect(() => {
    let timeID: number | NodeJS.Timeout = 0;
    if (localType) timeID = setTimeout(() => setLocalFeedback({ message: "", type: undefined }), 5000);
    return () => {
      if (timeID) clearTimeout(timeID as number);
    };
  }, [localType]);

  return (
    <CustomBaseCentralized>
      <Form onContinue={onContinueCallback}>
        <Helmet title={t("wizard.pages.validateEmail.title")} />
        <SupportText size="md">{t("wizard.pages.validateEmail.info")}</SupportText>
        <Title size="md">{(userData && userData.email) || ""}</Title>
        {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
        {localFeedback.type && (
          <SupportFeedback color={localFeedback.type === "error" ? "quaternary" : "primary"}>
            {localFeedback.message}
          </SupportFeedback>
        )}
        <InputLabeledCodeStyled
          type="tel"
          autoFocus
          required
          maxLength={4}
          minLength={4}
          placeholder="0000"
          value={code}
          onChange={onChangeCallback}
          /*
          icon={["fal", "clone"]}
          onClickIcon={() => console.log("paste")}
          */
        />
        <LargeButton type="submit" block disabled={loading}>
          {t("wizard.pages.validateEmail.submit")}
        </LargeButton>
      </Form>
      <Steps>
        <SupportText tag="p" size="md">
          {t("wizard.pages.validateEmail.steps.title")}
        </SupportText>
        <ul>
          <li>
            <Icon type="check" />
            {t("wizard.pages.validateEmail.steps.correctEmail")}
          </li>
          <li>
            <Icon type="check" />
            {t("wizard.pages.validateEmail.steps.spam")}
          </li>
          <li>
            <Icon type="check" style={{ fill: loading ? theme.colors.support_text : theme.colors.primary }} />
            <button disabled={loading} onClick={onRequestCode}>
              {t("wizard.pages.validateEmail.steps.newCode")}
            </button>
          </li>
          <li>
            <Icon type="check" />
            <Link to={{ pathname: onNewEmail, state }}>{t("wizard.pages.validateEmail.steps.newEmail")}</Link>
          </li>
        </ul>
      </Steps>
    </CustomBaseCentralized>
  );
};

export default ValidateEmailPage;

const CustomBaseCentralized = styled(BaseCentralized)`
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;

const Form = styled(FormWizard)`
  align-self: center;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 30px 0 50px;
  }
`;

const Steps = styled.div`
  align-self: center;
  ul {
    padding: 0;
    padding-left: 35px;
    list-style: none;

    li,
    button,
    a {
      color: #657d9b;
    }

    button,
    a {
      text-decoration: underline;
      padding: 0;
      margin: 0;
      border: 0;
      outline: none;
      background-color: transparent;
      transition: margin 0.4s;
    }

    li {
      font-size: ${rem(13)};
      line-height: ${rem(26)};
      letter-spacing: 0;

      :hover > button,
      :hover > a {
        margin-left: 10px;
      }

      svg {
        fill: ${({ theme }) => theme.colors.primary};
        margin-right: 10px;
        width: 10px;
      }
    }
  }
`;

const Title = styled(BaseTitle)`
  margin-bottom: 40px;
  ${mediaQueries.xs(css`
    font-size: ${rem(18)};
    line-height: ${rem(30)};
  `)}
`;
