import React from "react";
import { PageEvents } from "../definitions/commonTypes";
import SimpleInputCepPage from "wizard/pages/common/SimpleInputCepPage";
import { LocationWithAddress } from "wizard/components/Input/PlacesAutocompleteResolved";
import { useTranslation } from "react-i18next";

interface InputProjectAddressPageProps extends PageEvents<LocationWithAddress> {
  location?: LocationWithAddress;
}

const InputProjectAddressPage = ({ location, ...props }: InputProjectAddressPageProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <SimpleInputCepPage
      title={t("wizard.stepsWizard.wizardWhichTownProject.title").toString()}
      placeholder={t("wizard.stepsWizard.wizardWhichTownProject.input.title").toString()}
      clue={t("wizard.stepsWizard.wizardWhichTownProject.cue")}
      value={location}
      continueDisabled={!!props.error || !location?.address}
      {...props}
    />
  );
};

export default InputProjectAddressPage;
