import { Address } from "core/api/definitions";
import { convertLatLngToArray } from "./helpers";

export type LocationWithAddress = Address & { address: string };

type AddressInfoType =
  | "route"
  | "street_number"
  | "postal_code"
  | "locality"
  | "sublocality"
  | "country"
  | "administrative_area_level_1"
  | "administrative_area_level_2"
  | "administrative_area_level_3"
  | "administrative_area_level_4"
  | "locality";

const infoExtract = (address: google.maps.GeocoderAddressComponent[], type: AddressInfoType) =>
  address.find((a) => a.types.includes(type));

const infoExtractIndex = (address: google.maps.GeocoderAddressComponent[], type: AddressInfoType) =>
  address.findIndex((a) => a.types.includes(type));

export const formatGeocoderResult = (res: google.maps.GeocoderResult): LocationWithAddress => {
  const countryIdx = infoExtractIndex(res.address_components, "country"),
    country = res.address_components[countryIdx]?.long_name,
    stateIndex = infoExtractIndex(res.address_components, "administrative_area_level_1"),
    state = (res.address_components[stateIndex] ?? res.address_components[countryIdx - 1])?.short_name,
    city = (
      infoExtract(res.address_components, "locality") ??
      infoExtract(res.address_components, "administrative_area_level_3") ??
      infoExtract(res.address_components, "administrative_area_level_4") ??
      res.address_components[stateIndex - 1]
    )?.long_name;

  return {
    street: infoExtract(res.address_components, "route")?.long_name || "",
    number: infoExtract(res.address_components, "street_number")?.long_name || "",
    district: infoExtract(res.address_components, "sublocality")?.long_name || "",
    coordinates: convertLatLngToArray(res.geometry.location),
    zipcode: infoExtract(res.address_components, "postal_code")?.long_name || "",
    address: res.formatted_address,
    city,
    state,
    country,
  };
};
