import { createAction, createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { Origin } from "core/api/definitions";
import { OriginResponse, OriginRequest } from "core/api/origin";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { ThemeColor } from "core/api/definitions";

type OriginGetResponse = OriginResponse | ErrorResponse;

export const get = asyncThunk<OriginGetResponse, OriginRequest>("pages/origin/get", (objt) => {
  if ((window as any).$origin) {
    return new Promise((resolve, _) =>
      resolve({
        data: (window as any).$origin,
        status: 200,
        statusText: "ok",
        headers: [],
        config: {},
      })
    );
  }
  return api.origin(objt);
});

export const updateColor = createAction<{ color: ThemeColor; value: string }>("pages/origin/updateColor");

export const restoreColors = createAction("pages/origin/restoreColors");

type IState = RequestableState &
  OriginGetResponse & {
    originalResponse?: Origin;
  };

const originSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelValidationProject",
  initialState: {
    success: false,
    loading: "idle",
  },
  reducers: {
    [updateColor.type]: (state, { payload }: PayloadAction<{ color: ThemeColor; value: string }>) => {
      (state.params as Origin).theme.colors[payload.color] = payload.value;
    },
    [restoreColors.type]: (state) => {
      (state.params as Origin).theme.colors = state.originalResponse!.theme.colors;
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, get, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.params = action.payload.params;
          state.originalResponse = action.payload.params;
          (window as any).$shareable_domain = action.payload.params.shareable_domain;
        },
      },
    ]);
  },
});

export default originSlice;
