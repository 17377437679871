import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { ValidationErrorSet } from "core/api/definitions";
import React, { Fragment, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { TitleContainer } from "wizard/components/Layout/Content";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import DonationListItems, { DonationListDataProps, MaterialItemInfo } from "./DonationListItems";
import { Helmet } from "react-helmet";

interface DonationListDataPageProps extends DonationListDataProps {
  onChangeItem: (item: MaterialItemInfo, idx: number) => void;

  continueDisabled?: boolean;

  onContinue: string | (() => void);
  onBack: string | (() => void);

  error?: string | ValidationErrorSet;
}

const DonationListItemsPage: React.FC<DonationListDataPageProps> = ({
  items,
  onAddItem,
  onRemoveItem,
  onContinue,
  onBack,
  continueDisabled,
  onChangeItem,
  error,
}) => {
  const { t } = useTranslation();

  const itemErrorMemorized = useMemo(() => {
    const itemError = Object.keys(error || {}).find((itemKey) => /\w\.([0-9]{1,3})\.\w/.test(itemKey));
    return itemError && itemError?.split(".").length > 2 ? parseInt(itemError?.split(".")[1]) : -1;
  }, [error]);

  const onEditItemHandler = useCallback(
    (item: MaterialItemInfo, idx?: number) => {
      if (item && idx !== undefined) onChangeItem(item, idx);
    },
    [onChangeItem]
  );

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.donationListDataItem.title")} />
          <BaseTitle size="md">{t("wizard.pages.donationListDataItem.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.donationListDataItem.subtitle")}</SupportText>
        </TitleContainer>
        <DonationListItems
          items={items}
          itemError={itemErrorMemorized}
          onAddItem={onAddItem}
          onRemoveItem={onRemoveItem}
          onEditItem={onEditItemHandler}
          autoFocus={true}
        />
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={continueDisabled || itemErrorMemorized !== -1}
        onContinue={onContinue}
      />
    </Fragment>
  );
};

export default DonationListItemsPage;
