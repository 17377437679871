import Button from "components/Button/Button";
import Divider from "components/common/Divider";
import SupportFeedback from "components/common/SupportFeedback";
import SupportText from "components/common/SupportText";
import Icon from "components/Icon/Icon";
import { ValidationError } from "core/api/definitions";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { validHour } from "utils/helpers";
import mediaQueries, { mq } from "utils/mediaQueries";
import InputLabeled from "wizard/components/Input/InputLabeled";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { PageEvents } from "wizard/pages/definitions/commonTypes";
import usePlatformLanguage from "hooks/usePlatformLanguage";

export type SubscribeHour = {
  start?: string;
  end?: string;
};

export type HourError = "start" | "end" | "limit" | "overlap" | "empty" | undefined;
interface SubscribeHoursProps extends Pick<PageEvents, "error"> {
  onChange?(hours: SubscribeHour[]): void;
  onError?(error?: string): void;
  hours: SubscribeHour[];
  disabled?: boolean;
  autoFocus?: boolean;
}

const validateHour = (language: string, newHour: SubscribeHour, hours: SubscribeHour[] = []): HourError => {
  const start = validHour(newHour.start || "");
  const end = validHour(newHour.end || "");

  if (start === undefined || end === undefined) {
    return start === undefined ? "start" : "end";
  }

  const startDate = new Date();
  startDate.setHours(start.hours, start.minutes, 0);

  const endDate = new Date();
  endDate.setHours(end.hours, end.minutes, 0);

  let diff = endDate.getHours() - startDate.getHours();
  if (diff < 0) {
    diff += 24;
    endDate.setDate(endDate.getDate() + 1);
  }

  if ((language == "pt-br" && diff > 6) || (diff === 6 && end.minutes !== 0)) return "limit";
};

const SubscribeHours = ({ hours, onChange, onError, disabled, autoFocus, error }: SubscribeHoursProps) => {
  const { t } = useTranslation();
  const { language } = usePlatformLanguage();

  const [hour, setHour] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });
  const [hasError, setHasError] = useState<HourError>();

  const submitHandlerCallback = useCallback(() => {
    if (!onChange) return;

    const validateResp = validateHour(language.toLowerCase(), hour, hours);
    if (validateResp) return setHasError(validateResp);

    setHasError(undefined);
    onChange([
      ...hours,
      {
        start: hour.start,
        end: hour.end,
      },
    ]);
    setHour({ start: "__:__", end: "__:__" });
    const firstInput = document.getElementById("startHour") as HTMLInputElement;
    if (firstInput) {
      firstInput.focus();
      setTimeout(() => {
        if (firstInput.setSelectionRange) firstInput.setSelectionRange(0, firstInput.value.length);
      }, 100);
    }
  }, [hour, hours]);

  useEffect(() => {
    if (hasError && onError) onError(hasError);
  }, [hasError, onError]);

  return (
    <Fragment>
      <InputsContainer>
        <Flex>
          <SupportText size="md">{t("wizard.pages.vacancyHour.form.hourStart")}</SupportText>
          <InputHour
            data-cy="subscribe-hour-start"
            autoFocus={autoFocus}
            id="startHour"
            type="tel"
            name="start"
            mask="99:99"
            value={hour.start}
            placeholder="__:__"
            invalid={hasError === "start"}
            disabled={disabled}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { target } = e;
              if (hasError) setHasError(undefined);
              setHour((prev) => ({ ...prev, start: target?.value || "" }));
            }}
          />
        </Flex>
        <Flex>
          <SupportText size="md">{t("wizard.pages.vacancyHour.form.hourEnd")}</SupportText>
          <InputHour
            data-cy="subscribe-hour-end"
            type="tel"
            name="end"
            mask="99:99"
            value={hour.end}
            placeholder="__:__"
            invalid={hasError === "end"}
            disabled={disabled}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { target } = e;
              if (hasError) setHasError(undefined);
              setHour((prev) => ({ ...prev, end: target?.value || "" }));
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 13 && !disabled) submitHandlerCallback();
            }}
          />
        </Flex>
        <Flex>
          <Button
            disabled={disabled}
            type="button"
            onClick={(e: React.MouseEvent) => {
              if (e) e.preventDefault();
              submitHandlerCallback();
            }}
          >
            {t("common.add")}
          </Button>
        </Flex>
      </InputsContainer>
      {(hasError || error) && (
        <ErrorsWrapper>
          {hasError && (
            <CustomSupportFeedback data-cy="generic-error">
              {t(`wizard.pages.vacancyHour.errors.${hasError}`)}
            </CustomSupportFeedback>
          )}
          {error && !hasError && <WizardTrans textAlign="left">{error as ValidationError}</WizardTrans>}
        </ErrorsWrapper>
      )}
      <Divider after={30} before={20} />
      <SupportText>
        {hours.length !== 0 ? t("wizard.pages.vacancyHour.form.withHours") : t("wizard.pages.vacancyHour.withoutHours")}
      </SupportText>
      <Ul data-cy="subscribe-hour-list">
        <Scrollbars>
          {hours.map((hour, k) => {
            const { start, end } = hour;
            return start && end ? (
              <li key={k}>
                {!disabled && (
                  <button
                    onClick={() => {
                      if (onChange) onChange(hours.filter((h, filterIdx) => filterIdx !== k));
                    }}
                  >
                    <Icon type="close" />
                  </button>
                )}
                <SupportText size="md">
                  {t("wizard.pages.vacancyHour.hours", {
                    start: start,
                    end: end,
                  })}
                </SupportText>
              </li>
            ) : null;
          })}
        </Scrollbars>
      </Ul>
    </Fragment>
  );
};

export default SubscribeHours;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const InputsContainer = styled.div`
  display: flex;
  padding-top: 20px;
  background-color: transparent;

  ${Flex} > div {
    margin-top: 0;
  }

  ${mq.smUp} {
    & > ${Flex}:nth-child(2) {
      margin-left: 10px;
    }
  }

  button {
    height: 54px;
    padding: 0 25px;
    margin-left: 20px;
  }

  ${mediaQueries.sm(css`
    flex-wrap: wrap;

    & > ${Flex}:last-child {
      width: 100%;
      button {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
    }
  `)};

  ${mediaQueries.xs(css`
    justify-content: space-between;
    ${Flex} {
      margin-top: 10px;
    }
  `)};
`;

const InputHour = styled(InputLabeled)`
  width: 80px;
  padding: 15px !important;
  margin-left: 15px;
  background-image: none !important;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 165px;
  overflow-y: auto;

  li {
    position: relative;
    display: flex;
    align-items: center;
    width: 95%;
    height: 60px;
    border: 1px solid #cdd6e2;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 20px;

    button {
      position: absolute;
      display: flex;
      border: 0;
      background-color: transparent;
      right: 20px;
      width: 10px;
      height: 10px;
      padding: 0;

      svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.colors.support_text};
      }
    }
  }
`;

const ErrorsWrapper = styled.div``;

const CustomSupportFeedback = styled(SupportFeedback)`
  text-align: left;
`;
