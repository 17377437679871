import React, { useState, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import mediaQueries, { mq } from "utils/mediaQueries";
import { CROP_CONFIG, CROP_SIZE } from "utils/constants";
import { PageEvents, BaseProps, PhotoFile } from "wizard/pages/definitions/commonTypes";
import SupportText from "components/common/SupportText";
import ActionsFooter from "wizard/components/Layout/ActionsFooter";
import { Area, Point } from "react-easy-crop/types";
import { croppedImg, handleRejectFile, FileErrorType } from "utils/helpers";
import SupportFeedback from "components/common/SupportFeedback";
import { WizardContainer } from "wizard/components/Layout/Wizard";
import { BaseTitle } from "components/common/BaseTitle";
import { TitleContainer } from "wizard/components/Layout/Content";
import { Centered } from "wizard/components/common/styles";
import WizardTrans from "wizard/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import ImageProfileCropper from "components/common/ImageProfileCropper";
import { CropInfo } from "components/Cropper";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import Text from "v3/components/common/Text";

export type InputProfileImagePageProps = Omit<BaseProps, "placeholder"> &
  PageEvents<PhotoFile | undefined> & {
    file?: PhotoFile;
    alt?: string;
    continueLabel?: string;
    continueDisabled?: boolean;
  };

const SimpleProfileImage: React.FC<InputProfileImagePageProps> = ({
  title,
  clue,
  file,
  error: genericError,
  onChange,
  onContinue,
  onBack,
  continueLabel,
  continueDisabled,
}) => {
  const { t } = useTranslation();

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ height: 0, width: 0, x: 0, y: 0 });
  const [error, setError] = useState<string>("");

  const loadData = useLocalStorageRead(
    "creating.profile.profileCrop",
    () => ({ crop: CROP_CONFIG.crop, zoom: CROP_CONFIG.zoom }),
    "session"
  ) ?? { crop: CROP_CONFIG.crop, zoom: CROP_CONFIG.zoom };

  const [crop, setCrop] = useState<Point>(loadData.crop);
  const [zoom, setZoom] = useState(loadData.zoom);

  useLocalStorageWrite({ crop, zoom }, "creating.profile.profileCrop", "session", 500);

  const onCropComplete = useCallback((croppedAreaPixels: CropInfo) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!file?.source) return onContinue && onContinue(undefined);

    try {
      const croppedImage = await croppedImg(file.source, croppedAreaPixels, "BASE64");
      if (croppedImage && onContinue && file) onContinue({ ...file, cropped: croppedImage });
    } catch (e) {
      setError(t("wizard.error.image.crop"));
    }
  }, [t, croppedAreaPixels, onContinue, file]);

  const validateImageCallback = (file: HTMLImageElement) => {
    if (file.naturalWidth < CROP_SIZE || file.naturalHeight < CROP_SIZE) {
      setError(t("wizard.error.image.resolution", { width: CROP_SIZE, height: CROP_SIZE }));
      return false;
    }
    return true;
  };

  const onRejectedFileCallback = (rejectedFiles: File[], errorType: FileErrorType) => {
    if (errorType === "none") setError("");
    handleRejectFile(rejectedFiles, (errorType) => {
      if (errorType === "size") setError(t("wizard.error.image.size"));
      if (errorType === "type") setError(t("wizard.error.image.type"));
    });
  };

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Text tag="h1" size="lg">
            {t(`plain:${title}`)}
          </Text>
        </TitleContainer>
        <Container>
          <ImageProfileCropper
            file={file}
            onRejectedFile={onRejectedFileCallback}
            validateImage={validateImageCallback}
            onCropComplete={onCropComplete}
            onChange={onChange}
            crop={crop}
            zoom={zoom}
            onCropChanged={setCrop}
            onZoomChanged={setZoom}
          />
          {genericError && <WizardTrans>{genericError as ValidationError}</WizardTrans>}
          {error && <SupportFeedback>{error}</SupportFeedback>}
        </Container>
        <Centered>
          <CustomText tag="span" size="sm" color="neutralLight">
            {clue ? clue : t("wizard.common.infoImage")}
          </CustomText>
          <CustomText tag="span" size="sm" color="neutralLight">
            {clue ? clue : t("wizard.common.infoImageUpload")}
          </CustomText>
        </Centered>
      </WizardContainer>
      <ActionsFooter
        continueLabel={continueLabel}
        continueDisabled={continueDisabled}
        onContinue={showCroppedImage}
        onBack={onBack}
      />
    </Fragment>
  );
};

export default SimpleProfileImage;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.v3.spacing.lg} 0;

  ${mediaQueries.sm(css`
    flex-direction: column;
    align-items: center;

    > div {
      margin: 0;
    }
  `)}
`;

const CustomText = styled(Text)`
  ${mq.xsDown} {
    text-align: center;
  }
`;
