import React from "react";
import { PageEvents, PhotoFile } from "../definitions/commonTypes";
import { useTranslation } from "react-i18next";
import SimpleProfileImage from "wizard/pages/common/SimpleProfileImage";

interface MMatchmakerPhotoProps extends PageEvents<PhotoFile | undefined> {
  file?: PhotoFile;
}

const MatchmakerPhotoPage = ({ file, loading, ...props }: MMatchmakerPhotoProps): JSX.Element => {
  const { t } = useTranslation();
  const continueLabel = file ? t("common.continue") : t("wizard.common.skipStep");
  return (
    <SimpleProfileImage
      file={file}
      continueLabel={continueLabel}
      title="Sua imagem de perfil"
      continueDisabled={loading}
      {...props}
    />
  );
};

export default MatchmakerPhotoPage;
