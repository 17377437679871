import { Origin } from "core/api/definitions";
import { store } from "core/index";
import routes from "routes";
import Validator from "validatorjs";

const projectType: Validator.RegisterCallback = (value: string | number | boolean) => {
  const projectTypeData = (store.getState()?.pagesNew?.origin?.params as Origin)?.project_type_data;
  const organization = Object.keys(projectTypeData?.organization ?? {});
  const others = Object.keys(projectTypeData?.others ?? {});

  return !![...organization, ...others].find((o) => o === value);
};

Validator.register("projectType", projectType, "option-invalid");

const rules = {
  type: "required|projectType",
  name: "required|string|min:3|max:50",
  description: "required|string|min:180|max:1000",
  "locations.country": "required",
  "locations.state": "required",
  "locations.city": "required",
  "locations.coordinates": "array",

  // ESTA VALIDACAO ABAIXO SOMENTE PARA PROJETOS DIFERENTES DE PROJETOS PESSOAIS
  "images_data.cover.original": "required",
  "images_data.profile.original": "required",
};

const projectValidation = {
  rules: rules,
  rulesPersonalProject: {
    ...rules,
    "conditionals.causes": "required|conditionals:causes",
    "conditionals.interests": "required|conditionals:interest",
    "conditionals.skills": "required|conditionals:skill",
  },
  routes: {
    create: [
      {
        field: "type",
        route: routes.wizard.newProject.selectType,
      },
      {
        field: "name",
        route: routes.wizard.newProject.inputName,
      },
      {
        field: "description",
        route: routes.wizard.newProject.inputDescription,
      },
      {
        field: ["locations.country", "locations.state", "locations.city", "locations.coordinates"],
        route: routes.wizard.newProject.inputAddress,
      },
      { field: "conditionals.skills", route: routes.wizard.newProject.personalWork },
      { field: "conditionals.interests", route: routes.wizard.newProject.personalAbilities },
      { field: "conditionals.causes", route: routes.wizard.newProject.personalCauses },
      {
        field: "images_data.profile.original",
        route: routes.wizard.newProject.inputProfileImage,
      },
      { field: "images_data.cover.original", route: routes.wizard.newProject.inputCoverImage },
      { field: "accessInfo", route: routes.wizard.newProject.inputAccessInfo },
    ],
  },
};

export default projectValidation;
