import React, { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import classNames from "classnames";
import { transition } from "utils/animation";
import { chunk, orderBy } from "lodash";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";
import ActionLink from "../Link/ActionLink";
import { useTranslation } from "react-i18next";
import { default as BaseCheckbox } from "../Checkbox/Checkbox";
import Link from "../Link/Link";
import { Filter } from "pages/ListActions/DataOfFilters";

interface FilterOptionsProps {
  tag?: React.ElementType;
  text?: string;
  className?: string;
  isOpen?: boolean;
  onClose(): void;
  onSelected?(option: any): void; //TODO(Jeconias): Add type
  onApply?(): void;
  onClean?(): void;
  list?: Filter;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({
  isOpen,
  list,
  onClose,
  onSelected,
  onApply,
  onClean,
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation("components");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { isSM, isXS } = useMediaQueriesV3();

  const resizeCallback = useCallback(
    (lengthOfArray: number) => {
      if (lengthOfArray < 10) return lengthOfArray;
      if (isSM) return Math.ceil(lengthOfArray / 2);
      if (isXS) return lengthOfArray / 1;
      return Math.ceil(lengthOfArray / 3);
    },
    [isSM, isXS]
  );

  const chunkSize = resizeCallback(list?.options.length || 0);
  const options = chunk(orderBy(list?.options || [], ["label"]), chunkSize);

  const handleClickOutside = (event: MouseEvent) => {
    if (event.target && wrapperRef.current !== null && !wrapperRef.current.contains((event as any).target)) {
      const clicked = (event.target as HTMLDivElement).hasAttribute("actived");
      if (!clicked) onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onCleanCallback = useCallback(() => {
    if (onClean) onClean();
  }, [onClean]);

  const onApplyCallback = useCallback(() => {
    if (onApply) onApply();
    onClose();
  }, [onApply, onClose]);

  return (
    <Wrapper ref={wrapperRef} {...props} className={classNames("filterOptions", className)}>
      {children}
      <Options isOpen={isOpen} className="optionsWrapper">
        <Flex>
          {options.map((subArr, key) => (
            <Column key={key}>
              {subArr.map((el, subKey) => (
                <Checkbox
                  id={`${subKey}-${el.value}`}
                  key={`${subKey}-${el.value}`}
                  label={el.label}
                  checked={el.checked}
                  onValueChange={() => {
                    if (onSelected) onSelected(el);
                  }}
                />
              ))}
            </Column>
          ))}
        </Flex>
        <Actions>
          <Link tag="button" onClick={onCleanCallback}>
            {t("searchWithFilter.actions.cleanFilter")}
          </Link>
          <ActionLink tag="button" onClick={onApplyCallback}>
            {t("common.apply", { ns: "translation" })}
          </ActionLink>
        </Actions>
      </Options>
    </Wrapper>
  );
};

export default FilterOptions;

const Wrapper = styled.div`
  position: relative;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.v3.spacing.lg};
`;

const Column = styled.div`
  width: 240px;
`;

const Flex = styled.div`
  display: flex;
`;

const Options = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 0;
  margin: 0 ${({ theme }) => theme.v3.spacing.xs};
  padding: 0;
  background-color: transparent;
  box-shadow: ${({ theme }) => theme.v3.boxShadow.l3};
  border: 1px solid transparent;
  border-radius: 6px;
  display: flex;

  z-index: 10;
  overflow: hidden;
  transition: ${transition.normal("height")};

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: #fff;
      padding: ${({ theme }) => theme.v3.spacing.md};
      border-color: ${({ theme }) => theme.v3.colors.neutralLightest};
      height: auto;
    `};
`;

const Checkbox = styled(BaseCheckbox)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
`;
