import React from "react";
import styled, { css } from "styled-components/macro";
import BaseButton, { BaseButtonProps } from "./BaseButton";
import { rem } from "polished";
import mediaQueries from "utils/mediaQueries";

/**
 * @deprecated
 */
const SpecialButton = (props: BaseButtonProps): JSX.Element => <Button {...props} />;

export default SpecialButton;

const sizeSM = css`
  width: 180px;
  min-height: 30px;
  font-size: ${rem(14)};
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
`;

const Button = styled(BaseButton)<BaseButtonProps>`
  color: ${({ theme }) => theme.v3.colors.primary};
  border: 1px solid #e2e6f4;
  width: 270px;
  padding-top: ${rem(13)};
  padding-bottom: ${rem(13)};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.v3.colors.primary};
    border-color: ${({ theme }) => theme.v3.colors.primary};
  }

  ${({ size }) => size === "sm" && sizeSM}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${mediaQueries.xs(sizeSM)}
`;
