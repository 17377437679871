import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { Interest } from "pages/ListActions/DataOfFilters";
import { Weekday, SubscribeTimeConfigurationsTime } from "core/api/definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface UpdateVacancyResponseParams {}

export type UpdateSubscribeRequest = {
  slug: string;
  // Action data
  function_id: string;
  description: string;
  action_address: boolean; //[If is true don't need send address, the address will be set in backend]
  time_accept_remote: boolean; //[If is true, don't should to send address]
  requirements?: string;
  address?: Address;
  images_data?: {
    cover_original: string;
    cover_description?: string;
  };
  time_configurations?: {
    times?: SubscribeTimeConfigurationsTime[];
    quantity?: number;
    weekdays?: Weekday[];
  };
  conditionals?: {
    interests: Interest[];
  };
};

export type UpdateSubscribeResponse = ResponseWithParams<ResponseStatus, UpdateVacancyResponseParams>;

const storeUpdateSubscribe = async ({
  slug,
  ...data
}: UpdateSubscribeRequest): Promise<AxiosResponse<UpdateSubscribeResponse>> =>
  http.put<UpdateSubscribeResponse>(`/subscribe/${slug}`, data);

export default storeUpdateSubscribe;
